import React, { useEffect } from "react";
import { DEFAULT_IR_PAGE_SIZE } from "../../constants";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import GroupWapper from "./ReasonWapper";
import ReasonContent from "./ReasonContent";
import { useSelector } from "react-redux";
import CommonWrapper from "../../common-context/CommonWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const MainContainer = styled.div`
  padding: 0 20px 0 20px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const ReasonMainPage = () => {
  let lastPageSize = useSelector((state) => state?.user?.lastPageSize);
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Reason",
      href: `${window.location?.origin}/Reason`
    });
    // eslint-disable-next-line
  }, []);

  if (typeof lastPageSize === "string" && lastPageSize !== "") {
    lastPageSize = JSON.parse(lastPageSize);
  }
  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: lastPageSize?.ir_reason
      ? lastPageSize?.ir_reason
      : DEFAULT_IR_PAGE_SIZE.ir_reason,
    data: [],
    sortDirection: "ASC",
    orderBy: "groupName",
    refreshData: 0
  };

  return (
    <MainContainer>
      <GroupWapper>
        <PaginationWrapper initialValues={initialValues}>
          <CommonWrapper>
            <ReasonContent />
          </CommonWrapper>
        </PaginationWrapper>
      </GroupWapper>
    </MainContainer>
  );
};

export default ReasonMainPage;
