import React from "react";
import styled from "styled-components";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "../../components/shared/GridAutoCompleteStyles";
import { find } from "lodash";
import { OwcTypography } from "@one/react";

const TopBarContents = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SearchByBlock = styled.div`
  display: flex;
  align-items: center;
  & > .selectBox {
    margin: 0 10px 0px 10px;
    min-width: 164px;
  }
`;

const StatusLogTopBar = ({
  importedUsers,
  setImportedBy,
  setImportedType,
  importedTypes = null
}) => {
  return (
    <TopBarContents>
      <OwcTypography variant="subtitle2">History of imports</OwcTypography>
      <SearchByBlock>
        <OwcTypography variant="body1">Search by:</OwcTypography>
        {importedTypes && (
          <GridAutocomplete
            className="selectBox"
            size="small"
            sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
            propValue="listKey"
            propLabel="listName"
            optionsList={importedTypes}
            label="Type of import"
            key={"Type of import"}
            onAutoCompleteChange={(selectedValue) => {
              setImportedType(
                find(importedTypes, {
                  targetModule: selectedValue?.targetModule
                })
              );
            }}
          />
        )}
        <GridAutocomplete
          className="selectBox"
          size="small"
          sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
          propValue="value"
          propLabel="key"
          optionsList={importedUsers}
          label="Imported by"
          key={"imported by"}
          onAutoCompleteChange={(selectedValue) => {
            setImportedBy(selectedValue?.email);
          }}
        />
      </SearchByBlock>
    </TopBarContents>
  );
};

export default StatusLogTopBar;
