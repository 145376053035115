import { CommonContextWrapper } from "./context";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import {
  updateLastPageSize as updateLastPageSizeAction,
  updateSearchPreference as updateSearchPreferenceAction
} from "../features/user/redux/actions";
import { UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS } from "../gql/landingapi/mutations";
import { useState } from "react";

const CommonWrapper = ({
  children,
  client,
  id,
  email,
  lastPageSize,
  updateLastPageSize,
  updateSearchPreference
}) => {
  const [counter, setCounter] = useState(0);
  const saveLastPageSize = async (limit, limitKey) => {
    try {
      if (typeof lastPageSize === "string" && lastPageSize !== "") {
        lastPageSize = JSON.parse(lastPageSize);
      }
      lastPageSize = { ...lastPageSize };
      lastPageSize[limitKey] = limit;
      lastPageSize = JSON.stringify(lastPageSize);

      await client.mutate({
        mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
        variables: { id, email, lastPageSize }
      });
      updateLastPageSize(lastPageSize);
    } catch (err) {
      console.log(" last page size fetch!", { err });
    }
  };
  const updateSelectedSearchPreference = (searchDropDownFilter) => {
    try {
      updateSearchPreference(searchDropDownFilter);
      const searchPreference = JSON.stringify(searchDropDownFilter);
      client.mutate({
        mutation: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
        variables: { id, email, searchPreference }
      });
    } catch (err) {
      process.env.NODE_ENV === "development" &&
        console.log("fav last filter fetch!", { err });
    }
  };

  const onCounterChange = (count) => {
    setCounter(() => count);
  };

  return (
    <CommonContextWrapper.Provider
      value={{
        saveLastPageSize,
        updateSelectedSearchPreference,
        onCounterChange,
        counter
      }}
    >
      {children}
    </CommonContextWrapper.Provider>
  );
};

const mapStateToProps = (store) => ({
  id: store?.user?.id,
  email: store?.user?.email,
  lastPageSize: store?.user?.lastPageSize
});

export default compose(
  connect(mapStateToProps, {
    updateLastPageSize: updateLastPageSizeAction,
    updateSearchPreference: updateSearchPreferenceAction
  }),
  withApollo
)(CommonWrapper);
