import React from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`;

const RenderIconComponent = ({ IconComponent, onClick, iconStyle }) =>
  IconComponent ? <IconComponent onClick={onClick} style={iconStyle} /> : null;

export const IconWithText = ({
  icon,
  iconStyle = {},
  iconComponent: IconComponent,
  children,
  onClick
}) => {
  return (
    <StyledIcon>
      {icon ? (
        <i
          className="one-icons"
          data-testid="app-icon-with-text-icon"
          onClick={onClick}
          style={iconStyle}
        >
          {icon}
        </i>
      ) : (
        <RenderIconComponent IconComponent={IconComponent} onClick={onClick} iconStyle={iconStyle} />
      )}
      {children}
    </StyledIcon>
  );
};
