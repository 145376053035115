import React from "react";
import { ChipsWrapper } from "./FormsStyled";
import { uniq } from "underscore";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { OwcChip } from "@one/react";

const filter = createFilterOptions();

const onChangeItemToValue = (newValue, { propValue, propLabel }) => {
  const selectedMediaTypes = newValue.map((item) => {
    if (typeof item === "object" && item.inputValue) {
      item[propLabel] = item.inputValue;
      item[propValue] =
        item.inputValue.replace(/\s/g, "_") + new Date().toISOString();
      delete item["inputValue"];
    }
    return item;
  });

  return uniq(selectedMediaTypes, (item) => item[propLabel]);
};

const CustomAutoCompleteWithMultiSelect = ({
  value,
  id,
  dataTestid,
  optionsList,
  setValue,
  label,
  propValue,
  propLabel,
  fullWidth,
  multiple,
  error,
  removable = false,
  handleDelete,
  helperText,
  ...props
}) => {
  const renderTags = (selectedObjects, getTagProps) =>
    selectedObjects?.map((selectedObject, index) => {
      const chipLabel = propLabel
        ? selectedObject?.[propLabel]
        : selectedObject.replace("Add ", "").replace(/"/g, "");
      return (
        <ChipsWrapper>
          <OwcChip
            data-testid={`${dataTestid}-chip-for-${chipLabel}`}
            key={selectedObject}
            variant="outlined"
            label={chipLabel}
            removable={removable}
            onRemove={() => {
              handleDelete(selectedObjects, selectedObject, setValue);
            }}
            size="small"
            {...getTagProps({ index })}
          >
            {chipLabel}
          </OwcChip>
        </ChipsWrapper>
      );
    });

  const handleOnChange = (event, newValue) => {
    if (propLabel) {
      setValue(
        onChangeItemToValue(newValue, {
          propValue,
          propLabel
        })
      );
    } else {
      const tempValue = newValue.map((item) =>
        item.replace("Add ", "").replace(/"/g, "")
      );
      setValue(uniq(tempValue));
    }
  };

  const handleOnFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (propLabel) {
      // Suggest the creation of a new value
      if (params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          [propLabel]: `Add "${params.inputValue}"`
        });
      }
    } else {
      let temp = params.inputValue ?? null;
      temp = temp.trim();
      if (temp !== "" && temp !== null && temp.length > 0) {
        filtered.push(`Add "${params.inputValue}"`);
      }
    }

    return filtered;
  };

  const handleOnRenderInput = (params) => (
    <TextField
      {...params}
      InputLabelProps={{}}
      label={label}
      variant="filled"
      helperText={helperText}
      error={error}
      data-testid={`${dataTestid}-text-field`}
    />
  );

  return (
    <>
      <Autocomplete
        data-testid={dataTestid}
        id={id}
        multiple
        limitTags={2}
        value={value}
        onChange={handleOnChange}
        options={optionsList}
        filterOptions={handleOnFilterOptions}
        renderInput={handleOnRenderInput}
        getOptionLabel={(option) => option}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderTags={renderTags}
        openOnFocus={true}
        {...props}
      />
    </>
  );
};

export default CustomAutoCompleteWithMultiSelect;
