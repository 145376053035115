import React, { useRef, useContext } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { compose } from "redux";
import { signOut } from "../utils/signout";
import IconForApp from "../icons/IconForApp";
import { displayNameSelector } from "../features/user/redux/selectors";
import { useHistory } from "react-router-dom";
import {
  OwcBadge,
  OwcButton,
  OwcDivider,
  OwcDoubleGlobalArea,
  OwcIcon,
  OwcIconButton,
  OwcPopover,
  OwcTypography
} from "@one/react";
import styles from "./AppBar.module.scss";
import { IconButton, Tooltip } from "@mui/material";
import { BackendInfoContext } from "../features/backend-info-context/context";

const Appbar = ({ client, email, name, isInboxActive, inboxCount }) => {
  const popoverElement = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const logoLetter = email?.charAt(0);
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const badgeElement = useRef(null);
  const { appbarAppNameVersion, hideFooter } = useContext(BackendInfoContext);
  return (
    <>
      <OwcDoubleGlobalArea
        data-testid="app-bar-not-auth-main-page"
        style={{
          position: "relative",
          zIndex: 1000,
          filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4))"
        }}
      >
        <div
          slot="primary"
          style={{
            justifyContent: "space-between",
            borderBottom: "none",
            filter: "none"
          }}
        >
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <IconForApp />
            <OwcTypography variant="subtitle1">
              Equipment Repository
            </OwcTypography>
            <div className={styles.VerticalLine} />
            {!hideFooter && (
              <a href="/info" style={{ textDecoration: "none" }}>
                <OwcTypography
                  variant="subtitle1"
                  style={{
                    textTransform: "initial",
                    marginLeft: 12,
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  {appbarAppNameVersion}
                </OwcTypography>
              </a>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.appbarInfoUserInfo}
              data-testid="app-bar-user-info-user"
              button
              // hasFocus={anchorEl !== null}
              style={{ marginRight: 12 }}
            >
              <OwcTypography variant="caption" style={{ marginRight: 10 }}>
                {name}
              </OwcTypography>
              <OwcIcon
                variant="default"
                name="user"
                id="popover-anchor-for-top-bar"
                onClick={handleClick}
              />
            </div>
            <Tooltip title={"Inbox"} placement="right" arrow>
              <IconButton
                onClick={() => {
                  history?.push("/export-csv");
                }}
                color={
                  isInboxActive
                    ? "var(--one-color-background-brand-1)"
                    : "default"
                }
              >
                {inboxCount > 0 && (
                  <>
                    <OwcIconButton
                      icon="inbox"
                      type="legacy"
                      style={{
                        color: "var(--one-color-background-brand-1)"
                      }}
                      flat
                    />
                    <OwcBadge
                      ref={badgeElement}
                      type="info"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px"
                      }}
                    >
                      {inboxCount}
                    </OwcBadge>
                  </>
                )}
                {inboxCount === 0 && (
                  <OwcIconButton icon="inbox" type="legacy" flat />
                )}
              </IconButton>
            </Tooltip>
            <OwcIconButton
              data-testid="App-bar-user-info-info"
              flat
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
              style={{ marginRight: "12px" }}
            />
            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>

      <OwcPopover
        data-testid="popover-user-menu"
        ref={popoverElement}
        anchor="popover-anchor-for-top-bar"
        id="popover-anchor-for-top-bar-popover"
        anchor-position="bottom-start"
        visible={open}
        disable-portal={true}
        onClickOutside={handleClose}
        style={{ width: 340 }}
      >
        <div
          className={styles.appbarUserInfoSection}
          data-testid="popover-user-menu-info-section"
        >
          <span
            className={styles.appbarLogoLetter}
            data-testid="popover-user-menu-info-section-logo"
          >
            {logoLetter}
          </span>
          <OwcTypography
            variant="subtitle1"
            data-testid="popover-user-menu-info-section-username"
          >
            {name}
          </OwcTypography>
          <OwcTypography
            variant="body2"
            data-testid="popover-user-menu-info-section-email"
          >
            {email}
          </OwcTypography>
          <OwcDivider />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "16px"
          }}
        >
          <OwcButton
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
            variant="secondary"
          >
            Logout
          </OwcButton>
        </div>
      </OwcPopover>
    </>
  );
};

const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.user.email
});

export default compose(connect(mapStateToProps), withApollo)(Appbar);
