import { Tooltip } from "@mui/material";

export const UploadedByCell = ({ item }) => {
  const userName = item?.userName?.items[0];
  const uploadedBy =
    userName?.givenName && userName?.familyName
      ? `${userName?.givenName?.trim()} ${userName?.familyName?.trim()}`
      : item?.email;
  return (
    <Tooltip title={uploadedBy}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          flex: "0 1 14%",
          alignItems: "center"
        }}
      >
        {uploadedBy}
      </div>
    </Tooltip>
  );
};

export const UsersCell = ({ item }) => {
  const sortedUserList =
    item?.userIds
      ?.map((user) =>
        user?.givenName && user?.familyName
          ? `${user?.givenName?.trim()} ${user?.familyName?.trim()}`
          : user?.email
      )
      ?.sort() || [];
  const userString = sortedUserList?.join(", ") || "-";
  return (
    <Tooltip title={userString}>
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        {userString}
      </div>
    </Tooltip>
  );
};
