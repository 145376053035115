import * as yup from "yup";

/**
 *
 * @param {editMasterDataValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

/**
 * schema for validating the equpiment identification form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const editMasterDataValidationSchema = yup.object({
  equipmentCategory: getCustomNullable(
    yup
      .string("Enter category")
      .typeError("Invalid type")
      .required("Category is required")
  ),
  manufacturer: getCustomNullable(
    yup
      .string("Enter manufacturer")
      .typeError("Invalid type")
      .required("Manufacturer is required")
  ),
  equipmentModel: getCustomNullable(
    yup
      .string("Enter model")
      .typeError("Invalid type")
      .required("Model is required")
  ),
  variant: getCustomNullable(
    yup.string("Enter variant").typeError("Invalid type")
  ),
  displayImage: getCustomNullable(
    yup.string("Enter displayImage").typeError("Invalid type")
  ),
  weightInkg: yup
    .string()
    .typeError("Enter a decimal value with 2 digits only")
    .matches(/^[0-9]*\.[0-9]{2}$/, "Enter a decimal value with 2 digits only")
    .nullable(),
  heightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  lengthInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  depthInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceLeftInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceRightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceHeightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceToFrontInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceBackwardsInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  heatOutputInW: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  pressureEquipment: getCustomNullable(
    yup.string("Enter pressureEquipment").typeError("Invalid type")
  ),
  noiseIndBA: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  noiseSource: getCustomNullable(
    yup.string("Enter noiseSource").typeError("Invalid type")
  ),
  noiseSourceDetails: getCustomNullable(
    yup.string("Enter noiseSourceDetails").typeError("Invalid type")
  ),
  positioningNote: getCustomNullable(
    yup.string("Enter positioningNote").typeError("Invalid type")
  ),
  positioning: getCustomNullable(
    yup.string("Enter positioning").typeError("Invalid type")
  ),
  vibrationSensitive: getCustomNullable(
    yup.string("Enter vibrationSensitive").typeError("Invalid type")
  )
});
