import { Fragment, useEffect, useRef, useState } from "react";
import { OwcBadge, OwcButton, OwcExpandable, OwcExpandableGroup, OwcTypography, OwcWorkflowOverlay } from "@one/react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { capitalize } from "lodash";
import {
  AddMessageContainer,
  AddMessageDiv,
  AddMessageDivBody,
  AddReviewContainer
} from "../addEditEquipment/AddEquipmentStyle";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import useDialog from "../../../utils/hooks/useDialog";
import {
    EQUIPMENT_APPROVE_REVIEW,
    EQUIPMENT_REJECT_REVIEW,
    EQUIPMENT_REVERT_REVIEW, GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
    GET_EQUIPMENT_PROPOSED_CHANGES,
    GET_EQUIPMENT_PROPOSED_CHANGES_INVENTORY_ID
} from "../../../gql/landingapi";

import Notify from "../../notifications/Notify";
import {
  EQUIPMENT_REVIEW_ACTIONS,
  EQUIPMENT_REVIEW_SECTIONS,
  equipmentStatus,
  OTHER_REASON,
  SHOW_REVIEW_MODAL
} from "../../../constants";
import ReviewReasonDialogContent from "../../../components/shared/ReviewReasonDialogContent";
import { ReviewDetailsCells } from "../../../components/shared/details-cells/DetailsCells";
import { AuditItemContentCellsInfoArray } from "../audit-trial/AuditItemCellsConfig";
import { getAllData } from "../../../utils/helpers/fetching";
import { useHistory } from "react-router-dom";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import ReviewCommentOnlyDialogContent from "../../../components/shared/ReviewCommentOnlyDialogContent";
import { isId4eye } from "../../../utils/helpers/4eye";

const EquipmentReviewDialog = ({
  client,
  reviewId,
  reviewAction,
  openReviewModal,
  onCloseReviewModel,
  onChangeOfActiveModule,
  inventoryId,
  fullUserID
}) => {
  const history = useHistory();
  const [editReason, setEditReason] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [reviewData, setReviewData] = useState(null);
  const [activeDecisionDialog, setActiveDecisionDialog] = useState(false);
  const { openDialog, ...dialogProps } = useDialog();
  const expandableGroupElement = useRef(null);
  const [expandID, setExpandID] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isReviewOfNewEquipment, setIsReviewOfNewEquipment] = useState(false);
    const [isReviewOfDecommission, setIsReviewOfDecommission] = useState(false);
    const [equipmentData, setEquipmentData] = useState();

  useEffect(() => {

    const reviewData = async () => {
      try {
        let result, dataObj, equipmentId;
        if (reviewId) {
          result = await client.query({
            query: GET_EQUIPMENT_PROPOSED_CHANGES,
            variables: {
              id: reviewId
            },
            fetchPolicy: "no-cache"
          });

          const proposedChanges = result?.data?.["getEquipmentProposedChanges"];
            equipmentId = proposedChanges?.inventoryId;

          if (!result?.error) {
            dataObj = proposedChanges;
            if (
              reviewAction !== EQUIPMENT_REVIEW_ACTIONS.revert.value &&
              dataObj?.reviewStatus === equipmentStatus?.pending?.key &&
              !dataObj?.reviewers?.includes(fullUserID)
            ) {
              dataObj = null;
              Notify({
                type: "warning",
                icon: "caution",
                appName: "",
                text: "You are not part of the reviewer list."
              });
              history?.replace("/");
            }
          }
        } else if (inventoryId) {
          equipmentId = inventoryId;
          result = await getAllData({
            client,
            query: GET_EQUIPMENT_PROPOSED_CHANGES_INVENTORY_ID,
            fetchPolicy: "no-cache",
            dataPath: [
              "data",
              "listEquipmentProposedChangesByInventoryIdAndReviewStatus"
            ],
            variables: {
              limit: 1000,
              id: inventoryId
            },
            drillData: true
          });
          if (!result?.error && result?.items?.length > 0) {
            dataObj = result?.items[0];
          }
          if (!dataObj) {
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: "Error in fetching data."
            });
          }
        }

        if (
          dataObj &&
          dataObj?.reviewStatus === equipmentStatus?.pending?.key
        ) {
          onChangeOfActiveModule(SHOW_REVIEW_MODAL);
        }

        if (
          dataObj &&
          (dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.approved?.key ||
            dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.revert?.key ||
            dataObj?.reviewStatus === EQUIPMENT_REVIEW_ACTIONS?.reject?.key)
        ) {
          openDialog();
        }

        if (!dataObj) {
          onCloseReviewModel();
        } else {
          if (isId4eye(equipmentId)) {
            setIsReviewOfNewEquipment(true);
          } else {
              const reviewChanges = JSON.parse(dataObj?.changes || "{}")
              if (
                  reviewChanges.status?.newvalue === equipmentStatus?.deleted?.key &&
                  reviewChanges.status?.oldvalue === equipmentStatus?.active?.key
              ) {
                  setIsReviewOfDecommission(true);
                  const equipmentData = await client.query({
                      query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
                      variables: {
                          id: equipmentId
                      },
                      fetchPolicy: "no-cache"
                  });
                  setEquipmentData(equipmentData.data.getDigitalLabInstrumentRepositoryEntry);
              }
          }
          setReviewData(dataObj);
        }
      } catch (error) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Error in fetching data."
        });
        onCloseReviewModel();
      }
    };
    reviewData().catch(error => {
                        console.error(error);
                      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for approve changes
  const approveReview = async () => {
    try {
      setLoading((preState) => !preState);
      let approveReviewResult;
      approveReviewResult = await client.mutate({
        mutation: EQUIPMENT_APPROVE_REVIEW,
        variables: {
          reviewIds: [reviewData?.id]
        },
        fetchPolicy: "no-cache"
      });
      if (!approveReviewResult?.error) {
        if (
          approveReviewResult?.data?.approveChange?.status ===
          EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to approve at this time. Please try again."
          });
        } else {
          onCloseReviewModel();
          isReviewOfNewEquipment
            ? Notify({
              type: "success",
              icon: "circle_confirm",
              appName: "",
              text: "New equipment has been reviewed and approved successfully!"
            })
            : isReviewOfDecommission
                  ? Notify({
                      type: "success",
                      icon: "circle_confirm",
                      appName: "",
                      text: "Request to remove equipment has been approved, equipment is deleted. "
                  })
                  : Notify({
                      type: "success",
                      icon: "circle_confirm",
                      appName: "",
                      text: "Equipment details are reviewed and approved successfully."
                  });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to approve at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  //for reject changes
  const rejectReview = async () => {
    try {
      setLoading((preState) => !preState);
      setActiveDecisionDialog(false);
      const result = await client.mutate({
        mutation: EQUIPMENT_REJECT_REVIEW,
        variables: {
          reviewIds: [reviewData?.id],
          editComment: editComment,
          editReason: editReason
        },
        fetchPolicy: "no-cache"
      });
      if (!result?.error) {
        if (
          result?.data?.approveChange?.status === EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to reject at this time. Please try again."
          });
        } else {
          closeDecisionDialog();
          onCloseReviewModel();
          isReviewOfNewEquipment
            ? Notify({
              type: "success",
              icon: "circle_confirm",
              appName: "",
              text: "New equipment has been deleted due to rejection."
            })
            : isReviewOfDecommission
                  ? Notify({
                      type: "success",
                      icon: "circle_confirm",
                      appName: "",
                      text: "Request to remove equipment has been rejected, equipment status is \"Active\" again."
                  })
                  : Notify({
                      type: "success",
                      icon: "circle_confirm",
                      appName: "",
                      text: "Change(s) reverted due to rejection and the equipment status is \"Active\" again."
                  });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to reject at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  //for revert changes
  const revertReview = async () => {
    try {
      setLoading((preState) => !preState);
      setActiveDecisionDialog(false);
      const result = await client.mutate({
        mutation: EQUIPMENT_REVERT_REVIEW,
        variables: {
          reviewIds: [reviewData?.id],
          editComment: editComment,
          editReason: editReason
        },
        fetchPolicy: "no-cache"
      });
      if (!result?.error) {
        if (
          result?.data?.approveChange?.status === EQUIPMENT_REVIEW_ACTIONS?.failure?.key
        ) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Unable to revert at this time. Please try again."
          });
        } else {
          closeDecisionDialog();
          onCloseReviewModel();
          isReviewOfNewEquipment
            ? Notify({
              type: "success",
              icon: "circle_confirm",
              appName: "",
              text: "New equipment has been deleted due to revert."
            })
            : Notify({
              type: "success",
              icon: "circle_confirm",
              appName: "",
              text: "Change(s) reverted and the equipment status is \"Active\" again."
            });
        }
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Unable to revert at this time. Please try again."
      });
    } finally {
      setLoading((preState) => !preState);
    }
  };

  const openDecisionDialog = () => {
    setActiveDecisionDialog(true);
  };

  const closeDecisionDialog = () => {
    setActiveDecisionDialog(false);
  };

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      onCloseReviewModel();
    }
  };

  const expandedElementsChangedHandler = (event) => {
    setExpandID(() => event?.detail[0]);
  };

  const badgeCount = (item, reviewData) => {
    if (reviewData && reviewData?.changes) {
      const reviewChangesValue = JSON.parse(reviewData?.changes);
      if (
        reviewChangesValue[DATA_MODEL_TABLE?.floor?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.floor?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.floor?.key];
      }

      if (
        reviewChangesValue[DATA_MODEL_TABLE?.room?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.room?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.room?.key];
      }
      if (
        reviewChangesValue[DATA_MODEL_TABLE?.buildingLocation?.key] &&
        !reviewChangesValue[DATA_MODEL_TABLE?.buildingLocation?.key]?.value
      ) {
        delete reviewChangesValue[DATA_MODEL_TABLE?.room?.key];
      }

      const matchCount = item?.fields?.filter(
        (iVal) => Object.keys(reviewChangesValue).indexOf(iVal) !== -1
      );
      return matchCount?.length > 0 ? matchCount?.length : 0;
    }
    return 0;
  };

  const getEquipmentReviewDialogTitle = () => {
    const user = reviewData?.createdBy?.items[0]?.name ??
        reviewData?.createdBy?.items[0]?.email ??
        "";
    return capitalize(
      `${reviewAction} ${isReviewOfNewEquipment ? "new equipment created" : isReviewOfDecommission ? "equipment deleted" : "changes proposed"} by ${user}`
    );
  };

  return (
    <Fragment>
      {reviewData &&
      reviewData?.reviewStatus === equipmentStatus?.pending?.key ? (
        <OwcWorkflowOverlay
          className="owcoverayZIndex"
          visible={openReviewModal}
          onVisibleChange={visibleChangeHandler}
          disableBackdropClick
        >
          <Fragment>
            <div slot="title">
              { getEquipmentReviewDialogTitle() }
            </div>
            <div
              slot="content"
              style={{
                padding: "0",
                display: "block",
                marginBottom: "0",
                height: "100%"
              }}
            >
              <AddReviewContainer id="add-edit-cluster-container">
                <OwcExpandableGroup
                  ref={expandableGroupElement}
                  onExpandedElementsChanged={expandedElementsChangedHandler}
                >
                  {EQUIPMENT_REVIEW_SECTIONS.map((item, index) => {
                    const badgeCountValue = isReviewOfNewEquipment ? 0 : badgeCount(item, reviewData);
                    return (
                      <OwcExpandable
                        key={`${index}_${(new Date()).getTime()}`}
                        variant="standard"
                        round
                        expanded={expandID === index}
                      >
                        <span slot="title">
                          {item?.name}{" "}
                          {badgeCountValue > 0 && (
                            <OwcBadge
                              type="active"
                              style={{ margin: "0 20px 10px 0" }}
                            >
                              {badgeCountValue}
                            </OwcBadge>
                          )}
                        </span>
                        <span
                          slot="content"
                          style={{
                            height: "fit-content"
                          }}
                        >
                          <ReviewDetailsCells
                            changedItem={isReviewOfNewEquipment ? {} : JSON.parse(reviewData.changes)}
                            item={isReviewOfDecommission ? equipmentData : JSON.parse(reviewData?.newData)}
                            displayColumns={item.fields}
                            infoMeta={{fields: AuditItemContentCellsInfoArray}}
                          />
                        </span>
                      </OwcExpandable>
                    );
                  })}
                </OwcExpandableGroup>
              </AddReviewContainer>
              <AddMessageContainer>
                {reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value ? (
                  <>
                    <AddMessageDiv>
                      <OwcTypography variant="button">
                        Review {isReviewOfNewEquipment ? "new equipment" : isReviewOfDecommission ? "deleted equipment" : "proposed changes"}
                      </OwcTypography>
                    </AddMessageDiv>
                    <AddMessageDivBody>
                      <OwcTypography variant="body1">
                        Approve or reject the above shown {isReviewOfNewEquipment ? "new equipment" : isReviewOfDecommission ? "deleted equipment" : "changes"}.
                      </OwcTypography>
                    </AddMessageDivBody>
                  </>
                ) : (
                  <>
                    <AddMessageDiv>
                      <OwcTypography variant="button">
                        Revert {isReviewOfNewEquipment ? "new equipment" : isReviewOfDecommission ? "deleted equipment" : "proposed changes"}
                      </OwcTypography>
                    </AddMessageDiv>
                    <AddMessageDivBody>
                      <OwcTypography variant="body1">
                        For the above shown {isReviewOfNewEquipment ? "new equipment" : isReviewOfDecommission ? "deleted equipment" : "changes"} a review is required.
                        {
                          isReviewOfNewEquipment
                            ? " By reverting, the new equipment will be completely removed."
                            : " By reverting changes, the equipment status will be \"Active\" again."
                        }
                      </OwcTypography>
                    </AddMessageDivBody>
                  </>
                )}
              </AddMessageContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "calc(100% - 120px)",
                backgroundColor: "var(--one-color-interaction-hover-brand-3)"
              }}
            >
              <div
                style={{
                  width: "60%",
                  margin: "auto",
                  height: "calc(100% - 60px)",
                  backgroundColor:
                    "var(--one-color-interaction-disabled-base-1)"
                }}
              ></div>
            </div>

            <div slot="actions" style={{ padding: "16px" }}>
              {reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value ? (
                <div style={{ display: "flex" }}>
                  <OwcButton
                    data-testid="master-data-previous-step-button"
                    onClick={openDecisionDialog}
                    variant="secondary"
                    disabled={loading}
                    style={{ textTransform: "none", marginRight: "20px" }}
                  >
                    Reject
                  </OwcButton>
                  <OwcButton
                    variant="primary"
                    onClick={() => {
                      approveReview().catch(error => {
                        console.error(error);
                      });
                    }}
                    style={{
                      textTransform: "none"
                    }}
                    disabled={loading}
                    type="submit"
                    data-testid="master-data-save-step-button"
                  >
                    Approve
                  </OwcButton>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <OwcButton
                    data-testid="master-data-previous-step-button"
                    onClick={() => onCloseReviewModel()}
                    variant="secondary"
                    disabled={loading}
                    style={{ textTransform: "none", marginRight: "20px" }}
                  >
                    Cancel
                  </OwcButton>
                  <OwcButton
                    variant="primary"
                    onClick={openDecisionDialog}
                    style={{
                      textTransform: "none"
                    }}
                    disabled={loading}
                    type="submit"
                    data-testid="master-data-save-step-button"
                  >
                    Revert {!isReviewOfNewEquipment && !isReviewOfDecommission && "changes"}
                  </OwcButton>
                </div>
              )}
              <ConfirmDialog
                {...dialogProps}
                open={activeDecisionDialog}
                approveText="Save"
                content={(
                  isReviewOfNewEquipment
                    ? <ReviewCommentOnlyDialogContent
                      editComment={editComment}
                      setEditComment={setEditComment}
                    />
                    : <ReviewReasonDialogContent
                      editReason={editReason}
                      setEditReason={setEditReason}
                      editComment={editComment}
                      setEditComment={setEditComment}
                    />
                )}
                approveColor="primary"
                approveVariant="contained"
                approveDisable={(
                  !isReviewOfNewEquipment && (editReason === OTHER_REASON ? !editComment : !editReason)
                )}
                cancelText="Cancel"
                cancelVariant="outlined"
                cancelColor="primary"
                onApprove={() => {
                  if (reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value) {
                    rejectReview().catch(error => {
                      console.error(error);
                    });
                  } else {
                    revertReview().catch(error => {
                      console.error(error);
                    });
                  }
                }}
                onCancel={closeDecisionDialog}
                title={
                  reviewAction === EQUIPMENT_REVIEW_ACTIONS?.review?.value
                    ? isReviewOfNewEquipment
                      ? "Comment for rejection"
                      : "Reason for rejection"
                    : isReviewOfNewEquipment
                      ? "Comment for revert"
                      : "Reason for revert changes"
                }
                disableBackdropClick={true}
              />
            </div>
          </Fragment>
        </OwcWorkflowOverlay>
      ) : (
        <Fragment>
          {reviewData &&
          (reviewData?.reviewStatus ===
            EQUIPMENT_REVIEW_ACTIONS?.approved?.key ||
            reviewData?.reviewStatus ===
              EQUIPMENT_REVIEW_ACTIONS?.revert?.key ||
            reviewData?.reviewStatus ===
              EQUIPMENT_REVIEW_ACTIONS?.reject?.key) ? (
            <Fragment>
              <ConfirmDialog
                {...dialogProps}
                approveText="OK"
                approveColor="secondary"
                approveVariant="contained"
                onApprove={() => {
                  history?.replace("/");
                }}
                title="Equipment already reviewed"
                content="The equipment you are trying to review has already been reviewed."
                disableBackdropClick={true}
              />
            </Fragment>
          ) : (
            <Fragment/>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default compose(connect(), withApollo)(EquipmentReviewDialog);
