import React from "react";
import GpxMaintainanceInformation from "./GpxMaintainanceInformation";
import EquipmentDescription from "./EquipmentDescription";
import LaboratoryInfo from "./LaboratoryInfo";
import AnalyzerConfiguration from "./AnalyzerConfiguration";
import EquipmentIdentification from "./EquipmentIdentification";
import { OwcExpandableGroup, OwcExpandable } from "@one/react";

export const addStepContent = (
  step,
  isEditMode,
  isUpdateBadge,
  sopHelperText,
  isLinkedInstance
) => {
  switch (step) {
    case 0:
      return (
        <EquipmentIdentification
          isEditMode={isEditMode}
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
          isLinkedInstance={isLinkedInstance}
        />
      );
    case 1:
      return (
        <EquipmentDescription
          isEditMode={isEditMode}
          isUpdateBadge={isUpdateBadge}
        />
      );
    case 2:
      return (
        <LaboratoryInfo
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
        />
      );
    case 3:
      return (
        <GpxMaintainanceInformation
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
        />
      );
    case 4:
      return <AnalyzerConfiguration isUpdateBadge={isUpdateBadge} />;
    default:
      return "Unknown Step";
  }
};

export const editStepContent = (
  step,
  isEditMode,
  isUpdateBadge,
  sopHelperText,
  isLinkedInstance,
  expandableGroupElement,
  expandID,
  expandedElementsChangedHandler
) => {
  return (
    <OwcExpandableGroup
      ref={expandableGroupElement}
      onExpandedElementsChanged={expandedElementsChangedHandler}
    >
      <OwcExpandable variant="standard" round expanded={expandID === 0}>
        <span slot="title">Basic identification</span>
        {expandID === 0 && (
          <span slot="content">
            <EquipmentIdentification
              isEditMode={isEditMode}
              isUpdateBadge={isUpdateBadge}
              sopHelperText={sopHelperText}
              isLinkedInstance={isLinkedInstance}
            />
          </span>
        )}
      </OwcExpandable>
      <OwcExpandable variant="standard" round expanded={expandID === 1}>
        <span slot="title">Equipment description</span>
        {expandID === 1 && (
          <span slot="content">
            <EquipmentDescription
              isEditMode={isEditMode}
              isUpdateBadge={isUpdateBadge}
            />
          </span>
        )}
      </OwcExpandable>
      <OwcExpandable variant="standard" round expanded={expandID === 2}>
        <span slot="title">Laboratory and responsibility</span>
        {expandID === 2 && (
          <span slot="content">
            <LaboratoryInfo
              isUpdateBadge={isUpdateBadge}
              sopHelperText={sopHelperText}
              isEditMode={isEditMode}
            />
          </span>
        )}
      </OwcExpandable>
      <OwcExpandable variant="standard" round expanded={expandID === 3}>
        <span slot="title">Qualification status and maintenance</span>
        {expandID === 3 && (
          <span slot="content">
            <GpxMaintainanceInformation
              isUpdateBadge={isUpdateBadge}
              sopHelperText={sopHelperText}
              isEditMode={isEditMode}
            />
          </span>
        )}
      </OwcExpandable>
      <OwcExpandable variant="standard" round expanded={expandID === 4}>
        <span slot="title">Configuration and availability</span>
        {expandID === 4 && (
          <span slot="content">
            <AnalyzerConfiguration
              isEditMode={isEditMode}
              isUpdateBadge={isUpdateBadge}
            />
          </span>
        )}
      </OwcExpandable>
    </OwcExpandableGroup>
  );
};
