import { AuditTrialReasonTitle, AuditTrialReasonValue } from "./AuditItemContent";

export const ReviewersField = ({ reviewers=[]}) => {
    return (
        reviewers.length>0 ? (
        <>  
            <AuditTrialReasonTitle>
                Reviewer(s):{" "}
            </AuditTrialReasonTitle>
            <AuditTrialReasonValue>
                {reviewers
                    .map((x) => (x.name ? x.name : x.email))
                    .join(", ")}
            </AuditTrialReasonValue>
        </>
        ): ""
    )
}
