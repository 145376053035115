import React from "react";
import { OwcButton, OwcModalDialog } from "@one/react";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";

const THEME = themeMapping[THEME_NAME];
/**
 * Empty modal with Save/Cancel buttons
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 * @param {boolean} props.openModal - state if modal is open
 * @param {function} props.closeModal - callback to close modal
 * @param {React.ReactElement} props.modalTitle - title of modal
 * @param {React.ReactElement} props.modalBody - body of modal - usually form
 * @param {string} [props.confirmText="OK"] - confirm text
 * @param {string} [props.cancelText="Cancel"] - cancel text
 */

export const ModalDialog = ({
  formik,
  openModal,
  closeModal,
  modalTitle,
  modalBody,
  confirmText = "OK",
  cancelText = "Cancel"
}) => {
  return (
    <>
      <OwcModalDialog
        visible={openModal}
        open={openModal}
        close={closeModal}
        title="Add checklist"
        disableBackdropClick={true}
        width="80vw"
      >
        <div slot="header">{modalTitle}</div>
        <form onSubmit={formik.handleSubmit}>
          {modalBody}
          <div
            slot="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: THEME["one-spacer-12"]
            }}
          >
            <OwcButton
              elevated
              style={{ margin: 2, marginRight: THEME["one-spacer-12"] }}
              variant="secondary"
              onClick={() => closeModal()}
            >
              {cancelText}
            </OwcButton>
            <OwcButton
              elevated
              disabled={
                !formik.isValid || Object.keys(formik.touched).length === 0
              }
              style={{ margin: 2 }}
              variant="primary"
              type="submit"
            >
              {confirmText}
            </OwcButton>
          </div>
        </form>
      </OwcModalDialog>
    </>
  );
};
