import { Fragment } from "react";
import styled from "styled-components";
import { NormalFont } from "../../../components/shared/NormalFont";
import {
    AUDIT_ACTIONS,
    AUDIT_ACTION_BY,
    AUDIT_TRAIL_LIMIT,
    EDIT_REASON, equipmentStatus
} from "../../../constants";
import { ReviewersField } from "./ReviewersField";
import { AuditItemContentCellsInfoArray } from "./AuditItemCellsConfig";
import AuditItemSingleFrame from "./AuditItemSingleFrame";
import { utcStringToLocaleString } from "../helpers";
import { valueOrEmptyString } from "../../../utils/helpers/text";

const HistoryItemHeader = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
  margin-top: 16px;
  margin-bottom: 12px;
`;

const HistoryItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: var(--one-color-gray-800) 3;
`;

export const AuditTrialReasonTitle = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #000000;
`;

export const AuditTrialReasonValue = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: var(--one-color-gray-800);
`;

const AuditAdminText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--one-color-background-brand-1);
`;

const LOG_ACTIONS = [
  AUDIT_ACTIONS.MODIFY,
  AUDIT_ACTIONS.CLUSTER_CREATE,
  AUDIT_ACTIONS.CLUSTER_DELETE,
  AUDIT_ACTIONS.ADD_TO_CLUSTER,
  AUDIT_ACTIONS.REMOVE_FROM_CLUSTER,
  AUDIT_ACTIONS.APPROVE,
  AUDIT_ACTIONS.REJECT,
  AUDIT_ACTIONS.REVERT
];



const AuditItemContent = ({ changeItem, dataTestId, userLists }) => {
  return (
    <div data-testid={`${dataTestId}-content`} style={{ position: "relative" }}>
      {changeItem?.map((log, index) => {
        const newData = JSON.parse(log?.newData || "{}");
        const changes = JSON.parse(log?.changes || "{}");
        const proposedChanges = JSON.parse(log?.proposedChanges?.changes || "{}");
        const relevantPositionState = newData?.trackPositionInCluster;
        const is4eye = (log?.action === AUDIT_ACTIONS.CREATE && newData?.status === equipmentStatus.pending.key) ||
            (changes?.status?.oldvalue === equipmentStatus.active.key && changes?.status?.newvalue === equipmentStatus.pending.key);
        const isDecommission = (
            proposedChanges?.status?.newvalue === equipmentStatus.deleted.key &&
            proposedChanges?.status?.oldvalue === equipmentStatus.active.key &&
            is4eye
        );
        let reviewers = [];
        const notInheritedByMasterChange = log.editReason !== EDIT_REASON.INHERITED_BY_MASTER_DATA_CHANGE;
        if (notInheritedByMasterChange) {
            reviewers = (log?.proposedChanges?.reviewers ?? is4eye ? newData.reviewers : [])
                ?.map((item) => userLists.find((x) => x.userId === item)) ?? [];
        }
        const logHistory = () => {
            if (notInheritedByMasterChange && log.proposedChanges && log.changes) {
                return proposedChanges;
            } else {
                return changes;
            }
        }
        const auditAction =  isDecommission ?
            AUDIT_ACTION_BY.DELETE_4EP :
            AUDIT_ACTION_BY?.[`${log.action}${is4eye ? "_4EP" : ""}`] ?? AUDIT_ACTION_BY?.[log.action];
        const title = (log?.modifiedByUserId || log?.modifiedByEmail) ?
          `(${auditAction} ${log?.modifiedByUserId || log?.modifiedByEmail})` :
          "";

        if (index > AUDIT_TRAIL_LIMIT - 1) return "";
        return (
          <Fragment key={`${index}_${new Date().getTime()}`}>
            <HistoryItemHeader
              style={{
                marginBottom: !LOG_ACTIONS.includes(log?.action)
                  ? "48px"
                  : "12px"
              }}
            >
              <HistoryItemTitle
                data-testid={`${dataTestId}-content-header-${index}`}
              >
                <div>
                  {utcStringToLocaleString(log?.modifiedDate, 24)}
                  &nbsp;
                  <NormalFont>
                    {title}
                  </NormalFont>
                </div>
              </HistoryItemTitle>
              <div>
                {log?.editReason || log?.editComment || reviewers.length > 0 ? (
                  <>
                    {/* presence of proposed changes implies PENDING status */}
                    {log?.proposedChanges ? (
                        <div style= {{ display:"flex" }}>
                            <div style={{ flex: "40%" }}>
                            <AuditTrialReasonTitle>
                                Reason:{" "}
                            </AuditTrialReasonTitle>
                            <AuditTrialReasonValue>
                                {valueOrEmptyString(log?.editReason)}
                            </AuditTrialReasonValue>
                            </div>
                            <div style={{ flex: "60%" }}>
                                {/* since it is in pending status, reviewers are expected to be present, 
                                thus we filter out only inherited my master change status (field need to be hidden for it) */}
                            <ReviewersField reviewers={reviewers} />
                            </div>
                        </div>
                    ) : 
                    log?.action === AUDIT_ACTIONS.APPROVE ? (
                      ""
                    ) : (
                        <div style= {{ display:"flex" }}>
                            <div style={{ flex: "40%" }}>
                                <AuditTrialReasonTitle>Reason: </AuditTrialReasonTitle>
                                <AuditTrialReasonValue>
                                {log?.editReason}
                                </AuditTrialReasonValue>
                            </div>
                            <div style = {{ flex: "60%" }}>
                                <ReviewersField  reviewers={reviewers} />
                            </div>
                        </div>
                    )}
                    {log?.action === AUDIT_ACTIONS.APPROVE ? (
                      ""
                    ) : (
                      <div>
                        <AuditTrialReasonTitle>Comment: </AuditTrialReasonTitle>
                        <AuditTrialReasonValue>
                          {log?.editReason === EDIT_REASON.INHERITED_BY_MASTER_DATA_CHANGE ? "-": valueOrEmptyString(log?.editComment)}
                        </AuditTrialReasonValue>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </HistoryItemHeader>
            {log?.changes && (
              <AuditItemSingleFrame
                isAuditTrial={true}
                isDecomission={isDecommission}
                auditAction={log?.action}
                history={logHistory()}
                newData={newData}
                CellsInfoArray={AuditItemContentCellsInfoArray}
                relevantPositionState={relevantPositionState}
                proposedChanges={notInheritedByMasterChange && !!log?.proposedChanges}
                proposedChangesValues={log?.proposedChanges ? changes : []}
              />
            )}

            {index === AUDIT_TRAIL_LIMIT - 1 && (
              <AuditAdminText>
                For more records please contact Admin.
              </AuditAdminText>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default AuditItemContent;
