import React, { useState, useEffect, useContext } from "react";

import { getAllData } from "../../utils/helpers/fetching";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { LIST_IR_INBOX_BY_USER_ID_AND_CREATE_DATE, UPDATE_IR_INBOX } from "../../gql/landingapi";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { ExportTableMeta } from "./ExportCsvMeta";
import ExportCsvMainPage from "./ExportCsvTopBarButton";
import CustomList from "../../components/shared/List";
import PaginationActions from "../instruments/instrument-repository-pagination/PaginationActions";
import { ExportCsvContext } from "./context";
import { utcStringToLocaleString } from "../instruments/helpers";
import { getCurrentDatas } from "../instruments/instrument-repository-pagination/PaginationActions";
import { DEFAULT_RECORDS_FETCHED } from "../../constants";

const ExportCsvContent = ({ client, isInboxActive }) => {
  const {
    dispatchAction,
    fetching,
    limit,
    orderBy,
    sortDirection,
    refreshData,
    nextToken
  } = useContext(PaginationContext);
  const { selectedItem } = useContext(ExportCsvContext);
  const [logList, setLogList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalExportLogList, setTotalExportLogList] = useState([]);
  const [tempInboxList, setTempInboxList] = useState([]);
  const user = useSelector((state) => state.user);

  const [isReverseOrder, setIsReverseOrder] = useState(
    sortDirection === "ASC"
  );

  const exportLogListGql = async (isTokenNull = false) => {
    let variables = {
      limit: DEFAULT_RECORDS_FETCHED,
      nextToken: isTokenNull ? null : nextToken,
      sortDirection,
      createdById: user?.user,
      filter: {
        or: [
          { status: { eq: "COMPLETED" } },
          { status: { eq: "FAILED" } },
          { status: { eq: "VIEWED" } },
          { status: { eq: "DOWNLOADED" } }
        ]
      }
    };

    return getAllData({
      client,
      query: LIST_IR_INBOX_BY_USER_ID_AND_CREATE_DATE,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listIRInboxByCreatedByIdAndCreatedDate"],
      drillData: false
    });
  };

  const onRequestSort = (key, isReverse) => {
    dispatchAction({
      type: "sortDirection",
      payload: {
        orderBy: key,
        sortDirection: isReverse
      }
    });
    setIsReverseOrder(isReverse);
  };

  const getDownloadHistoryLogs = async () => {
    dispatchAction({
      type: "fetching",
      payload: true
    });

    setPageIndex(0);
    const { items, nextToken: token } = await exportLogListGql(true);

    items.map((item) => {
      item.selected = item.status === "COMPLETED" || item.status === "VIEWED";
      item.createdDate = utcStringToLocaleString(item?.createdDate);
      return item;
    });

    dispatchAction({
      type: "nextToken",
      payload: { token: token, data: items }
    });
    const currentItems = getCurrentDatas(limit, 0, items);
    setTotalExportLogList(items);
    setLogList(currentItems);
    setTempInboxList(items);
  };

  const updateAllInboxToViewed = async () => {
    for await (const results of tempInboxList) {
      await updateDownloadStatus(results);
    }
  };
  const updateDownloadStatus = async (item) => {
    if (item.status === "COMPLETED") {
      try {
        await client.mutate({
          mutation: UPDATE_IR_INBOX,
          fetchPolicy: "no-cache",
          variables: {
            status: "VIEWED",
            id: item.id
          }
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    updateAllInboxToViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempInboxList]);

  useEffect(() => {
    getDownloadHistoryLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, sortDirection, limit, refreshData]);

  useEffect(() => {
    if (isInboxActive) {
      getDownloadHistoryLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInboxActive]);

  useEffect(() => {
    if (Object.keys(selectedItem).length > 0) {
      const tempList = logList.map((x) => {
        if (x.id === selectedItem.id) {
          x.selected = false;
        }
        return x;
      });

      setLogList(tempList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <ExportCsvMainPage data-testid="export-csv-content-page" />

      <CustomList
        meta={ExportTableMeta}
        data={logList}
        onRequestSort={onRequestSort}
        isReverseOrder={isReverseOrder}
        orderBy={orderBy}
        loading={fetching}
        Component={
          <PaginationActions
            filteredDatas={logList}
            setFilteredDatas={setLogList}
            handlerQueryDatas={exportLogListGql}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            totalDatas={totalExportLogList}
            setTotalDatas={setTotalExportLogList}
          />
        }
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user
});

export default compose(connect(mapStateToProps), withApollo)(ExportCsvContent);
