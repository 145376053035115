import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Auth } from "@aws-amplify/auth";

// AppSync client instantiation
export const appSyncClient = new AWSAppSyncClient({
  url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  region: process.env.REACT_APP_AWS_PROJECT_REGION,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken()
  },
  disableOffline: true
});
