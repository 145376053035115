import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { GET_IR_CATEGORY_MAPPING } from "../../gql/landingapi/queries";
import { withApollo } from "react-apollo";
import {
  AddContainer,
  AddContainerBody
} from "../instruments/addEditEquipment/AddEquipmentStyle";
import { OwcWorkflowOverlay } from "@one/react";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import FormSteps from "./FormSteps";
import useDialog from "../../utils/hooks/useDialog";
import FormActions from "./FormActions";
import MasterDataAttributes from "./MasterDataAttributes";
import MasterDataDimensions from "./MasterDataDimensions";
import MasterDataComplexAttributes from "./MasterDataComplexAttributes";
import { CREATE_IR_CATEGORY_MAPPING } from "../../gql/landingapi/mutations";
import Notify from "../notifications/Notify";
import { emptyMasterData } from "../../constants";

const MasterDataDialog = ({
  open,
  setOpen,
  client,
  stepList,
  setStepList,
  activeStep,
  setActiveStep,
  setRefreshFlag,
  isEditMode,
  userId
}) => {
  const formik = useFormikContext();
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [duplicateEntry, setDuplicateEntry] = useState(false);
  const { openDialog, ...dialogProps } = useDialog();

  useEffect(() => {
    for (let index = 0; index < stepList.length; index++) {
      stepList[index].valid = stepList[index].id < activeStep.id;
      stepList[index].completed = stepList[index].id < activeStep.id;
      stepList[index].active = stepList[index].id === activeStep.id;
    }
    setStepList([...stepList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (!isEditMode) {
      formik.resetForm({
        values: {
          ...emptyMasterData
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormContentByStep = () => {
    switch (activeStep.id) {
      case 0:
        return (
          <MasterDataAttributes
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            activeStep={activeStep}
            isEditMode={isEditMode}
          />
        );
      case 1:
        return (
          <MasterDataDimensions
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            activeStep={activeStep}
            isEditMode={isEditMode}
          />
        );
      case 2:
        return (
          <MasterDataComplexAttributes
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            activeStep={activeStep}
            isEditMode={isEditMode}
          />
        );
      default:
        return (
          <MasterDataAttributes
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            activeStep={activeStep}
            isEditMode={isEditMode}
          />
        );
    }
  };

  const nextButtonHandle = (formData) => {
    const newRowId = activeStep.id + 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };

  const prevButtonHandle = () => {
    const newRowId = activeStep.id - 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };

  const validateFormOnSubmit = async (masterData) => {
    let {
      equipmentCategory,
      manufacturer,
      equipmentModel,
      variant,
      modelName = "IRCategoryMapping"
    } = masterData;
    const result = await client.query({
      query: GET_IR_CATEGORY_MAPPING,
      fetchPolicy: "no-cache",
      variables: {
        equipmentCategory,
        manufacturer,
        equipmentModel,
        variant,
        modelName
      }
    });
    const unicityRecord = result?.data?.getIRCategoryMapping;
    if (unicityRecord) {
      setIsSubmitDisabled(false);
      setDuplicateEntry(true);
      return false;
    }
    return true;
  };

  const submitHandle = async (masterData) => {
    setIsSubmitDisabled(true);
    if (await validateFormOnSubmit(masterData)) {
      setIsSubmitDisabled(false);
      try {
        let inputData = {
          ...masterData,
          weightInkg: parseFloat(masterData?.weightInkg),
          heightInmm: parseInt(masterData?.heightInmm),
          lengthInmm: parseInt(masterData?.lengthInmm),
          depthInmm: parseInt(masterData?.depthInmm),
          clearanceLeftInmm: parseInt(masterData?.clearanceLeftInmm),
          clearanceRightInmm: parseInt(masterData?.clearanceRightInmm),
          clearanceHeightInmm: parseInt(masterData?.clearanceHeightInmm),
          clearanceToFrontInmm: parseInt(masterData?.clearanceToFrontInmm),
          clearanceBackwardsInmm: parseInt(masterData?.clearanceBackwardsInmm),
          createdById: userId,
          modelName: "IRCategoryMapping",
          heatOutputInW: parseInt(masterData?.heatOutputInW),
          pressureEquipment: masterData?.pressureEquipment,
          noiseIndBA: parseInt(masterData?.noiseIndBA),
          noiseSource: masterData?.noiseSource,
          noiseSourceDetails: masterData?.noiseSourceDetails,
          positioningNote: masterData?.positioningNote,
          positioning: masterData?.positioning,
          vibrationSensitive: masterData?.vibrationSensitive
        };
        const result = await client.mutate({
          mutation: CREATE_IR_CATEGORY_MAPPING,
          variables: {
            ...inputData
          },
          fetchPolicy: "no-cache"
        });
        if (result) {
          if (result?.errors) {
            Notify({
              type: "warning",
              icon: "caution",
              appName: "",
              text: `Details saved failed! `
            });
          }
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: `Details saved successfully! `
          });
        } else {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: `Details saved failed! `
          });
        }
      } catch (error) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: `Details saved failed! `
        });
      } finally {
        const activeRow = stepList.find((x) => x.id === 0);
        setActiveStep(activeRow);
        formik.resetForm();
        setOpen(false);
        setRefreshFlag((prevState) => !prevState);
        setIsSubmitDisabled(false);
      }
    }
  };

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      openDialog();
    }
  };

  return (
    <>
      <OwcWorkflowOverlay
        className="owcoverayZIndex"
        visible={open}
        onVisibleChange={visibleChangeHandler}
        disableBackdropClick
      >
        <>
          <div slot="title">Add equipment master data</div>
          <div
            slot="content"
            style={{
              padding: "0",
              display: "block",
              marginBottom: "0",
              height: "100%"
            }}
          >
            <FormSteps stepsArray={stepList} />
            <AddContainer id="add-edit-cluster-container">
              <AddContainerBody id="add-edit-cluster-body">
                {getFormContentByStep()}
              </AddContainerBody>
            </AddContainer>
            <ConfirmDialog
              {...dialogProps}
              approveText="Yes"
              approveColor="primary"
              approveVariant="contained"
              cancelText="No"
              cancelVariant="outlined"
              cancelColor="primary"
              onApprove={() => {
                const activeRow = stepList.find((x) => x.id === 0);
                setActiveStep(activeRow);
                formik.resetForm();
                setOpen(false);
              }}
              title="Cancel equipment master data"
              content="Do you want to cancel it?"
              disableBackdropClick={true}
            />
            <ConfirmDialog
              {...dialogProps}
              open={duplicateEntry}
              approveText="OK"
              approveColor="primary"
              approveVariant="contained"
              onApprove={() => {
                setDuplicateEntry(false);
                prevButtonHandle();
              }}
              title={"Master data already exists"}
              content="You cannot add equipment master data. The record already exists. Please enter new values."
              disableBackdropClick={true}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: "calc(100% - 120px)",
              backgroundColor: "var(--one-color-interaction-hover-brand-3)"
            }}
          >
            <div
              style={{
                width: "60%",
                margin: "auto",
                height: "calc(100% - 60px)",
                backgroundColor: "var(--one-color-interaction-disabled-base-1)"
              }}
            ></div>
          </div>
          <div slot="actions" style={{ padding: "16px" }}>
            <FormActions
              stepList={stepList}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isNextDisabled={isNextDisabled}
              setIsNextDisabled={setIsNextDisabled}
              prevButtonHandle={prevButtonHandle}
              nextButtonHandle={nextButtonHandle}
              submitHandle={submitHandle}
              cancelHandle={openDialog}
              isSubmitDisabled={isSubmitDisabled}
              isEditMode={isEditMode}
            ></FormActions>
          </div>
        </>
      </OwcWorkflowOverlay>
    </>
  );
};

export default withApollo(MasterDataDialog);
