import React from "react";

const RESUBSCRIBE_AFTER = 15 * 60 * 1000;

class ConnectSubscription extends React.Component {
  unsubscribeRef = null;

  doSubscribe() {
    const { documentSubscription, dataCallback } = this.props;
    if (
      !documentSubscription ||
      typeof dataCallback !== "function" ||
      typeof this.props.refetch !== "function"
    ) {
      return;
    }
    this.unsubscribeRef = this.props.subscribeToMore({
      document: documentSubscription,
      variables: { ...this.props.variables },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        return dataCallback(subscriptionData);
      }
    });

    this.timeoutId = setTimeout(() => {
      this.doUnsubscribe();
      this.props.refetch();
      this.doSubscribe();
    }, RESUBSCRIBE_AFTER);
  }

  doUnsubscribe() {
    if (typeof this.unsubscribeRef === "function") {
      this.unsubscribeRef();
    }
  }

  cancelTimeout() {
    clearTimeout(this.timeoutId);
  }

  componentDidMount() {
    this.doSubscribe();
  }
  componentWillUnmount() {
    this.doUnsubscribe();
    this.cancelTimeout();
  }

  render() {
    return null;
  }
}

export default ConnectSubscription;
