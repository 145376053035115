import { OwcTypography } from "@one/react";
import React from "react";
import { MainViewStyled } from "../importFile/ImportMainView";
import AdminTabs from "./AdminTabs";

const AdminMainView = () => {
  return (
    <MainViewStyled
      data-testid="admin-main-view"
      style={{ minWidth: "max-content" }}
    >
      <OwcTypography variant="title5" style={{ marginBottom: 20 }}>
        Admin panel
      </OwcTypography>
      <AdminTabs />
    </MainViewStyled>
  );
};

export default AdminMainView;
