import React, { useContext, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { FormComponents, CoverSpace } from "./AddEquipmentStyle";
import { removeSpaceInString } from "../../importFile/utils/helpers";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { StepperContext } from "../Context";
import { updateInstrumentDetail as updateInstrumentDetailAction } from "../redux/actions";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcIcon, OwcInput, OwcTypography } from "@one/react";
import { SOP_MANDATORY_CLP } from "../../../constants";
import LinkedInstanceStep from "./LinkedInstanceStep";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { defaultInputStyle } from "../../../utils/helpers/text";
import { SerialNumberInput } from "../../../components/shared/SerialNumberInput";

const EquipmentIdentification = ({
  isEditMode,
  updateInstrumentDetail,
  instrumentDetail,
  client,
  isUpdateBadge,
  sopHelperText,
  sites,
  isLinkedInstance
}) => {
  const secondaryIdValues = instrumentDetail?.linkedInstanceRef
    ?.secondaryIdMapping
    ? instrumentDetail?.linkedInstanceRef?.secondaryIdMapping
    : instrumentDetail?.secondaryIDMapping;
  const areThereAnySemiColon = secondaryIdValues?.includes(";");
  let disableArr = [];
  if (areThereAnySemiColon) {
    const fieldsName = secondaryIdValues?.split("; ");
    disableArr = fieldsName;
  } else {
    disableArr.push(secondaryIdValues);
  }

  const formik = useFormikContext();
  const classes = {};
  const { stepsArray, setStepsArray } = useContext(StepperContext);
  const [selectedSite, setSelectedSite] = useState(null);

  useEffect(() => {
    if (formik?.values?.siteName) {
      setSelectedSite(() => `${formik?.values?.siteName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.siteName]);

  const onSiteSelected = async (siteName) => {
    if (formik?.values?.siteName !== siteName?.siteName) {
      formik.setValues({
        ...formik.values,
        buildingLocation: null,
        floor: null,
        room: null,
        siteName: siteName?.siteName
      });
      setSelectedSite(() => siteName?.siteName ?? null);
      updateInstrumentDetail({
        ...instrumentDetail,
        buildingLocation: null,
        floor: null,
        room: null,
        siteName
      });
      if (isEditMode) {
        const updatedStepsArray = stepsArray.map((p) =>
          p.id === 2
            ? { ...p, error: true, completed: false }
            : { ...p, error: false, completed: true }
        );
        setStepsArray(updatedStepsArray);
      }
    }
  };

  const isDisabled = (fieldName) => {
    return disableArr.includes(fieldName) ? true : false;
  };

  return (
    <>
      <CoverSpace>
        <OwcTypography variant="caption">SAP Mapped Fields</OwcTypography>
        <OwcIcon
          style={{
            color: "var(--one-color-gray-600)",
            fontSize: 11,
            marginTop: -5,
            width: 100,
            position: "relative",
            float: "right",
            marginRight: -85
          }}
          name="certificate"
          type="legacy"
        />
      </CoverSpace>
      {!isEditMode && (
        <OwcTypography
          variant="subtitle2"
          style={{ padding: "0px 20px 20px 0px" }}
        >
          Basic identification
        </OwcTypography>
      )}
      <FormComponents>
        <>
          <LinkedInstanceStep
            isLinkedInstance={isLinkedInstance}
            sopHelperText={sopHelperText}
            isEditMode={isEditMode}
          />
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.siteName.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.siteName.value + ` *`}
            propValue="siteName"
            style={defaultInputStyle}
            list={sites}
            selected={selectedSite}
            onChange={(selectedValue) => {
              const selVal = sites?.find(
                (value, index) => index === selectedValue
              );
              onSiteSelected(selVal ?? null);
            }}
            // onOpen={onOpenSite}
            // onClose={onCloseSite}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.siteName.key]}
          />
        </>
        {!isDisabled(DATA_MODEL_TABLE.serialNumber.key) && (
          <div className="owcInputBox" style={{ width: 313.5 }}>
            <SerialNumberInput
              formik={formik}
              disabled={isDisabled(DATA_MODEL_TABLE.serialNumber.key)}
              assistiveText={sopHelperText && SOP_MANDATORY_CLP}
            />
          </div>
        )}
        {!isDisabled(DATA_MODEL_TABLE.materialNumber.key) && (
          <div className="owcInputBox">
            <OwcInput
              data-testid="text-field-material-number"
              variant="filled"
              style={defaultInputStyle}
              label={DATA_MODEL_TABLE.materialNumber.value}
              name={DATA_MODEL_TABLE.materialNumber.key}
              id={DATA_MODEL_TABLE.materialNumber.key}
              value={formik.values?.materialNumber
                ?.trim()
                .split(/\s+/)
                .join(" ")}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue(
                classes,
                formik,
                DATA_MODEL_TABLE.materialNumber.key,
                DATA_MODEL_TABLE.materialNumber.key,
                "text-field",
                isDisabled(DATA_MODEL_TABLE.materialNumber.key),
                false,
                isUpdateBadge
              )}
            >
              {formik.values[DATA_MODEL_TABLE.materialNumber.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.materialNumber.key] ===
                  true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() =>
                      formik.setFieldValue(
                        [DATA_MODEL_TABLE.materialNumber.key],
                        "",
                        true
                      )
                    }
                  />
                )}
            </OwcInput>
          </div>
        )}
        <div className="owcInputBox">
          <OwcInput
            data-testid="text-field-inventory-number"
            variant="filled"
            style={defaultInputStyle}
            label={DATA_MODEL_TABLE.inventoryNumber.value}
            name={DATA_MODEL_TABLE.inventoryNumber.key}
            id={DATA_MODEL_TABLE.inventoryNumber.key}
            value={formik.values?.inventoryNumber || ""}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              DATA_MODEL_TABLE.inventoryNumber.key,
              DATA_MODEL_TABLE.inventoryNumber.key,
              "text-field",
              false,
              false,
              false,
              sopHelperText
            )}
          >
            {formik.errors[DATA_MODEL_TABLE.inventoryNumber.key] &&
              formik.touched[DATA_MODEL_TABLE.inventoryNumber.key] === true && (
                <>
                  <span slot="error-text">
                    {formik.errors[DATA_MODEL_TABLE.inventoryNumber.key]}
                  </span>
                </>
              )}
            {formik.values[DATA_MODEL_TABLE.inventoryNumber.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.inventoryNumber.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.inventoryNumber.key],
                      "",
                      true
                    )
                  }
                />
              )}
            <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
          </OwcInput>
        </div>
        {!isDisabled(DATA_MODEL_TABLE.equipmentId.key) && (
          <div className="owcInputBox">
            <OwcInput
              data-testid="text-field-Equipment-ID"
              variant="filled"
              style={defaultInputStyle}
              label={DATA_MODEL_TABLE.equipmentId.value}
              name={DATA_MODEL_TABLE.equipmentId.key}
              id={DATA_MODEL_TABLE.equipmentId.key}
              value={removeSpaceInString(formik.values?.equipmentId)}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue(
                classes,
                formik,
                DATA_MODEL_TABLE.equipmentId.key,
                DATA_MODEL_TABLE.equipmentId.key,
                "text-field",
                isDisabled(DATA_MODEL_TABLE.equipmentId.key)
              )}
            >
              {formik.values[DATA_MODEL_TABLE.equipmentId.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.equipmentId.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() =>
                      formik.setFieldValue(
                        [DATA_MODEL_TABLE.equipmentId.key],
                        "",
                        true
                      )
                    }
                  />
                )}
            </OwcInput>
          </div>
        )}
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-RUDI`}
            label="RUDI"
            style={defaultInputStyle}
            name={DATA_MODEL_TABLE.instrumentRUDI.key}
            value={formik.values?.instrumentRUDI}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              DATA_MODEL_TABLE.instrumentRUDI.key,
              DATA_MODEL_TABLE.instrumentRUDI.key,
              "text-field",
              false,
              false,
              isUpdateBadge
            )}
          >
            {formik.values[DATA_MODEL_TABLE.instrumentRUDI.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.instrumentRUDI.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.instrumentRUDI.key],
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-GTIN`}
            label="GTIN"
            style={defaultInputStyle}
            name={DATA_MODEL_TABLE.instrumentGTIN.key}
            value={formik.values?.instrumentGTIN}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              DATA_MODEL_TABLE.instrumentGTIN.key,
              DATA_MODEL_TABLE.instrumentGTIN.key,
              "text-field",
              false
            )}
          >
            {formik.errors[DATA_MODEL_TABLE.instrumentGTIN.key] &&
              formik.touched[DATA_MODEL_TABLE.instrumentGTIN.key] === true && (
                <>
                  <span slot="error-text">
                    {formik.errors[DATA_MODEL_TABLE.instrumentGTIN.key]}
                  </span>
                </>
              )}
            {formik.values[DATA_MODEL_TABLE.instrumentGTIN.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.instrumentGTIN.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.instrumentGTIN.key],
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  sites: state.user.sites
});

export default compose(
  connect(mapStateToProps, {
    updateInstrumentDetail: updateInstrumentDetailAction
  }),
  withApollo
)(EquipmentIdentification);
