import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../components/shared/Table";
import { checkRoleInstrumentRepo } from "../../utils/helpers/checkRoleInstrumentRepo";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import StatusLogTableMeta from "./StatusLogTableMeta";
import { getAllData } from "../../utils/helpers/fetching";
import { LIST_IR_UPLOAD_STATUS } from "../../gql/landingapi/queries";
import {
  IR_EQUIPMENT_UPLOAD_TARGET_MODULE,
  IR_UPLOAD_STATUS_MODEL_NAME
} from "../../constants";
import { sortBy, uniqBy, filter } from "lodash";
import { CommonContextWrapper } from "../../common-context/context";
import { getCurrentDatas } from "../instruments/instrument-repository-pagination/PaginationActions";

const StatusLogTable = ({
  client,
  isMasterDataTable = false,
  setImportedUsers,
  importedBy,
  importedType = null
}) => {
  const [statusLogList, setStatusLogList] = useState([]);
  const {
    dispatchAction,
    fetching,
    limit,
    nextToken,
    sortDirection,
    orderBy,
    refreshData
  } = useContext(PaginationContext);
  const [isReverseOrder, setIsReverseOrder] = useState(
    sortDirection === "ASC" ? true : false
  );
  const { saveLastPageSize } = useContext(CommonContextWrapper);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalStatusLogs, setTotalStatusLogs] = useState([]);
  const history = useHistory();

  const statusLogGql = async (isTokenNull = false) => {
    let variables = {
      nextToken: isTokenNull ? null : nextToken,
      sortDirection,
      modelName: IR_UPLOAD_STATUS_MODEL_NAME
    };
    if (isMasterDataTable) {
      const filterObj = importedType
        ? { and: { targetModule: { eq: importedType?.targetModule } } }
        : {
            not: { targetModule: { eq: IR_EQUIPMENT_UPLOAD_TARGET_MODULE } }
          };
      variables = {
        ...variables,
        filter: filterObj
      };
    } else {
      variables = {
        ...variables,
        filter: {
          and: { targetModule: { eq: IR_EQUIPMENT_UPLOAD_TARGET_MODULE } }
        }
      };
    }
    return getAllData({
      client,
      query: LIST_IR_UPLOAD_STATUS,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "uploadStatusByModelNameAndUploadDate"],
      drillData: true
    });
  };

  const setImportedByList = (items) => {
    let list = items.map((item) => ({
      ...item,
      key: item?.userName?.items[0]?.email || item?.email,
      value:
        item?.userName?.items[0]?.givenName &&
        item?.userName?.items[0]?.familyName &&
        item?.userName?.items[0]?.name
          ? `${item?.userName?.items[0]?.givenName} ${item?.userName?.items[0]?.familyName} - ${item?.userName?.items[0]?.name}`
          : item?.userName?.items[0]?.givenName &&
            item?.userName?.items[0]?.familyName
          ? `${item?.userName?.items[0]?.givenName} ${item?.userName?.items[0]?.familyName}`
          : item?.userName?.items[0]?.email || item?.email
    }));
    list = sortBy(uniqBy(list, "email"), "value");
    setImportedUsers(list);
  };

  /**
   * This method is used to get the list of status log for the uploaded files in IR
   */
  const getStatusLogList = async () => {
    dispatchAction({
      type: "fetching",
      payload: true
    });
    setPageIndex(0);
    const { items, nextToken: token } = await statusLogGql(true);
    dispatchAction({
      type: "nextToken",
      payload: { token: token, data: items }
    });
    const filteredList = importedBy
      ? filter(items, { email: importedBy })
      : items;
    const currentItems = getCurrentDatas(limit, 0, filteredList);
    setTotalStatusLogs(filteredList);
    setImportedByList(items);
    setStatusLogList(currentItems);
  };

  useEffect(() => {
    const fetchRolePermission = async () => {
      const actions = await checkRoleInstrumentRepo();
      if (!actions?.showStatusLog) {
        history?.goBack();
      }
    };
    fetchRolePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStatusLogList();
    saveLastPageSize(limit, "ir_status_log");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, sortDirection, limit, refreshData, importedBy, importedType]);

  const onRequestSort = (key, isReverse) => {
    dispatchAction({
      type: "sortDirection",
      payload: {
        orderBy: key,
        sortDirection: isReverse
      }
    });
    setIsReverseOrder(isReverse);
  };

  const refreshTableEvent = () => {
    getStatusLogList();
  };

  return (
    <>
      <CustomTable
        filteredDatas={statusLogList}
        setFilteredDatas={setStatusLogList}
        handlerQueryDatas={statusLogGql}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        totalDatas={totalStatusLogs}
        setTotalDatas={setTotalStatusLogs}
        meta={StatusLogTableMeta}
        data={statusLogList}
        fieldArray={[]}
        onRequestSort={onRequestSort}
        isReverseOrder={isReverseOrder}
        orderBy={orderBy}
        fetching={fetching}
        refreshTableEvent={refreshTableEvent}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default compose(connect(mapStateToProps), withApollo)(StatusLogTable);
