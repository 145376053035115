import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TogglerContext } from "./contexts";
import DATA_LABEL_TABLE from "./utils/constants/dataLabelTable";
import AdminPanelSettings from "./icons/AdminPanelSettings";
import { checkRoleInstrumentRepo } from "./utils/helpers/checkRoleInstrumentRepo";
import { ADMIN_ROLE } from "./constants";
import {
  OwcIconButton,
  OwcListItem,
  OwcTooltip,
  OwcTypography
} from "@one/react";
import styles from "./DrawerMenu.module.scss";
import { OwcDrawer } from "@one/react";

const DrawerMenu = () => {
  const location = useLocation();
  const { isMini, setIsMini, isVisible } = useContext(TogglerContext);
  const [actionStatus, setActionStatus] = useState({});
  const [activeMenuPath, setActiveMenuPath] = useState(null);
  useEffect(() => {
    const fetchRolePermission = async () => {
      const actions = await checkRoleInstrumentRepo();
      setActionStatus(actions);
    };
    fetchRolePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  useEffect(() => {
    setActiveMenuPath(location?.pathname.trim());
  }, [location.pathname]);
  return (
    <>
      {isVisible && (
        <div
          className={styles.drawermenuOwcdrawer}
          // style={{ height: containerHeight }}
        >
          <OwcDrawer
            data-testid="app-drawer"
            visible
            mode="push"
            mini={isMini}
            hideBackdrop
          >
            <div slot="header" className={styles.drawermenuHeader}>
              <div className={styles.drawermenuMenuText}>
                <OwcIconButton
                  flat
                  id="menu-button"
                  icon="menu"
                  style={{ margin: "8px" }}
                  onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
                />
                <OwcTypography variant="button">Menu</OwcTypography>
              </div>
              <OwcIconButton
                flat
                id="collapse-button"
                icon="clear"
                onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
              />
            </div>
            <div slot="panel" style={{ width: 278 }}>
              <div data-testid="menu-list">
                <OwcListItem
                  data-testid="drawer-menu-item-icon"
                  key="drawer-menu-home-item"
                  id="drawer-menu-home-item"
                  icon="table_of_content"
                  iconType="legacy"
                  noBorder
                  onClick={() => history?.replace("/")}
                  selected={activeMenuPath === "/"}
                >
                  <OwcTooltip anchor={"drawer-menu-home-item"} offset={[0, 0]}>
                    {DATA_LABEL_TABLE.LEFT_MENU_BAR.equiment_list_menu_label}
                  </OwcTooltip>
                  {DATA_LABEL_TABLE.LEFT_MENU_BAR.equiment_list_menu_label}
                </OwcListItem>

                {actionStatus.hasRole === ADMIN_ROLE && (
                  <OwcListItem
                    data-testid="drawer-menu-item-icon"
                    key="drawer-menu-import-item"
                    id="drawer-menu-import-item"
                    icon="import"
                    iconType="legacy"
                    noBorder
                    onClick={() => history?.replace("/import")}
                    selected={activeMenuPath === "/import"}
                  >
                    <OwcTooltip
                      anchor={"drawer-menu-import-item"}
                      offset={[0, 0]}
                    >
                      {
                        DATA_LABEL_TABLE.LEFT_MENU_BAR
                          .equipment_import_menu_label
                      }
                    </OwcTooltip>
                    {DATA_LABEL_TABLE.LEFT_MENU_BAR.equipment_import_menu_label}
                  </OwcListItem>
                )}
                {actionStatus.hasRole === ADMIN_ROLE && (
                  <OwcListItem
                    data-testid="drawer-menu-item-icon"
                    noBorder
                    onClick={() => history?.replace("/admin")}
                    selected={activeMenuPath === "/admin"}
                    key="drawer-menu-admin-item"
                    id="drawer-menu-admin-item"
                  >
                    <OwcTooltip
                      anchor={"drawer-menu-admin-item"}
                      offset={[0, 0]}
                    >
                      {
                        DATA_LABEL_TABLE.LEFT_MENU_BAR
                          .equipment_admin_menu_label
                      }
                    </OwcTooltip>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <AdminPanelSettings />
                      <span style={{ marginLeft: 12 }}>
                        {
                          DATA_LABEL_TABLE.LEFT_MENU_BAR
                            .equipment_admin_menu_label
                        }
                      </span>
                    </div>
                  </OwcListItem>
                )}
              </div>
            </div>
          </OwcDrawer>
        </div>
      )}
    </>
  );
};
export default DrawerMenu;
