import * as types from "./actionTypes";
import initialState from "./initialState";

export default function reducer(
  state = initialState,
  action = { type: "", payload: {} }
) {
  if (action?.type === types.LOAD_USER_INFO) {
    return { ...state, ...action?.payload };
  }
  if (action?.type === types.LOAD_LAST_FILTER) {
    return {
      ...state,
      lastFilter: {
        ...action?.payload
      }
    };
  }
  if (action?.type === types.LOAD_ACTION_STATUS) {
    return {
      ...state,
      actionStatus: {
        ...action?.payload
      }
    };
  }
  if (action?.type === types.UPDATE_USER_FILTERS) {
    return {
      ...state,
      userFilters: action?.payload
    };
  }
  if (action?.type === types.UPDATE_USER_DISPLAY_COLUMNS) {
    return {
      ...state,
      userDisplays: action?.payload
    };
  }
  if (action?.type === types.UPDATE_LAST_FAV_FILTER) {
    return {
      ...state,
      lastFavFilter: action?.payload
    };
  }
  if (action?.type === types.UPDATE_LAST_PAGE_SIZE) {
    return {
      ...state,
      lastPageSize: action?.payload
    };
  }
  if (action?.type === types.UPDATE_USER_LAST_DISPLAY_COLUMN) {
    return {
      ...state,
      lastDisplayColumns: action?.payload
    };
  }
  if (action?.type === types.UPDATE_USER_SYSTEM_DISPLAY_COLUMN) {
    return {
      ...state,
      systemDisplays: action?.payload
    };
  }
  if (action?.type === types.UPDATE_SEARCH_PREFERENCE) {
    return {
      ...state,
      searchPreference: action?.payload
    };
  }
  return state;
}
