import React, { useContext } from "react";
import { GroupContext } from "./context";
import { useFormikContext } from "formik";
import { find } from "lodash";
import { OwcIconButton } from "@one/react";
import Tooltip from "@mui/material/Tooltip";

const Actions = ({ item }) => {
  const { setDialogOpen, setIsEditMode, userList } = useContext(GroupContext);
  const formik = useFormikContext();
  const createUserObj = (list, usersList) => {
    const filteredList = [];
    list.forEach((group) => {
      const obj = find(usersList, { email: group?.email });
      if (obj) filteredList.push(obj);
    });
    return filteredList;
  };
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
      <Tooltip
        data-testid={"equipment-edit-button-tooltip" + item.groupName}
        title={"Edit group access"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          onClick={() => {
            setIsEditMode(true);
            formik.resetForm();
            formik.setFieldValue("groupName", item.groupName, true);
            formik.setFieldValue(
              "userIds",
              createUserObj(item.userIds, userList),
              true
            );
            setDialogOpen(true);
          }}
          icon="edit"
          type="legacy"
          flat
        >
          {/* 
          data-testid
          <EditIcon
            fontSize="small"
            data-testid="booking-admin-table-edit-instrument-icon"
          /> */}
        </OwcIconButton>
      </Tooltip>
    </div>
  );
};
export default Actions;
