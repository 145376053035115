import { find, sortBy } from "lodash";
import {
  MY_LAB,
  // SOP_RT,
  TOOL_PANELS,
  entryType
} from "./dLabGridConstant";
import Snackbar from "../../snackbar/Snackbar";
import Notify from "../../notifications/Notify";
import { CloudSearchConfig } from "../../../components/shared/CloudSearchConfig";
import { convertToCamelCase } from "../../../utils/helpers/converters";
import { SPLITTER, equipmentStatus } from "../../../constants";

export const checkNullInArrayOfStr = (list = []) => {
  if (!list || list?.length === 0) return false;
  let isNull = false;
  let index = 0;
  do {
    if (list && !list[index]) {
      isNull = true;
      break;
    }
    index++;
  } while (index < list.length);

  return isNull;
};

export const onlyUnique = (list) => {
  return [...new Set(list)];
};

export const convertStrToObjColumns = (savedColumn) => {
  let displayColumn =
    typeof savedColumn === "string" ? JSON.parse(savedColumn) : savedColumn;
  displayColumn = {
    ...displayColumn,
    displayDefinition:
      typeof displayColumn?.displayDefinition === "string"
        ? JSON.parse(displayColumn?.displayDefinition)
        : displayColumn?.displayDefinition
  };

  return displayColumn;
};

export const convertStrToObjFilters = (savedFilter) => {
  let filterModel =
    typeof savedFilter === "string" ? JSON.parse(savedFilter) : savedFilter;
  filterModel = {
    ...filterModel,
    filterDefinition:
      typeof filterModel?.filterDefinition === "string"
        ? JSON.parse(filterModel?.filterDefinition)
        : filterModel?.filterDefinition
  };
  return filterModel;
};

export const getIndex = (arr, key, value) => {
  return arr.findIndex((obj) => obj[key] === value);
};

export const replaceDateUtil = (value, operator) => {
  let date = `${value?.replace(/ /g, "T")}Z`;
  date = date?.replace("00:00:00", "00:00:01");
  if (operator === "lessThan") {
    date = date?.replace("00:00:01", "00:00:00");
  } else if (operator === "greaterThan") {
    date = date?.replace("00:00:01", "00:00:02");
  }
  return date;
};
export const exactSearchString = (values) => {
  return values?.map((value) => {
    return `"${value}"`;
  });
};

export const getFilterModelQuery = ({
  filterModel,
  type,
  filterName = "",
  fullSearchQuery = "",
  masterDatas = {}
}) => {
  let filterTextQuerys = [];

  for (const key in filterModel) {
    let query = ``;
    let queries = [];
    // let conditions = [];
    if (type === "set") {
      const removeIndex = filterModel[key]?.values.findIndex((item) => !item);
      let blank = null;
      if (
        masterDatas[convertToCamelCase(key)]?.length ===
        filterModel[key]?.values?.length
      ) {
        query = `(${key}:*`;

        if (removeIndex !== -1) {
          query = `${query} OR (*:* OR -${key}:*)`;
        }
        query = `${query})`;
      } else {
        if (removeIndex !== -1) {
          blank = filterModel[key]?.values?.splice(removeIndex, 1);
        }
        if (filterModel[key]?.values?.length !== 0) {
          let updatedArray = [];
          updatedArray = exactSearchString(filterModel[key]?.values)?.map(
            (value) => `${key}:${value}`
          );
          query = `(${updatedArray?.join(SPLITTER.OR)})`;
        }

        if (blank) {
          query = query
            ? `(${query} OR (*:* OR -${key}:*))`
            : `(*:* OR -${key}:*)`;
        }
      }
    } else if (filterModel[key]?.operator) {
      let testConditions = [];
      let conditions = [];
      if (filterModel[key]?.conditions) {
        conditions = [...filterModel[key]?.conditions];
      } else {
        conditions = [
          filterModel[key]?.condition1,
          filterModel[key]?.condition2
        ];
      }
      testConditions = [...conditions];
      testConditions?.forEach((condition) => {
        if (condition?.type === "equals") {
          queries?.push(`${key}:${condition?.filter}`);
        } else if (condition?.type === "contains") {
          queries?.push(`${key}:*${condition?.filter}*`);
        } else if (condition?.type === "notContains") {
          queries?.push(`-${key}:*${condition?.filter}*`);
        } else if (condition?.type === "notEqual") {
          queries?.push(`-${key}:${condition?.filter}`);
        } else if (
          condition?.type === "lessThan" ||
          condition?.type === "lessThanOrEqual"
        ) {
          queries?.push(`${key}:[* TO ${condition?.filter}}`);
        } else if (
          condition?.type === "greaterThan" ||
          condition?.type === "greaterThanOrEqual"
        ) {
          queries?.push(`${key}:{${condition?.filter} TO *]`);
        } else if (condition?.type === "notBlank") {
          queries?.push(
            type === "text" ? `(*:* OR ${key}:*)` : `${key}:[* TO *]`
          );
        } else {
          queries?.push(
            type === "text" ? `(*:* OR -${key}:*)` : `-${key}:[* TO *]`
          );
        }
      });
      query = `(${queries?.join(` ${filterModel[key]?.operator} `)})`;
    } else {
      if (filterModel[key]?.type === "equals") {
        query =
          type === "date"
            ? `${key}:"${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}"`
            : `${key}:${filterModel[key]?.filter}`;
      } else if (filterModel[key]?.type === "contains") {
        query = `${key}:*${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "notContains") {
        query = `-${key}:*${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "startsWith") {
        query = `${key}:${filterModel[key]?.filter}*`;
      } else if (filterModel[key]?.type === "endsWith") {
        query = `${key}:*${filterModel[key]?.filter}`;
      } else if (filterModel[key]?.type === "notEqual") {
        query =
          type === "date"
            ? `-${key}:"${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}"`
            : `-${key}:${filterModel[key]?.filter}`;
      } else if (
        filterModel[key]?.type === "lessThan" ||
        filterModel[key]?.type === "lessThanOrEqual"
      ) {
        query =
          type === "date"
            ? `${key}:[* TO ${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )}]`
            : `${key}:[* TO ${filterModel[key]?.filter}}`;
      } else if (
        filterModel[key]?.type === "greaterThan" ||
        filterModel[key]?.type === "greaterThanOrEqual"
      ) {
        query =
          type === "date"
            ? `${key}:[${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )} TO *]`
            : `${key}:{${filterModel[key]?.filter} TO *]`;
      } else if (filterModel[key]?.type === "inRange") {
        query =
          type === "date"
            ? `${key}:[${replaceDateUtil(
                filterModel[key]?.dateFrom,
                filterModel[key]?.type
              )} TO ${replaceDateUtil(
                filterModel[key]?.dateTo,
                filterModel[key]?.type
              )}]`
            : `${key}:[${filterModel[key]?.filter} TO ${filterModel[key]?.filterTo}]`;
      } else if (filterModel[key]?.type === "notBlank") {
        query = type === "text" ? `(*:* OR ${key}:*)` : `${key}:[* TO *]`;
      } else {
        query = type === "text" ? `(*:* OR -${key}:*)` : `-${key}:[* TO *]`;
      }
    }
    filterTextQuerys?.push(query);
  }

  let finalStringSearchQuery =
    filterTextQuerys?.length > 1
      ? filterTextQuerys?.join(SPLITTER.AND)
      : filterTextQuerys[0] || "";

  if (filterName === MY_LAB) {
    if (
      !fullSearchQuery.includes(`status:'${equipmentStatus?.active?.value}'`)
    ) {
      finalStringSearchQuery += ` AND (status:'${equipmentStatus?.active?.value}')`;
    }
  }

  return finalStringSearchQuery;
};

export const onAgGridFilterApply = ({
  gridRef,
  savedFilterModel,
  filterKeyMapping
}) => {
  if (gridRef && gridRef?.current?.api) {
    let obj = {};
    for (const key in savedFilterModel) {
      if (!(key === "dateFrom" || key === "dateTo" || key === "inventoryIds")) {
        if (savedFilterModel[key]) {
          if (Array.isArray(savedFilterModel[key] || key)) {
            obj[key] = {
              values: savedFilterModel[key],
              filterType: "set"
            };
          } else {
            obj[key] = savedFilterModel[key];
          }
        }
      }
    }
    gridRef.current.api.setFilterModel(obj);
    return { currentSidePannel: gridRef.current.api.getOpenedToolPanel() };
  }
};

export const onAgGridColumnApply = ({ gridRef, savedShowcolumns }) => {
  if (gridRef && gridRef?.current?.columnApi) {
    let columnState = gridRef.current.columnApi.getColumnState();
    const showColumns = sortBy(savedShowcolumns, ["order"], ["asc"]);
    showColumns.forEach((column, orderIndex) => {
      const index = getIndex(columnState, "colId", column.key);
      const splicedObjArray = columnState.splice(index, 1);
      const splicedObj = splicedObjArray[0];
      splicedObj.hide = false;
      columnState.splice(orderIndex, 0, splicedObj);
    });
    columnState?.map((column, index) => {
      if (index > showColumns?.length - 1 && column?.colId !== "actions") {
        column.hide = true;
      }

      return column;
    });
    // apply order
    gridRef.current.columnApi.applyColumnState({
      state: columnState,
      applyOrder: true
    });
  }
};
//======================FILTER FUNCTIONC===================
export const onUpdateFilter = async ({
  lastFavFilter,
  isSaveConfigUpdated = false,
  updateRedux = true,
  id,
  email,
  client,
  query,
  userProfileQuery,
  updateLastFavFilter = null,
  filterModel
}) => {
  let lastFilterData = {};
  lastFilterData.lastFavFilter = lastFavFilter;
  lastFilterData = JSON.stringify(lastFilterData);
  let result = null;
  try {
    // result = await client.mutate({
    //   mutation: userProfileQuery,
    //   variables: { id, email, lastFilter: lastFilterData }
    // });

    if (isSaveConfigUpdated) {
      const filterDefinition =
        typeof lastFavFilter?.filterDefinition === "string"
          ? lastFavFilter?.filterDefinition
          : JSON.stringify(lastFavFilter?.filterDefinition);
      await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          filterName: lastFavFilter?.filterName,
          filterDefinition,
          filterModel
        },
        fetchPolicy: "no-cache"
      });
    }

    if (isSaveConfigUpdated) {
      Snackbar({
        appName: "",
        icon: "circle_confirm",
        type: "success",
        text: "Updated filter successfully"
      });
    }
  } catch (error) {
    if (isSaveConfigUpdated) {
      Snackbar({
        appName: "",
        text: "Failed to update filter"
      });
    }
    result = null;
  }
  if (updateRedux && updateLastFavFilter) {
    updateLastFavFilter(lastFavFilter);
  }
  return result;
};

export const onCreatefilter = async ({
  id,
  email,
  client,
  userFilters,
  lastFavFilter,
  query,
  userProfileQuery,
  updateUserFilters = null,
  updateLastFavFilter = null,
  filterModel
}) => {
  let result = null;
  try {
    const FilterNameMatch = userFilters.filter(
      (item) => item.filterName === lastFavFilter?.filterName?.trim()
    );
    if (FilterNameMatch.length === 0 && lastFavFilter?.filterName !== MY_LAB) {
      result = await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          filterDefinition: JSON.stringify(lastFavFilter?.filterDefinition),
          filterName: lastFavFilter.filterName?.trim(),
          filterModel: filterModel
        }
      });
      if (result) {
        await onUpdateFilter({
          lastFavFilter,
          updateLastFavFilter,
          userProfileQuery,
          id,
          email,
          client,
          filterModel
        });
        if (updateUserFilters) {
          updateUserFilters([...userFilters, lastFavFilter]);
        }
      }
      Snackbar({
        appName: "",
        icon: "circle_confirm",
        type: "success",
        text: "Filters successfully saved"
      });
    } else {
      Snackbar({
        appName: "",
        text: "Duplicate filter name"
      });
    }
  } catch (err) {
    console.log(err);
    Snackbar({
      appName: "",
      text: "Filter creation failure"
    });
  }

  return result;
};

//===============COLUMN FUNCTIONS=================
export const onUpdateSelectColumn = async ({
  data,
  isSaveConfigUpdated = false,
  updateRedux = true,
  id,
  email,
  client,
  // userProfileQuery,
  query,
  userDisplayList,
  updateUserDisplayColumns = null,
  updateLastDisplayColumn = null,
  displayModel
}) => {
  let result = null;
  try {
    // result = await client.mutate({
    //   mutation: userProfileQuery,
    //   variables: { id, email, lastDisplayColumns: data },
    //   fetchPolicy: "no-cache"
    // });

    if (isSaveConfigUpdated) {
      const updateData = typeof data === "string" ? JSON.parse(data) : data;
      const displayDefinition =
        typeof updateData?.displayDefinition === "string"
          ? updateData?.displayDefinition
          : JSON.stringify(updateData?.displayDefinition);
      await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          displayName: updateData?.displayName,
          displayDefinition,
          displayModel: displayModel
        },
        fetchPolicy: "no-cache"
      });
      const displayObj = typeof data === "string" ? JSON.parse(data) : data;
      userDisplayList.current = userDisplayList.current?.map((userDisplay) => {
        if (displayObj?.displayName === userDisplay?.displayName) {
          return displayObj;
        }
        return userDisplay;
      });

      if (updateUserDisplayColumns) {
        updateUserDisplayColumns([...userDisplayList.current]);
      }
    }

    if (isSaveConfigUpdated) {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Updated columns set successfully `
      });
    }
  } catch (error) {
    if (isSaveConfigUpdated) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to update columns set`
      });
    }
    return null;
  }

  if (updateRedux && updateLastDisplayColumn) {
    updateLastDisplayColumn(data);
  }

  return result;
};

export const onCreateSelectColumn = async ({
  id,
  email,
  userDisplayList,
  unParsedData,
  client,
  query,
  userProfileQuery,
  updateUserDisplayColumns = null,
  updateLastDisplayColumn = null,
  displayModel
}) => {
  let result = null;

  const columnMatches = userDisplayList.current.filter(
    (item) => item.displayName === unParsedData?.displayName?.trim()
  );
  if (
    columnMatches?.length === 0 &&
    unParsedData?.displayName !== "SOP RT0020"
  ) {
    let columnObj = {
      displayName: unParsedData?.displayName,
      userID: id,
      displayDefinition: JSON.stringify(unParsedData?.displayDefinition),
      displayModel: displayModel
    };

    try {
      result = await client.mutate({
        mutation: query,
        variables: columnObj,
        fetchPolicy: "no-cache"
      });

      if (result) {
        await onUpdateSelectColumn({
          data: JSON.stringify(unParsedData),
          updateLastDisplayColumn,
          // userProfileQuery,
          id,
          email,
          client,
          displayModel
        });

        userDisplayList.current = [
          ...userDisplayList.current,
          result?.data?.createUserDisplay
        ];
        if (updateUserDisplayColumns) {
          updateUserDisplayColumns([...userDisplayList.current]);
        }
      }

      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Columns Set successfully saved`
      });
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to save columns set`
      });
    }
  } else {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Duplicate column name"
    });
  }
  return result;
};

//==============onChip delete======================

export const onDeleteSaveConfigurations = async ({
  userID,
  email,
  client,
  chipToDelete,
  panel,
  lastSavedChip,
  userProfileQuery,
  deleteFilterQuery,
  deleteColumnQuery,
  userFilters,
  userDisplayList,
  updateUserFilters = null,
  updateUserDisplayColumns = null,
  updateLastFavFilter = null,
  updateLastDisplayColumn = null,
  displayModel
}) => {
  let result = null;
  let variables = { userID: chipToDelete?.userID || userID };
  if (panel === TOOL_PANELS?.filters) {
    variables = {
      ...variables,
      filterName: chipToDelete?.filterName,
      filterModel: displayModel
    };
  } else if (panel === TOOL_PANELS?.columns) {
    variables = {
      ...variables,
      displayName: chipToDelete?.displayName,
      displayModel: displayModel
    };
  }
  try {
    result = await client.mutate({
      mutation:
        panel === TOOL_PANELS?.filters ? deleteFilterQuery : deleteColumnQuery,
      variables
    });
    if (panel === TOOL_PANELS?.filters) {
      const removeIndex = userFilters.findIndex(
        (item) => item.filterName === chipToDelete?.filterName
      );
      userFilters.splice(removeIndex, 1);
      if (lastSavedChip?.filterName === chipToDelete?.filterName) {
        await onUpdateFilter({
          lastFavFilter: {
            filterDefinition: chipToDelete?.filterDefinition,
            filterName: ""
          },
          userProfileQuery,
          updateLastFavFilter,
          id: userID,
          email,
          client,
          filterModel: displayModel
        });
      }
      const updateFilter = [...userFilters];
      if (find(updateFilter, { filterName: MY_LAB })) {
        updateFilter.shift();
      }
      if (updateUserFilters) {
        updateUserFilters([...updateFilter]);
      }
    } else {
      const removeIndex = userFilters.findIndex(
        (item) => item.displayName === chipToDelete?.displayName
      );
      userFilters.splice(removeIndex, 1);
      const savedColumn =
        typeof lastSavedChip === "string"
          ? JSON.parse(lastSavedChip)
          : lastSavedChip;
      if (savedColumn?.displayName === chipToDelete?.displayName) {
        await onUpdateSelectColumn({
          data: JSON.stringify({
            displayDefinition:
              typeof chipToDelete?.displayDefinition === "string"
                ? JSON.parse(chipToDelete?.displayDefinition)
                : chipToDelete?.displayDefinition,
            displayName: ""
          }),
          updateLastDisplayColumn,
          userProfileQuery,
          id: userID,
          email,
          client,
          displayModel
        });
      }
      const updateColumns = [...userFilters];
      // if (find(updateColumns, { displayName: SOP_RT })) {
      //   updateColumns.shift();
      // }
      userDisplayList.current = [...updateColumns];
      if (updateUserDisplayColumns) {
        updateUserDisplayColumns([...updateColumns]);
      }
    }
    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text:
        panel === TOOL_PANELS?.filters
          ? "Filter successfully deleted"
          : "Columns set successfully deleted"
    });
  } catch (err) {
    Snackbar({
      appName: "",
      text:
        panel === TOOL_PANELS?.filters
          ? "Filter deletion failure"
          : "Columns set deletion failure"
    });
  }
  return { result, userFilters, panel };
};

export const saveLastPageSize = async ({
  limit,
  limitKey,
  lastPageSize,
  query,
  updateLastPageSize,
  id,
  email,
  client
}) => {
  try {
    if (typeof lastPageSize === "string" && lastPageSize !== "") {
      lastPageSize = JSON.parse(lastPageSize);
    }
    lastPageSize = { ...lastPageSize };
    lastPageSize[limitKey] = limit;
    lastPageSize = JSON.stringify(lastPageSize);

    await client.mutate({
      mutation: query,
      variables: { id, email, lastPageSize }
    });
    updateLastPageSize(lastPageSize);
  } catch (err) {
    console.log(" last page size fetch!", { err });
  }
};

export const getClusterSubEquipments = async (clusterEquipments) => {
  const obj = {
    negatefield: "-cluster_id:*",
    sort: "asc",
    start: 0,
    size: 10000,
    sortBy: "serial_number",
    searchValue: getFilterModelQuery({
      filterModel: { _id: { values: clusterEquipments } },
      type: "set",
      filterName: MY_LAB
    })
  };
  let result = await CloudSearchConfig(obj);
  return result?.data;
};

const checkShouldKeepFetching = (items, totalCount) => {
  return items.length < totalCount;
};

const fetchOne = async ({ variables, items }) => {
  const result = await CloudSearchConfig(variables);
  items = [...items, ...result?.data];
  let totalCount = result?.count;
  const shouldKeepFetching = checkShouldKeepFetching(items, totalCount);

  return { items, shouldKeepFetching, totalCount };
};

export const getAllCloudSearchData = async ({ requestObj = {} }) => {
  try {
    let items = [];
    let shouldKeepFetching = true;
    do {
      ({ items, shouldKeepFetching } = await fetchOne({
        variables: {
          negatefield: requestObj?.negatefield || "-cluster_id:*",
          sort: requestObj?.sort || "asc",
          sortBy: requestObj?.sortBy || "serial_number",
          start: items?.length,
          size: requestObj?.size || 10000,
          searchValue:
            requestObj?.searchValue ||
            `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
        },
        items
      }));
    } while (shouldKeepFetching);
    return {
      items,
      error: null
    };
  } catch (err) {
    return { items: [], error: err };
  }
};

export const getMegaClusterIds = async () => {
  const requestObj = {
    negatefield: "-cluster_id:*",
    sort: "asc",
    sortBy: "serial_number",
    start: 0,
    size: 10000,
    searchValue: `-entry_type:'${entryType?.clusterSubequipment}' AND entry_type:'${entryType?.cluster}' AND cluster_id:*`
  };
  let result = await getAllCloudSearchData({ requestObj });
  let mclusterIds = [];
  if (result?.items?.length > 0) {
    mclusterIds = result?.items?.map((item) => item?.cluster_id[0]);
  }
  return mclusterIds;
};
