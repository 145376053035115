import React from "react";
import { ConfirmDialog } from "../../components/shared";
import { CreateFieldStyled } from "./ReasonMainPageStyle";
import { useFormikContext } from "formik";
import { OwcButton } from "@one/react";
import { ReasonContext } from "./context";
import { useContext } from "react";
import { deleteReason } from "./ReasonHelper";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { DELETE_REASON_DIALOG_MSG } from "../../constants";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";

const DeleteReasonDialog = ({ open, onCancel, client }) => {
  const formik = useFormikContext();
  const { setDialogCancelOpen } = useContext(ReasonContext);
  const { dispatchAction } = useContext(PaginationContext);
  return (
    <ConfirmDialog
      open={open}
      close={onCancel}
      approveText={"Delete reason"}
      approveColor="error"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      isDivider
      testid={"import-file-dialog"}
      title={"Delete reason"}
      renderActions={({ cancelText, approveText }) => (
        <div style={{ display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              deleteReason({
                formik,
                setDialogCancelOpen,
                dispatchAction,
                client
              });
            }}
            variant="error"
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>{DELETE_REASON_DIALOG_MSG}</CreateFieldStyled>
    </ConfirmDialog>
  );
};

// export default DeleteReasonDialog;
const mapStateToProps = (state) => ({
  user: state.user
});
export default compose(
  connect(mapStateToProps),
  withApollo
)(DeleteReasonDialog);
