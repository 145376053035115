import { identificationValidationSchema } from "./identificationValidationSchema";
import { descriptionValidationSchema } from "./descriptionValidationSchema";
import { gxpValidationSchema } from "./gxpValidationSchema";
import { laboratoryValidationSchema } from "./laboratoryValidationSchema";
import { analyzerConfigurationSchema } from "./analyzerConfigurationSchema";
import {
  emptyEquipmentDescription,
  emptyInstrumentIdentification,
  GXPemptyInstruments,
  emptyLaboratoryInfo,
  emptyAnalyzerConfiguration
} from "./../../../constants";

export const getValidationSchema = (activeStep, isEditMode) => {
  return !isEditMode
    ? addValidationSchema(activeStep)
    : editValidationSchema(activeStep);
};

export const getInitialValue = (activeStep, isEditMode) => {
  return !isEditMode
    ? addInitialValue(activeStep)
    : editInitialValue(activeStep);
};

const addValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return identificationValidationSchema;
    case 1:
      return descriptionValidationSchema;
    case 2:
      return laboratoryValidationSchema;
    case 3:
      return gxpValidationSchema;
    case 4:
      return analyzerConfigurationSchema;
    default:
      return "Invalid Schema";
  }
};

const editValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return identificationValidationSchema;
    case 1:
      return descriptionValidationSchema;
    case 2:
      return laboratoryValidationSchema;
    case 3:
      return gxpValidationSchema;
    case 4:
      return analyzerConfigurationSchema;
    default:
      return "Invalid Schema";
  }
};

const addInitialValue = (activeStep) => {
  switch (activeStep) {
    case 0:
      return emptyInstrumentIdentification;
    case 1:
      return emptyEquipmentDescription;
    case 2:
      return emptyLaboratoryInfo;
    case 3:
      return GXPemptyInstruments;
    case 4:
      return emptyAnalyzerConfiguration;
    default:
      return "Invalid Schema";
  }
};

const editInitialValue = (activeStep) => {
  switch (activeStep) {
    case 0:
      return emptyInstrumentIdentification;
    case 1:
      return emptyEquipmentDescription;
    case 2:
      return emptyLaboratoryInfo;
    case 3:
      return GXPemptyInstruments;
    case 4:
      return emptyAnalyzerConfiguration;
    default:
      return "Invalid Schema";
  }
};
