import { useContext, useEffect, useState } from "react";
import locale from "date-fns/locale/en-US";
import { useSelector } from "react-redux";
import {
  GXPemptyInstrumentsOptions,
  SOP_MANDATORY_CLP,
  systemStatusOptions
} from "./../../../constants";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { FormComponents, CoverSpace } from "./AddEquipmentStyle";
import { BadgeIconTop } from "./AddEditEquipmentContainer";
import styled from "styled-components";
import DocumentsDataForm from "../DocumentsDataForm";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { connect } from "react-redux";
import { StepperContext } from "../Context";
import {
  OwcButton,
  OwcDatepicker,
  OwcIcon,
  OwcIconButton,
  OwcInput,
  OwcTypography,
  OwcSelectDropdown,
  OwcListItem
  // OwcCheckbox
} from "@one/react";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { defaultInputStyle } from "../../../utils/helpers/text";

// const useHelperTextStyles = makeStyles(() => ({
//   root: {
//     background: "var(--one-color-cobas-white)",
//     paddingLeft: "13px"
//   }
// }));
const testStyle = {
  color: "var(--one-color-background-brand-1)",
  position: "absolute",
  right: "30px"
};

const InstallDocumentContainer = styled.div`
  position: relative;
  display: inline-block;
  & > .selectBoxLarge {
    margin: 0 20px 20px 0px;
    width: 321px;
    max-width: 321px;
  }
`;
const DocumentContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 4px;
`;
// const CustomTextField = withStyles((theme) => ({
//   root: {
//     "& .MuiFilledInput-input": {
//       maxWidth: "100%",
//       overflow: "hidden",
//       textOverflow: "ellipsis",
//       WebkiLineClamp: 1,
//       display: "-webkit-box",
//       WebkitBoxOrient: "vertical",
//       wordBreak: "break-all",
//       marginRight: "20px"
//     }
//   }
// }))(TextField);

const AddCancelButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

// const SOPHelperText = styled.div`
//   display: flex;
//   font-size: 11px;
// `;

const GpxMaintainanceInformation = ({
  isUpdateBadge,
  sopHelperText,
  instrumentDetail,
  isEditMode = false
}) => {
  const formik = useFormikContext();
  const classes = {};
  const classMaintainanceDocument = {};
  const [maintainanceCheck, setMaintainanceCheck] = useState("");
  const listOptions = useSelector((state) => state.instruments);
  const [showLiknedDocumentsPopUp, setShowLinkedDocumentsPopUp] =
    useState(false);
  const sopListRemovedIsactive = listOptions.sopList.filter(
    (sop) => delete sop?.isActive
  );
  const [isQualification, setIsQualification] = useState(false);
  const [isControlledEquipment, setIsControlledEquipment] = useState(false);
  const { instrumentPrevData, setInstrumentPrevData } =
    useContext(StepperContext);
  useEffect(() => {
    formik.setFieldTouched("maintenancePlan");
    if (
      formik.values[DATA_MODEL_TABLE.controlledEquipmentStatus.key]?.[
        "value"
      ] &&
      formik.values[DATA_MODEL_TABLE.controlledEquipmentStatus.key]?.[
        "value"
      ] !== "NA"
    ) {
      setIsQualification(true);
      formik.setValues({
        ...formik.values,
        qualificationStatus: {
          key: "NA",
          value: "NA"
        }
      });
    }
    if (
      formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.["value"] &&
      formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.["value"] !==
        "NA"
    ) {
      setIsControlledEquipment(true);
      formik.setValues({
        ...formik.values,
        controlledEquipmentStatus: {
          key: "NA",
          value: "NA"
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formik.values.dateOfNextMaintanance) {
      setMaintainanceCheck(DATA_MODEL_TABLE.dateOfNextMaintanance.key);
    } else if (formik.values?.documentedMaintenanceDate) {
      setMaintainanceCheck(DATA_MODEL_TABLE.documentedMaintenanceDate.key);
    } else {
      setMaintainanceCheck("");
    }
  }, [
    formik.values.dateOfNextMaintanance,
    formik.values.documentedMaintenanceDate
  ]);
  const handleShowPopup = (value) => {
    if (value === "linkedDoc") {
      setShowLinkedDocumentsPopUp(!showLiknedDocumentsPopUp);
    }
  };

  const checkMaintainanceDate = (value, e = null) => {
    if (
      value === DATA_MODEL_TABLE.documentedMaintenanceDate.key &&
      (e === null || e?.target?.value === "") &&
      formik.values.dateOfNextMaintanance === null
    ) {
      setMaintainanceCheck("");
    } else if (
      value === DATA_MODEL_TABLE.dateOfNextMaintanance.key &&
      e === null &&
      (formik.values.documentedMaintenanceDate === "" ||
        formik.values.documentedMaintenanceDate === null)
    ) {
      setMaintainanceCheck("");
    } else {
      setMaintainanceCheck(value);
    }
  };

  const getInstalledDocValue = (selected) => {
    if (typeof selected != "undefined") {
      if (selected.length === 0) {
        return "";
      }
      if (selected.length > 0) {
        return `${selected[0]?.name}, ${selected[0]?.documentId}`;
      }
    } else {
      return "";
    }
  };

  const handleClosePopup = () => {
    if (showLiknedDocumentsPopUp) {
      setShowLinkedDocumentsPopUp(false);
    }
  };

  const qualificationStatusChange = async (qualificationStatus) => {
    if (qualificationStatus && qualificationStatus?.key !== "NA") {
      setIsControlledEquipment(true);
      formik.setValues({
        ...formik.values,
        qualificationStatus: qualificationStatus,
        controlledEquipmentStatus: {
          key: "NA",
          value: "NA"
        }
      });
    } else {
      formik.setValues({
        ...formik.values,
        qualificationStatus: qualificationStatus
      });
      setIsControlledEquipment(false);
    }
  };

  const controlledEquipmentStatusChange = async (controlledEquipmentStatus) => {
    if (controlledEquipmentStatus && controlledEquipmentStatus?.key !== "NA") {
      setIsQualification(true);
      formik.setValues({
        ...formik.values,
        qualificationStatus: {
          key: "NA",
          value: "NA"
        },
        controlledEquipmentStatus: controlledEquipmentStatus
      });
    } else {
      formik.setValues({
        ...formik.values,
        controlledEquipmentStatus: controlledEquipmentStatus
      });
      setIsQualification(false);
    }
  };

  const csvRelevantChange = async (csvRelevantStatus) => {
    if (csvRelevantStatus && csvRelevantStatus?.key === "No") {
      formik.setValues({
        ...formik.values,
        csv: csvRelevantStatus,
        electronicRecord: {
          key: "No",
          value: "No"
        },
        electronicSignatures: {
          key: "No",
          value: "No"
        },
        dateOfNextPeriodicReview: null
      });
    }
    if (csvRelevantStatus && csvRelevantStatus?.key === "Yes") {
      formik.setValues({
        ...formik.values,
        csv: csvRelevantStatus,
        electronicRecord: instrumentPrevData?.electronicRecord,
        electronicSignatures: instrumentPrevData?.electronicSignatures,
        dateOfNextPeriodicReview: instrumentPrevData?.dateOfNextPeriodicReview
          ? instrumentPrevData?.dateOfNextPeriodicReview
          : null
      });
    }
    if (csvRelevantStatus && csvRelevantStatus?.key === "NA") {
      formik.setValues({
        ...formik.values,
        csv: csvRelevantStatus,
        electronicRecord: formik?.values?.electronicRecord,
        electronicSignatures: formik?.values?.electronicSignatures,
        dateOfNextPeriodicReview: formik?.values?.dateOfNextPeriodicReview
          ? formik?.values?.dateOfNextPeriodicReview
          : null
      });
    }
    if (!csvRelevantStatus) {
      formik.setValues({
        ...formik.values,
        csv: csvRelevantStatus
      });
    }
  };
  useEffect(() => {
    if (formik.values.csv?.key !== "No") {
      setInstrumentPrevData(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CoverSpace>
        <OwcTypography variant="caption">SAP Mapped Fields</OwcTypography>
        <BadgeIconTop></BadgeIconTop>
      </CoverSpace>
      {!isEditMode && (
        <OwcTypography
          variant="subtitle2"
          style={{ padding: "0px 20px 20px 0px" }}
        >
          Qualification status and maintenance
        </OwcTypography>
      )}
      <FormComponents>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "8px 8px 0px 0px"
              }}
            >
              <OwcSelectDropdown
                multiple
                label={DATA_MODEL_TABLE.sop.value}
                notFoundText={"No options"}
                style={defaultInputStyle}
                onSelectChange={(e) => {
                  let selected = e.detail;
                  selected = selected.map((v) => sopListRemovedIsactive[v]);
                  formik.setValues({
                    ...formik.values,
                    sop: selected
                  });
                }}
              >
                {sopListRemovedIsactive?.map((sopItem, index) => {
                  return (
                    <>
                      <OwcListItem
                        selected={
                          formik?.values?.sop &&
                          formik?.values?.sop.find(
                            (e) => e?.value === sopItem?.value
                          )
                            ? true
                            : false
                        }
                      >
                        {sopItem.value}
                        {/* <OwcCheckbox>{sopItem.value}</OwcCheckbox> */}
                      </OwcListItem>
                    </>
                  );
                })}
              </OwcSelectDropdown>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "var(--one-text-font-size-rem-0-75)",
                pointerEvents: "none",
                padding: "0px 16px 0px 16px",
                color: "var(--one-color-interaction-default-neutral-3)"
              }}
            >
              {SOP_MANDATORY_CLP}
            </div>
          </div>
        </>
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.csv.value + `?`}
          defaultLabel={DATA_MODEL_TABLE.csv.value + `?`}
          keylabel={DATA_MODEL_TABLE.csv.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.csv}
          selected={
            formik.values[DATA_MODEL_TABLE.csv.key]?.["value"] !== ""
              ? formik.values[DATA_MODEL_TABLE.csv.key]?.["value"]
              : null
          }
          onChange={(selectedValue) => {
            const selVal = GXPemptyInstrumentsOptions?.csv?.find(
              (value, index) => index === selectedValue
            );
            csvRelevantChange(selVal ?? null);
          }}
          sopHelperText={true}
        />
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.electronicRecord.value + ` (ER)?`}
          defaultLabel={DATA_MODEL_TABLE.electronicRecord.value + ` (ER)?`}
          keylabel={DATA_MODEL_TABLE.electronicRecord.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.electronicRecord}
          selected={
            formik.values[DATA_MODEL_TABLE.electronicRecord.key]?.["value"] !==
            ""
              ? formik.values[DATA_MODEL_TABLE.electronicRecord.key]?.["value"]
              : null
          }
          onChange={(selectedValue) => {
            const selVal = GXPemptyInstrumentsOptions?.electronicRecord?.find(
              (value, index) => index === selectedValue
            );
            formik.setValues({
              ...formik.values,
              electronicRecord: selVal ?? null
            });
          }}
          sopHelperText={true}
          disabled={formik.values[DATA_MODEL_TABLE.csv.key]?.value === "No"}
        />
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.electronicSignatures.value + `?`}
          defaultLabel={DATA_MODEL_TABLE.electronicSignatures.value + `?`}
          keylabel={DATA_MODEL_TABLE.electronicSignatures.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.electronicSignatures}
          selected={
            formik.values[DATA_MODEL_TABLE.electronicSignatures.key]?.[
              "value"
            ] !== ""
              ? formik.values[DATA_MODEL_TABLE.electronicSignatures.key]?.[
                  "value"
                ]
              : null
          }
          onChange={(selectedValue) => {
            const selVal =
              GXPemptyInstrumentsOptions?.electronicSignatures?.find(
                (value, index) => index === selectedValue
              );
            formik.setValues({
              ...formik.values,
              electronicSignatures: selVal ?? null
            });
          }}
          sopHelperText={true}
          disabled={formik.values[DATA_MODEL_TABLE.csv.key]?.value === "No"}
        />
        <div className="owcInputBox">
          <OwcDatepicker
            locale={{
              ...locale,

              options: {
                ...locale.options,

                weekStartsOn: 1
              }
            }}
            variant="filled"
            data-testid={
              "basic-data-additional-info-fields-date-Of-Next-Periodic-Review"
            }
            id={DATA_MODEL_TABLE.dateOfNextPeriodicReview.key}
            name={DATA_MODEL_TABLE.dateOfNextPeriodicReview.key}
            style={defaultInputStyle}
            label="Periodic review for CSV systems"
            format="dd-MMM-yyyy"
            value={
              typeof formik.values[
                DATA_MODEL_TABLE.dateOfNextPeriodicReview.key
              ] === "string"
                ? new Date(
                    formik.values[DATA_MODEL_TABLE.dateOfNextPeriodicReview.key]
                  )
                : formik.values[DATA_MODEL_TABLE.dateOfNextPeriodicReview.key]
            }
            autoClose={true}
            onValueChange={(e) => {
              const newDate = e.detail;
              if (newDate) {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
                  newDate,
                  true
                );
              } else {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
                  newDate,
                  true
                );
              }
            }}
            disabled={formik.values[DATA_MODEL_TABLE.csv.key]?.value === "No"}
            error-text={sopHelperText}
          ></OwcDatepicker>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-maintenancePlan`}
            variant="filled"
            style={defaultInputStyle}
            label={DATA_MODEL_TABLE.maintenancePlan.value}
            name={DATA_MODEL_TABLE.maintenancePlan.key}
            onInputChange={formik.handleChange}
            value={formik.values?.maintenancePlan}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              DATA_MODEL_TABLE.maintenancePlan.key,
              DATA_MODEL_TABLE.maintenancePlan.key,
              "text-field"
            )}
            onFocus={handleClosePopup}
          >
            {formik.values[DATA_MODEL_TABLE.maintenancePlan.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.maintenancePlan.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.maintenancePlan.key],
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.gxpRelevant.value + `?`}
          defaultLabel={DATA_MODEL_TABLE.gxpRelevant.value + `?`}
          keylabel={DATA_MODEL_TABLE.gxpRelevant.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.gxpRelevant}
          selected={
            formik.values[DATA_MODEL_TABLE.gxpRelevant.key]?.["value"] !== ""
              ? formik.values[DATA_MODEL_TABLE.gxpRelevant.key]?.["value"]
              : null
          }
          onChange={(selectedValue) => {
            const selVal = GXPemptyInstrumentsOptions?.gxpRelevant?.find(
              (value, index) => index === selectedValue
            );
            formik.setValues({
              ...formik.values,
              gxpRelevant: selVal ?? null
            });
          }}
          sopHelperText={true}
        />

        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.qualificationStatus.value + ` *`}
          defaultLabel={DATA_MODEL_TABLE.qualificationStatus.value + ` *`}
          keylabel={DATA_MODEL_TABLE.qualificationStatus.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.qualificationStatus}
          selected={
            formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.[
              "value"
            ] !== ""
              ? formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.[
                  "value"
                ]
              : null
          }
          onChange={(selectedValue) => {
            const selVal =
              GXPemptyInstrumentsOptions?.qualificationStatus?.find(
                (value, index) => index === selectedValue
              );
            qualificationStatusChange(selVal ?? null);
          }}
          required={true}
          helperText={formik.errors[DATA_MODEL_TABLE.qualificationStatus.key]}
          disabled={isQualification}
        />
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.controlledEquipmentStatus.value + ` *`}
          defaultLabel={DATA_MODEL_TABLE.controlledEquipmentStatus.value + ` *`}
          keylabel={DATA_MODEL_TABLE.controlledEquipmentStatus.key}
          style={defaultInputStyle}
          propValue="value"
          list={GXPemptyInstrumentsOptions?.controlledEquipmentStatus}
          selected={
            formik.values[DATA_MODEL_TABLE.controlledEquipmentStatus.key]?.[
              "value"
            ] !== ""
              ? formik.values[DATA_MODEL_TABLE.controlledEquipmentStatus.key]?.[
                  "value"
                ]
              : null
          }
          onChange={(selectedValue) => {
            const selVal =
              GXPemptyInstrumentsOptions?.controlledEquipmentStatus?.find(
                (value, index) => index === selectedValue
              );
            controlledEquipmentStatusChange(selVal ?? null);
          }}
          required={true}
          helperText={
            formik.errors[DATA_MODEL_TABLE.controlledEquipmentStatus.key]
          }
          disabled={isControlledEquipment}
        />
        <div className="owcInputBox">
          <OwcDatepicker
            locale={{
              ...locale,

              options: {
                ...locale.options,

                weekStartsOn: 1
              }
            }}
            variant="filled"
            data-testid={
              "basic-data-additional-info-fields-instrument-date-Of-Last-Maintanance"
            }
            style={defaultInputStyle}
            id={DATA_MODEL_TABLE.dateOfLastMaintanance.key}
            name={DATA_MODEL_TABLE.dateOfLastMaintanance.key}
            label={DATA_MODEL_TABLE.dateOfLastMaintanance.value}
            maxDate={new Date().getTime()}
            minDate={-8640000000000000}
            format="dd-MMM-yyyy"
            value={
              typeof formik.values[
                DATA_MODEL_TABLE.dateOfLastMaintanance.key
              ] === "string"
                ? new Date(
                    formik.values[DATA_MODEL_TABLE.dateOfLastMaintanance.key]
                  )
                : formik.values[DATA_MODEL_TABLE.dateOfLastMaintanance.key]
            }
            autoClose={true}
            onValueChange={(e) => {
              const newDate = e.detail;

              if (newDate) {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfLastMaintanance.key,
                  newDate,
                  true
                );
              } else {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfLastMaintanance.key,
                  newDate,
                  true
                );
              }
            }}
            error-text={formik.errors.dateOfLastMaintanance}
          ></OwcDatepicker>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-maintenance-interaval-in-days`}
            variant="filled"
            style={defaultInputStyle}
            label={DATA_MODEL_TABLE.maintenanceIntervalInDays.value}
            name={DATA_MODEL_TABLE.maintenanceIntervalInDays.key}
            onInputChange={formik.handleChange}
            value={formik.values?.maintenanceIntervalInDays}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classes,
              formik,
              DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
              DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
              "text-field",
              false
            )}
            onFocus={handleClosePopup}
          >
            {formik.errors[DATA_MODEL_TABLE.maintenanceIntervalInDays.key] &&
              formik.touched[DATA_MODEL_TABLE.maintenanceIntervalInDays.key] ===
                true && (
                <>
                  <span slot="error-text">
                    {
                      formik.errors[
                        DATA_MODEL_TABLE.maintenanceIntervalInDays.key
                      ]
                    }
                  </span>
                </>
              )}
            {formik.values[DATA_MODEL_TABLE.maintenanceIntervalInDays.key] !==
              "" &&
              formik.touched[DATA_MODEL_TABLE.maintenanceIntervalInDays.key] ===
                true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
                      "",
                      true
                    )
                  }
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcDatepicker
            locale={{
              ...locale,

              options: {
                ...locale.options,

                weekStartsOn: 1
              }
            }}
            variant="filled"
            data-testid={
              "basic-data-additional-info-fields-instrument-date-of-next-maintanance-input"
            }
            style={defaultInputStyle}
            id={DATA_MODEL_TABLE.dateOfNextMaintanance.key}
            name={DATA_MODEL_TABLE.dateOfNextMaintanance.key}
            label={DATA_MODEL_TABLE.dateOfNextMaintanance.value}
            minDate={new Date().getTime()}
            format="dd-MMM-yyyy"
            value={
              typeof formik.values[
                DATA_MODEL_TABLE.dateOfNextMaintanance.key
              ] === "string"
                ? new Date(
                    formik.values[DATA_MODEL_TABLE.dateOfNextMaintanance.key]
                  )
                : formik.values[DATA_MODEL_TABLE.dateOfNextMaintanance.key]
            }
            autoClose={true}
            onValueChange={(e) => {
              const newDate = e.detail;
              if (newDate) {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfNextMaintanance.key,
                  newDate,
                  true
                );
              } else {
                formik.setFieldValue(
                  DATA_MODEL_TABLE.dateOfNextMaintanance.key,
                  newDate,
                  true
                );
              }
            }}
            error-text={formik.errors.dateOfNextMaintanance}
            disabled={
              maintainanceCheck ===
              DATA_MODEL_TABLE.documentedMaintenanceDate.key
            }
          ></OwcDatepicker>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-documentedMaintenanceDate`}
            color="primary"
            variant="filled"
            style={defaultInputStyle}
            label={DATA_MODEL_TABLE.documentedMaintenanceDate.value}
            name={DATA_MODEL_TABLE.documentedMaintenanceDate.key}
            onInputChange={(e) => {
              formik.handleChange(e);
              checkMaintainanceDate(
                DATA_MODEL_TABLE.documentedMaintenanceDate.key,
                e
              );
            }}
            disabled={
              maintainanceCheck === DATA_MODEL_TABLE.dateOfNextMaintanance.key
            }
            value={formik.values?.documentedMaintenanceDate}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue(
              classMaintainanceDocument,
              formik,
              DATA_MODEL_TABLE.documentedMaintenanceDate.key,
              DATA_MODEL_TABLE.documentedMaintenanceDate.key,
              "text-field",
              false,
              false,
              false,
              sopHelperText
            )}
            onFocus={handleClosePopup}
          >
            {formik.values[DATA_MODEL_TABLE.documentedMaintenanceDate.key] !==
              "" &&
              formik.touched[DATA_MODEL_TABLE.documentedMaintenanceDate.key] ===
                true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() =>
                    formik.setFieldValue(
                      [DATA_MODEL_TABLE.documentedMaintenanceDate.key],
                      "",
                      true
                    )
                  }
                />
              )}
            <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <InstallDocumentContainer>
            <OwcInput
              style={defaultInputStyle}
              data-testid={`text-field-linked-documents`}
              label={DATA_MODEL_TABLE.qualificationDocuments.value}
              name={DATA_MODEL_TABLE.qualificationDocuments.key}
              size="medium"
              autoComplete="off"
              onClick={() => {
                handleShowPopup("linkedDoc");
              }}
              value={getInstalledDocValue(
                formik.values?.qualificationDocuments?.value
              )}
            >
              <div slot="suffix">
                {formik.values?.qualificationDocuments?.value?.length > 1 ? (
                  <div style={testStyle}>
                    +{formik.values?.qualificationDocuments?.value?.length - 1}
                  </div>
                ) : (
                  ""
                )}
                <OwcIconButton
                  flat
                  icon="circle_add"
                  type="outlined"
                  data-testid={`show-popup-linkedDoc`}
                  onClick={() => {
                    handleShowPopup("linkedDoc");
                  }}
                />
              </div>
            </OwcInput>
            <DocumentContainer
              style={{ display: showLiknedDocumentsPopUp ? "block" : "none" }}
            >
              {showLiknedDocumentsPopUp ? (
                <DocumentsDataForm formik={formik} />
              ) : (
                ""
              )}
              <AddCancelButton>
                <OwcButton
                  variant="primary"
                  style={{ textTransform: "none" }}
                  data-testid={`close-popup-close-linkedDoc`}
                  onClick={handleClosePopup}
                >
                  Close
                </OwcButton>
              </AddCancelButton>
            </DocumentContainer>
          </InstallDocumentContainer>
        </div>
        <CommonOwcEditableDropDown
          labelValue={false}
          label={DATA_MODEL_TABLE.systemStatus.value}
          keylabel={DATA_MODEL_TABLE.systemStatus.key}
          defaultLabel={DATA_MODEL_TABLE.systemStatus.value}
          style={defaultInputStyle}
          list={systemStatusOptions}
          selected={
            formik.values[DATA_MODEL_TABLE.systemStatus.key] !== ""
              ? formik.values[DATA_MODEL_TABLE.systemStatus.key]
              : null
          }
          onChange={(selectedValue) => {
            const selVal = systemStatusOptions?.find(
              (value, index) => index === selectedValue
            );
            formik.setValues({
              ...formik.values,
              systemStatus: selVal ?? null
            });
          }}
        />
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default connect(mapStateToProps)(GpxMaintainanceInformation);
