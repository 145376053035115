import styled from "styled-components";
import React from "react";
import { DAYS, MONTHS, SOP_MANDATORY_CLP } from "../../constants";

export const Input = styled.div`
  margin-bottom: 20px;
  margin-right: -5px;
`;
export const compareArrayFunc = (a, b) =>
  //compare two arrays
  a.length === b.length && a.every((element, index) => element === b[index]);

export const SOPHelperText = styled.div`
  display: flex;
  font-size: 11px;
`;

export const hasFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    Boolean(formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};
export const getFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    (formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};

const commonPropsForInputs =
  ({
    errorFn = (formik, key) => hasFormikObjectError(formik, key),
    helperTextFn = (formik, key) => getFormikObjectError(formik, key)
  } = {}) =>
  (
    classes,
    formik,
    key,
    dataTestIdKey,
    dataTestIdForFormKey,
    sopHelperText
  ) => ({
    error: errorFn(formik, key),
    helperText:
      sopHelperText && !formik.errors[key] ? (
        <SOPHelperText>{SOP_MANDATORY_CLP}</SOPHelperText>
      ) : (
        helperTextFn(formik, key)
      ),
    FormHelperTextProps: {
      "data-testid": `${dataTestIdForFormKey}-helper-text-${dataTestIdKey}-input`
    }
  });

export const commonPropsForInputsWithValue = commonPropsForInputs();
export const commonPropsForInputsWithoutValue = commonPropsForInputs({
  fieldKeyFn: (key) => key,
  valueFn: (formik, key) => formik.values?.[key],
  errorFn: (formik, key) => formik.touched[key] && Boolean(formik.errors[key]),
  helperTextFn: (formik, key) => formik.touched[key] && formik.errors[key]
});

export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (!inputDate) {
      return null;
    }

    if (typeof inputDate === "string") {
      if (format === null) {
        const utcDateStr = new Date(inputDate).toUTCString();
        const splitDateFormat = utcDateStr?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        const FormattedDateObj = new Date(currentDate);
        return FormattedDateObj;
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        return currentDate;
      }
      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return (
          DAYS[weekDay] +
          ", " +
          day +
          "-" +
          MONTHS[monthVal] +
          "-" +
          year +
          " ."
        );
      }
      if (format === "DD-MMM-YYYY" || format === "dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        const isoString =
          year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
        return isoString;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

// TODO: need to remove the code once tested in test env
// export const changeStringToFormattedDate = (inputDate, format) => {
//   const dateObj = new Date(inputDate).toUTCString();
//   const splitDateFormat = dateObj?.split(" ");
//   if (format === "dd-MMM-yyyy") {
//     return (
//       splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3]
//     );
//   }
//   if (format === "dd-MMM-yyyy, HH:mm") {
//     const hrMinute = splitDateFormat[4].split(":");
//     return (
//       splitDateFormat[1] +
//       "-" +
//       splitDateFormat[2] +
//       "-" +
//       splitDateFormat[3] +
//       ", " +
//       hrMinute[0] +
//       ":" +
//       hrMinute[1]
//     );
//   }
// };

const isoFormatToCustomFormat = (isoString) => {
  const dateObj = new Date(isoString).toUTCString();
  const splitDateFormat = dateObj?.split(" ");
  const currentDate =
    splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3];

  let timeVal = isoString.split("T");
  let time = timeVal[1].split(":");
  let hours = parseInt(time[0]);
  let mid = " am";
  if (hours === 12) {
    mid = " pm";
  }
  if (hours > 12) {
    hours = hours % 12;
    mid = " pm";
    if (hours === 0) {
      mid = " am";
    }
  }
  const timeString = hours + ":" + time[1] + mid;
  const timeIn24HrFormatString = parseInt(time[0]) + ":" + time[1];
  return {
    dateString: currentDate,
    timeString: timeString,
    dateAndTimeString: `${currentDate}, ${timeString}`,
    dateAndTimeIn24HrFormatString: `${currentDate}, ${timeIn24HrFormatString}`
  };
};

const stringToDate = (inputString) => {
  return new Date(inputString);
};

const dateToisoString = (inputDate) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const convertedDate = inputDate.toLocaleString("en-GB", {
    timeZone: localTimeZone
  });

  let isoVal = inputDate.toTimeString();
  isoVal = isoVal.split(" ");
  const splitDate = convertedDate?.split(",");
  if (splitDate.count === 0) {
    throw new Error("No valid date found for conversion");
  }

  const splitDateFormat = splitDate[0]?.split("/");
  if (splitDate.count === 0) {
    throw new Error("No valid date found for conversion");
  }

  let day = splitDateFormat[0];
  let month = splitDateFormat[1];
  let year = splitDateFormat[2];

  const isoString = year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
  return isoString;
};

/**
 *
 * @param {String} inputString - UTC date
 * @param {24} [timeFormat] - if value 24, then time returned in 24h format
 * @returns {String}  date and time converted to Local, with display in en-GB format
 */

export const utcStringToLocaleString = (inputString, timeFormat) => {
  const dateObj = stringToDate(inputString);
  const isoString = dateToisoString(dateObj);
  const { dateAndTimeString, dateAndTimeIn24HrFormatString } =
    isoFormatToCustomFormat(isoString);
  return timeFormat === 24 ? dateAndTimeIn24HrFormatString : dateAndTimeString;
};
