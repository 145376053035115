import React from "react";
import Notify from "../notifications/Notify";
import { Link } from "@mui/material";
import styled from "styled-components";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { DATA_UPLOAD_URL } from "../../gql/landingapi/queries";
import {
  EQUIPMENT_ERROR_TARGET_MODULE,
  IR_EQUIPMENT_UPLOAD_TARGET_MODULE,
  MASTER_DATA_ERROR_TARGET_MODULE
} from "../../constants";

const ErrorFieldDiv = styled.div`
  & .errorLink {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: left;
  }
`;

const ErrorField = ({ item, client }) => {
  const errorTargetModule =
    item?.targetModule === IR_EQUIPMENT_UPLOAD_TARGET_MODULE
      ? EQUIPMENT_ERROR_TARGET_MODULE
      : MASTER_DATA_ERROR_TARGET_MODULE;

  /**
   * This method is used to get the presigned url for a file to be uploaded to S3
   * @param {String} fileName name of the file
   * @returns the presignedurl data
   */
  const fetchPresignedUrl = async (fileName) => {
    try {
      const presignedUrlData = await client.query({
        query: DATA_UPLOAD_URL,
        fetchPolicy: "no-cache",
        variables: {
          targetModule: errorTargetModule,
          fileName
        }
      });

      if (!presignedUrlData?.data && presignedUrlData?.data?.dataUploadUrl) {
        throw new Error(
          "File download is not available. Please try again later"
        );
      }

      return presignedUrlData?.data?.dataUploadUrl;
    } catch (error) {
      throw new Error("File download is not available. Please try again later");
    }
  };

  /**
   * This method is used to download the error file
   */
  const downloadErrorFile = async () => {
    try {
      const presignedUrlData = await fetchPresignedUrl(item?.errorFilePath);

      if (!presignedUrlData?.presignedUrl) {
        throw new Error(
          "File download is not available. Please try again later"
        );
      }

      const downloadPath = presignedUrlData?.presignedUrl;
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("type", "hidden");
      downloadLink.href = downloadPath;
      downloadLink.download = item?.errorFileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();

      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `CSV successfully downloaded.`
      });
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: error?.message || error
      });
    }
  };

  return (
    <>
      {item?.status !== "FAILED" ? (
        <div style={{ height: "50px", paddingTop: "20px" }}>-</div>
      ) : (
        <ErrorFieldDiv
          style={{
            width: "130px",
            paddingTop: "15px"
          }}
        >
          <Link
            data-testid="error-link-field"
            className="errorLink"
            component="button"
            onClick={downloadErrorFile}
          >
            {item?.errorFileName}
          </Link>
        </ErrorFieldDiv>
      )}
    </>
  );
};

export default compose(connect(null, {}), withApollo)(ErrorField);
