import { Badge, IconButton } from "@mui/material";
import { OwcIcon, OwcIconButton } from "@one/react";
import React from "react";
import styled from "styled-components";
import Search from "../../components/shared/Search";
import FilterOutlinedActive from "../../icons/FilterOutlinedActive";
import FilterOutlinedGrey from "../../icons/FilterOutlinedGrey";
import PreDefinedFilters from "../instruments/instrument-filter/PreDefinedFilters";

const LinkedInstanceFilterBar = ({
  setSearchQuery,
  onShowSideBarCh,
  onAdvanceFilterChange,
  onFloatingFilterChange,
  showSideBar,
  floatingFilter,
  showAdvancedFilter,
  counter = 0,
  getPredefinedValue,
  preDefinedFilter
}) => {
  const StyledBadge = styled(Badge)({
    "& .MuiBadge-badge": {
      color: "var(--one-color-cobas-white)",
      backgroundColor: "var(--one-color-background-brand-1)"
    }
  });
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Search label="Search" setQuery={setSearchQuery} />
        {showAdvancedFilter && (
          <>
            <PreDefinedFilters
              preDefinedFilter={preDefinedFilter}
              getPredefinedValue={getPredefinedValue}
            />
            <OwcIconButton
              onClick={() => {
                onShowSideBarCh();
              }}
              flat
              data-testid="filter-cogwheel-icon-button"
            >
              <OwcIcon
                style={{
                  color: showSideBar
                    ? "var(--one-color-background-brand-1)"
                    : ""
                }}
                name="settings"
                type="legacy"
              />
            </OwcIconButton>
          </>
        )}

        <IconButton
          onClick={() => {
            onFloatingFilterChange();
            onAdvanceFilterChange();
          }}
          data-testid="notification-counter-badge-button"
        >
          {!floatingFilter && (
            <>
              <StyledBadge
                badgeContent={counter}
                data-testid="notification-counter-badge"
              >
                <FilterOutlinedGrey />
              </StyledBadge>
            </>
          )}
          {floatingFilter && (
            <>
              <StyledBadge
                badgeContent={counter}
                data-testid="notification-counter-badge"
              >
                <FilterOutlinedActive />
              </StyledBadge>
            </>
          )}
        </IconButton>
      </div>
      <br />
    </>
  );
};

export default LinkedInstanceFilterBar;
