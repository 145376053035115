import React, { useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { StepperContext } from "../Context";
import {
  OwcHorizontalStep,
  OwcHorizontalStepper,
  OwcIcon,
  OwcTooltip
} from "@one/react";

const StepperElement = ({
  activeStep,
  setActiveStep,
  isEditMode,
  instrumentDetail,
  errors
}) => {
  const { stepsArray, setStepsArray } = useContext(StepperContext);

  const handleStep = (step, prevstep) => () => {
    if (isEditMode && Object.keys(errors).length <= 0) {
      let newArray = [...stepsArray];
      setActiveStep(step);
      newArray[prevstep].active = false;
      newArray[step].active = true;
      setStepsArray(newArray);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        background: "#FBFBFA"
      }}
    >
      <OwcHorizontalStepper
        // linear
        data-testid="booking-calendar-stepper-element-box"
        activeStep={activeStep}
        style={{
          // width: "50%",
          margin: "15px 0 14px 0",
          minWidth: "fit-content"
        }}
      >
        {stepsArray.map((label, index) => {
          const labelProps = {
            completed: label.completed,
            error: label.error,
            active: label.active,
            disabled: label.disabled
          };
          return (
            <OwcHorizontalStep
              data-testid={`booking-calendar-stepper-element-step`}
              key={label.name}
              {...labelProps}
              onClick={handleStep(index, activeStep)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div style={{ paddingRight: "10px", wordBreak: "break-word" }}>
                  {label.name}
                </div>
                <div>
                  <OwcIcon
                    id={`tooltip-trigger-${label.id}`}
                    name="circle_help"
                  />
                  <OwcTooltip
                    anchor={`tooltip-trigger-${label.id}`}
                    placement="right"
                  >
                    {label.tooltip}
                  </OwcTooltip>
                </div>
              </div>
            </OwcHorizontalStep>
          );
        })}
      </OwcHorizontalStepper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});
export default compose(connect(mapStateToProps), withApollo)(StepperElement);
