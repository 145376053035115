import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  OwcHorizontalStep,
  OwcHorizontalStepper,
  OwcIcon,
  OwcTooltip
} from "@one/react";

/**
 * Assign the project to an employee.
 * @param {Object} stepsArray - Array of Object.
 * @param {string} stepsArray.label - label like showing step index
 * @param {string} stepsArray.description - description.
 * @param {boolean} stepsArray.active - false.
 * @param {boolean} stepsArray.completed - false.
 * @param {boolean} stepsArray.valid - false.
 * @param {boolean} stepsArray.error - false.
 */
const FormSteps = ({ stepsArray = [] }) => {
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    const activeStepDetail = stepsArray.find((x) => x.active === true);
    setActiveStep(activeStepDetail?.id);
  }, [stepsArray]);

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        background: "#FBFBFA"
      }}
    >
      <OwcHorizontalStepper
        data-testid="booking-calendar-stepper-element-box"
        activeStep={activeStep}
        style={{
          width: "50%",
          margin: "15px 0 14px 0",
          minWidth: "fit-content"
        }}
      >
        {stepsArray.map((label, index) => {
          const labelProps = {
            completed: label.completed,
            error: label.error,
            active: label.active,
            disabled: label.disabled,
            cursor: label.cursor
          };

          return (
            <OwcHorizontalStep
              data-testid={`booking-calendar-stepper-element-step-label`}
              activeStep={activeStep}
              {...labelProps}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div style={{ paddingRight: "10px", wordBreak: "break-word" }}>
                  {label.description}
                </div>
                <div>
                  <OwcIcon
                    id={`tooltip-trigger-${label.id}`}
                    name="circle_help"
                  />
                  <OwcTooltip
                    anchor={`tooltip-trigger-${label.id}`}
                    placement="right"
                  >
                    {label.tooltip}
                  </OwcTooltip>
                </div>
              </div>
            </OwcHorizontalStep>
          );
        })}
      </OwcHorizontalStepper>
    </div>
  );
};

export default FormSteps;
