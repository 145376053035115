import React from "react";
import ReactDOM from "react-dom";
import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { appSyncClient } from "./appSyncClient";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import Main from "./Main";
import { store } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import BackendInfoWrapper from "./features/backend-info-context/BackendInfoWrapper";
import HeaderSection from "./features/unique-env-info/HeaderSection";
import FooterSection from "./features/unique-env-info/FooterSection";

import { OwcThemeProvider } from "@one/react";
import { getEnv } from "./utils/helpers/text";
import { AwsRum } from "aws-rum-web";
import { THEME_NAME } from "./constants";

Amplify.configure(awsconfig);
process.env.NODE_ENV === "development" && (Amplify.Logger.LOG_LEVEL = "DEBUG");

process.env.NODE_ENV === "development" && (window.store = store);

const currentEnv = getEnv();

/**
 * Code for AWS cloud watch RUM
 */
let awsRum = null; //very important otherwise variable is local to try/catch block and will be garbage collected, and possible even removed during compilation (production optimized build)
try {
  let app_telemetries =
    currentEnv === "dev" || currentEnv === "test"
      ? ["performance", "errors", "http"]
      : ["errors"];
  // help for config: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
  awsRum = new AwsRum(
    process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
    process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
    {
      sessionSampleRate: 1, //record 100% of sessions
      sessionEventLimit: 0, //no limit on number of events per session
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: app_telemetries,
      // in development, record all telemetries
      // telemetries: ["errors"], // for production/staging, only errors
      allowCookies: true,
      enableXRay: false
    }
  );
  console.log(awsRum);
  //https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-custom-events.html
  //Caution if you send empty data, you get a strange error:  1 validation error detected: Value null at 'rumEvents.3.member.details' failed to satisfy constraint: Member must not be null
  // Note: custom events must be enabled in the cloudwatch console, otherwise they are simply ignore.
  awsRum.recordEvent("my_custom_event", { value_1: "App Loaded" }); //record custom event (remove later)
} catch (error) {
  console.log(error);
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
// Code for AWS cloud watch RUM ENDS

ReactDOM.render(
  <React.StrictMode>
    <OwcThemeProvider theme={THEME_NAME}>
      <ApolloProvider client={appSyncClient}>
        <Provider store={store}>
          <Rehydrated>
            <Router>
              <BackendInfoWrapper>
                <HeaderSection />
                <Main></Main>
                <FooterSection />
              </BackendInfoWrapper>
            </Router>
          </Rehydrated>
        </Provider>
      </ApolloProvider>
    </OwcThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
