const IconForApp = (props) => (
  <svg
    width="40"
    height="30"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_866_19697)">
      <path
        d="M13.335 10.6401V14.5801M16.8275 5.77759H41.42C41.8724 5.77726 42.3205 5.86604 42.7386 6.03887C43.1566 6.21169 43.5366 6.46517 43.8567 6.78484C44.1769 7.1045 44.4309 7.48409 44.6043 7.90193C44.7778 8.31978 44.8672 8.76769 44.8675 9.22009V14.5801H13.38V9.22009C13.3804 8.76769 13.4698 8.31978 13.6432 7.90193C13.8166 7.48409 14.0707 7.1045 14.3908 6.78484C14.7109 6.46517 15.0909 6.21169 15.509 6.03887C15.9271 5.86604 16.3751 5.77726 16.8275 5.77759Z"
        stroke="#0B41CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.89 18.1251V14.8876C44.89 14.7176 44.79 14.5801 44.665 14.5801H3.33499C3.20999 14.5801 3.10999 14.7176 3.10999 14.8876V33.9151C3.10999 34.0851 3.20999 34.2226 3.33499 34.2226H16.75"
        stroke="#0B41CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_866_19697)">
        <path
          d="M22 24L24 24M22 28H24"
          stroke="#1482FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 32H24M22 36H24"
          stroke="#1482FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 24H40"
          stroke="#1482FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 32H40M30 36H40"
          stroke="#1482FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 28L40 28"
          stroke="#1482FA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_866_19697">
        <rect width="48" height="48" fill="white" />
      </clipPath>
      <clipPath id="clip1_866_19697">
        <rect
          width="27"
          height="27"
          fill="white"
          transform="translate(20 18.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default IconForApp;
