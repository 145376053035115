import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { find } from "lodash";
import { OwcTextarea } from "@one/react";
import { OTHER_REASON } from "../../constants";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 0 25px 0;
  }
`;

export const ReviewReasonDialogContent = ({
  editReason,
  setEditReason,
  editComment,
  setEditComment
}) => {
  const listOptions = useSelector((state) => state.instruments);
  const editCommentChange = (e) => {
    setEditComment(e.target.value);
  };
  return (
    <IdentificationForm>
      <GridAutocomplete
        dataTestid="action-data"
        className="selectBoxLarge"
        size="meduim"
        optionsList={listOptions?.reasonList}
        propValue="configText"
        propLabel="configText"
        label="Select reason *"
        value={find(listOptions?.reasonList, {
          configText: editReason
        })}
        key="editReason"
        disableClearable
        onAutoCompleteChange={(selectedValue) => {
          if (selectedValue !== null) {
            setEditReason(selectedValue?.configText?.trim());
          }
        }}
      />

      <OwcTextarea
        data-testid={`textarea-field-edit-comment`}
        label={editReason === OTHER_REASON ? "Add comment *" : "Add comment"}
        name="editComment"
        validation-mode="realtime"
        value={editComment}
        error={!editComment}
        required={editReason === OTHER_REASON}
        onInputReset={() => setEditComment("")}
        onInputChange={editCommentChange}
        onInputKeyDown={editCommentChange}
        onBlur={editCommentChange}
        reset
        cols="18"
        maxLength={80}
        minLength={0}
        minRows={2}
      />
    </IdentificationForm>
  );
};

export default ReviewReasonDialogContent;
