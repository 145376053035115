import { attributesValidationSchema } from "./attributesValidationSchema";
import { dimensionsValidationSchema } from "./dimensionsValidationSchema";
import { editMasterDataValidationSchema } from "./editMasterDataValidationSchema";
import {
  emptyAttributes,
  emptyDimensions,
  emptyComplexAttributes,
  emptyEditMasterData
} from "./../../../constants";
import { complexAttriutesValidationSchema } from "./complexAttriutesValidationSchema";

export const getValidationSchema = (activeStep, isEditMode) => {
  return !isEditMode
    ? addValidationSchema(activeStep.id)
    : editValidationSchema();
};

export const getInitialValue = (activeStep, isEditMode) => {
  return !isEditMode ? addInitialValue(activeStep.id) : editInitialValue();
};

const addValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return attributesValidationSchema;
    case 1:
      return dimensionsValidationSchema;
    case 2:
      return complexAttriutesValidationSchema;
    default:
      return "Invalid Schema";
  }
};

const editValidationSchema = () => {
  return editMasterDataValidationSchema;
};

const addInitialValue = (activeStep) => {
  switch (activeStep) {
    case 0:
      return emptyAttributes;
    case 1:
      return emptyDimensions;
    case 2:
      return emptyComplexAttributes;
    default:
      return "Invalid Schema";
  }
};

const editInitialValue = () => {
  return emptyEditMasterData;
};
