import React, { useEffect, forwardRef, useState, useRef } from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import { CircularProgress, Collapse } from "@mui/material";
import { OwcTypography } from "@one/react";

const TableStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.table.borderColor};
  margin: 1rem 0;
  & .body {
    background-color: ${(props) => props.theme.table.cell.backgroundColor};
  }
  & .head {
    background-color: ${(props) => props.theme.table.header.backgroundColor};
    padding: 15px 18px !important;
  }
  & .head,
  & .row {
    padding: 0px 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  & .row.expanded {
    &,
    &:hover {
      background: var(--one-color-blue-100);
    }
    &:not(.expanded-content) {
      border-bottom-color: transparent;
    }
    &.expanded-content {
      position: relative;
      &::before {
        content: "";
        border-bottom: 1px solid #e0ecf9;
        position: absolute;
        top: -10px;
        left: 18px;
        right: 18px;
      }
    }
  }
  & .row.selected {
    background: #e9f4ff;
  }
  & .row:hover {
    background: var(--one-color-gray-50);

    &.selected {
      background: #d2e9ff;
    }
  }
  & .body .cell,
  & .head .cell {
    margin: 0;
    line-height: 18px;
    font-size: 14px;
  }
  & .head .cell {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  & .body .cell {
    font-weight: 500;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
  & .body .loading {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  & .cell {
    flex: 1 1 auto;

    &.sortable {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  & .empty {
    text-align: center;
    padding: 10px 16px;
  }
  & .empty,
  & .head,
  & .row {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${(props) => props.theme.table.borderColor};
  }
  & .fetchMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }
`;
const BodyTable = styled.div`
  overflow: auto;
  max-height: 64vh;
`;
const isObject = (item) => typeof item === "object" && item !== null;

const CustomList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  loading,
  preExpanded,
  fetching,
  className,
  Component = null,
  isSelected = () => false
}) => {
  const bodyRef = useRef();
  if (!isObject(meta)) {
    return null;
  }
  if (!isObject(meta?.fields)) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }

  let body = null;

  if (loading) {
    body = (
      <div className="loading">
        <CircularProgress size={30} />
      </div>
    );
  } else if (data.length === 0) {
    body = (
      <OwcTypography
        variant="title6"
        data-testid="custom-list-no-data"
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        No data
      </OwcTypography>
    );
  } else {
    body = data.map((item, index) => {
      const id =
        item?.[meta?.rowId] ||
        item?.id ||
        (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          expandable={meta?.expandable}
          ExpandedComponent={meta?.ExpandedComponent}
          item={item}
          key={id}
          id={id}
          fields={meta?.fields}
          preExpanded={preExpanded}
          bodyRef={bodyRef}
          selected={isSelected(item) || item.selected}
          lastElement={false}
          onRowClick={onRowClick}
          meta={meta}
        />
      );
    });
  }

  return (
    <TableStyled className={className} data-testid="custom-list">
      <div className="head" data-testid="custom-list-head">
        <Header
          meta={meta}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
          orderBy={orderBy}
        />
      </div>
      <BodyTable className="body" ref={bodyRef}>
        {body}
        {fetching && (
          <div className="fetchMore" data-testid="custom-list-fetchmore">
            <CircularProgress size={30} />
          </div>
        )}
      </BodyTable>
      {Component}
    </TableStyled>
  );
};
const ChooseDropDown = ({ isReverseOrder }) => {
  return isReverseOrder ? (
    <ArrowDropUpIcon
      data-testid="arrow-drop-down-up-icon"
      color="primary"
    ></ArrowDropUpIcon>
  ) : (
    <ArrowDropDownIcon
      data-testid="arrow-drop-down-down-icon"
      color="primary"
    ></ArrowDropDownIcon>
  );
};
const SortingHeader = ({
  xkey,
  meta,
  orderBy,
  onRequestSort,
  isReverseOrder
}) => {
  return (
    <div
      className={clsx({
        cell: true,
        sortable: meta.fields[xkey]?.sortable
      })}
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
      onClick={() => {
        if (xkey === orderBy) {
          onRequestSort(xkey, !isReverseOrder);
        } else {
          onRequestSort(xkey, false);
        }
      }}
    >
      {meta?.fields?.[xkey]?.text}
      {xkey !== orderBy ? (
        <ArrowDropDownIcon></ArrowDropDownIcon>
      ) : (
        <ChooseDropDown isReverseOrder={isReverseOrder} />
      )}
    </div>
  );
};

const HeaderName = ({ xkey, meta }) => {
  return (
    <div
      className="cell"
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
    >
      {meta?.fields?.[xkey]?.text}
    </div>
  );
};
export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent
      ? meta?.fields?.[key]?.headerComponent
      : null;
    if (!Component) {
      return meta.fields[key]?.sortable ? (
        <SortingHeader
          xkey={key}
          meta={meta}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
        />
      ) : (
        <HeaderName xkey={key} meta={meta} />
      );
    }
    return (
      <div
        className={clsx({
          cell: true,
          sortable: meta.fields[key]?.sortable
        })}
        data-testid={`list-head-${key}`}
        key={key}
        {...(meta?.fields?.[key]?.headProps ?? {})}
      >
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </div>
    );
  });
};
const mapItemsToRows = ({
  fields,
  id,
  item,
  toggleExpanded,
  shouldExpand,
  selected
}) => {
  return Object.keys(fields).map((field) => {
    const Component = fields?.[field]?.component
      ? fields?.[field].component
      : null;
    if (!Component) {
      return (
        <div
          key={`item-row-${id}-${field}`}
          data-testid={`item-row-${id}-${field}`}
          className="cell"
          {...(fields?.[field]?.cellProps ?? {})}
        >
          {item[field] ?? "-"}
        </div>
      );
    }
    return (
      <div
        className="cell"
        key={`item-row-${id}-${field}`}
        data-testid={`item-row-${id}-${field}`}
        {...(fields?.[field]?.cellProps ?? {})}
      >
        <Component
          item={item}
          {...(fields?.[field]?.cellProps ?? {})}
          toggleExpanded={toggleExpanded}
          expanded={shouldExpand}
          selected={selected}
        />
      </div>
    );
  });
};

export const ItemRow = forwardRef(
  (
    {
      item,
      fields,
      id,
      expandable,
      ExpandedComponent = React.Fragment,
      preExpanded,
      selected,
      lastElement,
      onRowClick,
      meta
    },
    elementRef
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef();

    const toggleExpanded = () => {
      setIsExpanded((val) => !val);
    };

    useEffect(() => {
      if (preExpanded === item && expandable === true) {
        ref.current.scrollIntoView();
        setIsExpanded(true);
      }
    }, [ref, preExpanded, item, expandable]);

    const shouldExpand = isExpanded && expandable === true;

    return (
      <div ref={lastElement ? elementRef : null}>
        <div
          onClick={(event) => onRowClick && onRowClick(event, item)}
          className={clsx("row", {
            selected,
            expanded: isExpanded
          })}
          data-testid={`${id}-row`}
          ref={ref}
        >
          {mapItemsToRows({
            fields,
            id,
            item,
            toggleExpanded,
            shouldExpand,
            selected
          })}
        </div>
        <Collapse in={shouldExpand}>
          {expandable === true && (
            <div
              className={clsx("row", "expanded-content", {
                selected,
                expanded: isExpanded
              })}
              data-testid={`${id}-row-expanded`}
            >
              <ExpandedComponent
                item={item}
                fields={fields}
                {...(meta.expandedComponentProps ?? {})}
              />
            </div>
          )}
        </Collapse>
      </div>
    );
  }
);

export default CustomList;
