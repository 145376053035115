
export const convertToSnakeCase = (value) => {
  return value
    .replace(/([A-Z])/g, "_$1")
    .trim()
    ?.toLowerCase();
};

export const convertToCamelCase = (str) => {
  if (!str?.includes("_")) {
    return str;
  }
  let arr = str.split("_");

  return arr
    .map((value, index) => {
      return index === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    })
    ?.join("");
};

