import React from "react";
import styled from "styled-components";
import AuditItemContent from "./AuditItemContent";
import ShowEquipmentInfo from "./../ShowEquipmentInfo";
import { CircularProgress, DialogContentText } from "@mui/material";
import { OwcButton, OwcModalDialog } from "@one/react";
import { ScreenCentered } from "../../../components/shared/CustomSelect";

const NoAuditText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--one-color-background-brand-1);
`;

const AuditEquipmentInfo = styled.div`
  background-color: #var(--one-color-gray-50);
  border-radius: 4px;
  padding: 16px 24px;
  border-top: thin solid var(--one-color-gray-300);
  border-bottom: thin solid var(--one-color-gray-300);
`;

const AuditTrialDialog = ({
  title,
  openAuditTrial,
  handleClose,
  changeItem,
  dataTestId,
  isLoading,
  hideEquipmentInfo = false,
  userLists = []
}) => {
  return (
    <OwcModalDialog
      className="owcmodalZIndex"
      data-testid={`${dataTestId}-dialog`}
      visible={openAuditTrial}
      size="lg"
      onVisibleChange={(event) => {
        if (!event?.detail) {
          handleClose();
        }
      }}
    >
      <div slot="header" data-testid={`${dataTestId}-title`}>
        {title}
      </div>
      {isLoading ? (
        <ScreenCentered>
          <CircularProgress data-testid="loading" size={30} />
        </ScreenCentered>
      ) : changeItem?.length === 0 ? (
        <NoAuditText>No audit trail logs found for this equipment.</NoAuditText>
      ) : (
        <>
          {!hideEquipmentInfo && (
            <AuditEquipmentInfo>
              <ShowEquipmentInfo equipmentDetail={changeItem[0]} />
            </AuditEquipmentInfo>
          )}
          <div>
            <DialogContentText>
              <AuditItemContent
                changeItem={changeItem}
                dataTestId={dataTestId}
                userLists={userLists}
              />
            </DialogContentText>
          </div>
        </>
      )}
      <div
        slot="actions"
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        <OwcButton
          onClick={() => {
            handleClose(true);
          }}
          variant="primary"
          data-testid={`${dataTestId}-ok-btn`}
        >
          Ok
        </OwcButton>
      </div>
    </OwcModalDialog>
  );
};

export default AuditTrialDialog;
