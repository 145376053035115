import * as CSV from "csv-string";
import { uniqBy, orderBy, trim } from "lodash";
import {
  requiredInstrumentProperties,
  hasRequiredInstrumentProperties,
  getParsedInstruments,
  validateInstrumentValues,
  validateValidData,
  getHeaders
} from "./structure";

export const readFileAsBinaryString = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(null);
      }
    });
    reader.readAsBinaryString(file);
  });

export const checkFileStructure = (data, checkHeader, minFieldLength = 1) => {
  if (typeof data !== "string" && !data) throw new Error("No data provided");
  const delimiter = CSV.detect(data);
  let header = null;
  const dataCSV = [];
  CSV.forEach(data, delimiter, function (row, index) {
    if (index === 0 && row?.filter((x) => x)?.length) header = [...row];
    if (index !== 0 && Array.isArray(row) && row.length >= minFieldLength)
      dataCSV.push(row);
  });
  if (!header) throw new Error("Header not found");
  const checkHeaderResult = checkHeader(header);
  if (!checkHeaderResult.valid)
    throw new Error(
      `Required ${
        checkHeaderResult?.data?.length === 1
          ? "property was"
          : "properties were"
      } not found${": " + checkHeaderResult?.data?.join(", ")}`
    );
  return {
    header,
    content: dataCSV
  };
};

export const getObjectsFromFileCSV = async (file, masterDatas) => {
  const stringData = await readFileAsBinaryString(file);
  const result = checkFileStructure(
    stringData,
    hasRequiredInstrumentProperties,
    requiredInstrumentProperties.length
  );
  const parsedValues = getParsedInstruments(result.header, result.content);
  await validateInstrumentValues(parsedValues);
  await validateValidData(parsedValues, masterDatas);
  return {
    headers: getHeaders(result.header),
    data: parsedValues
  };
};

export const removeSpaceInString = (string) => {
  return string ? string.replace(/ /g, "") : string;
};

/**
 *
 * @TODO Need to check with business on the below
 * Need to generate material no based on type/model and serial no?
 */
export const getNewMaterialNumber = (instrument) => {
  const manufacturer = instrument?.manufacturer ?? "";
  const materialNo =
    `${manufacturer}${instrument?.equipmentModel}${instrument?.serialNumber}`.replace(
      / /g,
      ""
    );
  return materialNo;
};

export const sortedList = (items, fieldName = [], sortDirection = ["asc"]) => {
  return orderBy(items, [...fieldName], [...sortDirection]);
};

export const uniqList = (items, fieldName) => {
  return uniqBy(items, fieldName);
};

export const getSortedUniqueList = (list = [], columnName) => {
  return uniqList(list.map((x) => trim(x[columnName]))).sort();
};

export const getUniqValuesWithCaseInsensitive = (values) => {
  let uniqueValues = [];
  let repeatedValuesCaseInSensitive = [];
  values?.forEach((value) => {
    if (!repeatedValuesCaseInSensitive?.includes(value?.toLowerCase())) {
      uniqueValues.push(value);
      repeatedValuesCaseInSensitive.push(value?.toLowerCase());
    }
  });
  return uniqueValues;
};
