import React, { useCallback, useEffect, useState } from "react";
import { intersectionWith } from "lodash";
import { connect } from "react-redux";
import { pick } from "lodash/fp";
import { requiredProps, instrumentIdentifiers } from "./utils/structure";

export const FileContext = React.createContext({});

export const getExisingRecordsIds = (actual, toImport, comparator) =>
  intersectionWith(actual, toImport, comparator)?.map(
    pick(instrumentIdentifiers)
  );

export const FileContextWapper = ({ children, instruments }) => {
  const [file, setFile] = useState(null);
  const [importData, setImportData] = useState(null);
  const [existingRecordsIds, setExistingRecordsIds] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [pickedProps, setPickedProps] = useState(requiredProps);
  const [skipExisingRecords, setSkipExistingRecords] = useState(true);

  useEffect(() => {
    if (file === null) {
      setImportData(null);
      setPickedProps(requiredProps);
      setSkipExistingRecords(true);
    }
  }, [file]);

  const onSkipExisingRecords = useCallback(
    (evt) => setSkipExistingRecords(evt.target.checked),
    []
  );
  const onPickedProps = useCallback((evt) => {
    const prop = evt.target.value;
    setPickedProps((prev) =>
      prev.includes(prop) ? prev.filter((p) => p !== prop) : [...prev, prop]
    );
  }, []);

  const onAllPickedProps = useCallback(() => {
    const allProps = Object.keys(importData?.[0] ?? {});
    setPickedProps((prev) =>
      prev.length !== allProps.length ? allProps : requiredProps
    );
  }, [importData]);

  const isPropChecked = useCallback(
    (value) => pickedProps.includes(value),
    [pickedProps]
  );

  const isAllPropChecked = useCallback(() => {
    const allProps = Object.keys(importData?.[0] ?? {});
    return pickedProps.length === allProps.length;
  }, [pickedProps, importData]);

  return (
    <FileContext.Provider
      value={{
        file,
        setFile,
        importData,
        setImportData,
        pickedProps,
        onPickedProps,
        skipExisingRecords,
        onSkipExisingRecords,
        isPropChecked,
        onAllPickedProps,
        isAllPropChecked,
        existingRecordsIds,
        setExistingRecordsIds,
        headers,
        setHeaders
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  instruments: state.instruments.instruments
});

export default connect(mapStateToProps)(FileContextWapper);
