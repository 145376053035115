import React from "react";
import { RowInputs, CheckboxRow } from "./BasicDataReqFieldsForm";
import { commonPropsForInputsWithValue } from "./helpers";
import { OwcInput } from "@one/react";
import { Checkbox, FormControlLabel } from "@mui/material";

// export const useStyles = makeStyles(() => ({
//   button: buttonStyles,
//   selected: selectedStyles,
//   checkbox: {
//     color: "var(--one-color-gray-600)",
//     marginRight: "3px"
//   },
//   clearInputButton: {
//     color: "#8A8A8A",
//     fontSize: "20px",
//     paddingRight: 18
//   }
// }));

const InputWithCheckbox = ({
  formik,
  fieldName,
  testid,
  label,
  isDisabled = false,
  commonProps = commonPropsForInputsWithValue,
  inputProps = {},
  prefixTest = "basic-data-additional-info-fields-instrument",
  isReadOnly = false
}) => {
  const classes = {};
  const checkboxLabel = formik.values?.[fieldName]?.isSynchronized
    ? "Will update"
    : "Update?";
  return (
    <RowInputs>
      <OwcInput
        data-testid={`${prefixTest}-${testid}-input`}
        variant="filled"
        id={fieldName}
        name={isDisabled ? fieldName : `${fieldName}.value`}
        label={label}
        value={
          isDisabled
            ? formik.values?.[fieldName]
            : formik.values?.[fieldName]?.value
        }
        onInputChange={formik.handleChange}
        disabled={isReadOnly}
        {...commonProps(
          classes,
          formik,
          fieldName,
          testid,
          prefixTest,
          isReadOnly
        )}
        {...inputProps}
        style={{ width: "100%" }}
      />
      {isDisabled ? (
        <CheckboxRow isDisabled={true}>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                data-testid={`${prefixTest}-${testid}-disabled-checkbox`}
              />
            }
            label="Update?"
          />
        </CheckboxRow>
      ) : (
        <CheckboxRow isChecked={formik.values?.[fieldName]?.isSynchronized}>
          <FormControlLabel
            data-testid={`${prefixTest}-${testid}-checkbox-label`}
            control={
              <Checkbox
                color="primary"
                data-testid={`${prefixTest}-${testid}-checkbox`}
                checked={formik.values?.[fieldName]?.isSynchronized}
                onChange={formik.handleChange}
                name={`${fieldName}.isSynchronized`}
              />
            }
            label={checkboxLabel}
          />
        </CheckboxRow>
      )}
    </RowInputs>
  );
};

export default InputWithCheckbox;
