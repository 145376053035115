import { OwcInputCustomized } from "./OwcInputCustomized";

export const SerialNumberInput = ({ ...args }) => (
  <OwcInputCustomized
    data-testid="text-field-serial-number"
    dataName={"serialNumber"}
    label="Serial number"
    onBlurValidation={(value) => value.trim().split(/\s+/).join(" ")}
    valueValidation={(value) => value?.trimStart().split(/\s+/).join(" ")}
    {...args}
  />
);
