import { OwcIcon, OwcInput } from "@one/react";
export const OwcInputCustomized = ({
  formik,
  style,
  assistiveText = "",
  required,
  label,
  dataName,
  valueValidation,
  onBlurValidation,
  ...args
}) => {
  const value = valueValidation?.(formik?.values?.[dataName]);
  return (
    <OwcInput
      variant="filled"
      style={{
        width: "100%",
        ...style
      }}
      name={dataName}
      id={dataName}
      label={`${label}${required ? " *" : ""}`}
      value={value}
      onInputChange={formik.handleChange}
      onBlur={(event) => {
        formik.handleBlur(event);
        if (onBlurValidation) {
          formik.setFieldValue(
            dataName,
            onBlurValidation(formik.values?.[dataName]),
            true
          );
        }
      }}
      required={required}
      {...args}
    >
      {formik.values?.[dataName] !== "" &&
        formik.touched?.[dataName] === true && (
          <OwcIcon
            name="circle_clear_filled"
            slot="suffix"
            type="legacy"
            onClick={() => formik.setFieldValue([dataName], "", true)}
          />
        )}
      <span slot="assistive-text">{assistiveText}</span>
    </OwcInput>
  );
};
