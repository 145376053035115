/** FileCreate svg icon */
export const FileCreate = () => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_580_2890)">
      <path
        d="M20.2746 61.2043C19.3142 61.2043 18.5322 60.4223 18.5322 59.4619V8.1875C18.5322 7.22713 19.3142 6.44512 20.2746 6.44512H60.4179C61.3783 6.44512 62.1603 7.22713 62.1603 8.1875V59.4665C62.1603 60.4268 61.3783 61.2088 60.4179 61.2088H20.2746V61.2043Z"
        fill="#CCE0F5"
      />
      <path
        d="M60.4177 6.90244C61.1265 6.90244 61.7028 7.47866 61.7028 8.1875V59.4665C61.7028 60.1753 61.1265 60.7515 60.4177 60.7515H20.2744C19.5656 60.7515 18.9893 60.1753 18.9893 59.4665V8.1875C18.9893 7.47866 19.5656 6.90244 20.2744 6.90244H60.4177V6.90244ZM60.4177 5.9878H20.2744C19.0625 5.9878 18.0747 6.97104 18.0747 8.1875V59.4665C18.0747 60.6784 19.0579 61.6662 20.2744 61.6662H60.4177C61.6296 61.6662 62.6174 60.6829 62.6174 59.4665V8.1875C62.6174 6.97104 61.6296 5.9878 60.4177 5.9878Z"
        fill="#0B41CD"
      />
      <path
        d="M11.0461 69.5549C10.5247 69.5549 10.104 69.1159 10.104 68.5762V28.1265L24.4683 13.7622H53.0598C53.5811 13.7622 54.0019 14.2012 54.0019 14.7409V68.5762C54.0019 69.1159 53.5766 69.5549 53.0598 69.5549H11.0461Z"
        fill="#F7F9FC"
      />
      <path
        d="M53.0642 14.2195C53.334 14.2195 53.5489 14.4527 53.5489 14.7409V68.5762C53.5489 68.8643 53.3294 69.0976 53.0642 69.0976H11.0459C10.7761 69.0976 10.5611 68.8643 10.5611 68.5762V28.3186L24.6602 14.2195H53.0642ZM53.0642 13.3049H24.2806L9.64648 27.939V68.5762C9.64648 69.3674 10.273 70.0122 11.0459 70.0122H53.0596C53.8325 70.0122 54.459 69.3674 54.459 68.5762V14.7409C54.4636 13.9497 53.837 13.3049 53.0642 13.3049Z"
        fill="#0B41CD"
      />
      <path
        d="M49.0078 65.0503L40.3462 70.0122L42.6831 60.3262L45.4133 56.6906C45.898 56.0457 46.6069 55.7576 47.0002 56.0457L51.9072 59.7088C52.2959 60.0015 52.2227 60.7607 51.738 61.4101L49.0078 65.0503Z"
        fill="#B2D1F0"
      />
      <path
        d="M48.9714 64.186C48.9394 64.186 48.8891 64.1768 48.8388 64.1402L43.6025 60.2302C43.5385 60.1845 43.5202 60.1204 43.5156 60.0884C43.511 60.0564 43.511 59.9878 43.5568 59.9284L61.8632 35.5442C62.1238 35.1966 62.5263 34.9954 62.9653 34.9954C63.2626 34.9954 63.5461 35.0915 63.7885 35.2698L67.1818 37.8034C67.4744 38.0229 67.6665 38.343 67.7168 38.7043C67.7671 39.0655 67.6757 39.4268 67.4562 39.7195L49.1498 64.1037C49.104 64.154 49.04 64.186 48.9714 64.186Z"
        fill="#CCE0F5"
      />
      <path
        d="M62.9607 35.4482C63.1573 35.4482 63.3494 35.5122 63.5094 35.6311L66.9027 38.1646C67.0994 38.311 67.2228 38.5213 67.2594 38.7637C67.296 39.0015 67.232 39.2439 67.0857 39.436L48.921 63.6326L44.0643 60.0061L62.2244 35.814C62.4027 35.5854 62.668 35.4482 62.9607 35.4482ZM62.9607 34.5335C62.4027 34.5335 61.8539 34.7851 61.4927 35.2652L43.1863 59.6494C42.9622 59.9466 43.0216 60.372 43.3235 60.596L48.5597 64.5061C48.6832 64.5976 48.825 64.6433 48.9668 64.6433C49.1725 64.6433 49.3783 64.5518 49.511 64.3735L67.8174 39.9893C68.421 39.1844 68.2564 38.0412 67.4515 37.4375L64.0582 34.904C63.7289 34.6524 63.3448 34.5335 62.9607 34.5335Z"
        fill="#0B41CD"
      />
      <path
        d="M43.104 60.5274L45.7747 56.9649C46.072 56.5716 46.4241 56.3933 46.6299 56.3933C46.6711 56.3933 46.7031 56.4024 46.726 56.4162L51.633 60.0838C51.6741 60.1113 51.6878 60.1844 51.6924 60.221C51.7199 60.4085 51.6513 60.7652 51.3677 61.1402L48.697 64.6982L41.0415 69.0884L43.104 60.5274Z"
        fill="white"
      />
      <path
        d="M46.5703 56.8689L51.2395 60.3537C51.2258 60.4634 51.1617 60.6509 51.0063 60.8613L48.3904 64.346L41.7364 68.1601L43.5291 60.7195L46.145 57.2348C46.305 57.029 46.4697 56.9146 46.5703 56.8689ZM46.6297 55.936C46.2456 55.936 45.77 56.2195 45.4133 56.6905L42.6831 60.3262L40.3462 70.0122L49.0078 65.0503L51.738 61.4146C52.2227 60.7698 52.3005 60.0061 51.9072 59.7134L47.0002 56.0503C46.895 55.9726 46.7715 55.936 46.6297 55.936Z"
        fill="#0B41CD"
      />
      <path
        d="M49.1084 64.9131L42.7837 60.1936L59.3203 38.1601L65.645 42.8841L49.1084 64.9131Z"
        fill="#0B41CD"
      />
      <path
        d="M44.3066 67.7439L40.3462 70.0122L41.4163 65.5854L44.3066 67.7439Z"
        fill="#0B41CD"
      />
      <path
        d="M47.1189 63.436L44.6128 61.5655L61.25 39.596L63.7607 41.471L47.1189 63.436Z"
        fill="#99C2EB"
      />
      <path
        d="M32.2378 34.7119V49.3415"
        stroke="#0B41CD"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.5458 42.0244H24.9346"
        stroke="#0B41CD"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M10.7485 27.4817L23.8232 14.407V27.4817H10.7485Z"
        fill="#F7F9FC"
      />
      <path
        d="M23.366 15.5137V27.0244H11.8553L23.366 15.5137ZM24.2806 13.3049L9.64648 27.939H24.2806V13.3049Z"
        fill="#0B41CD"
      />
      <path
        d="M24.3173 27.939L10.5703 41.4848V27.939H24.3173Z"
        fill="#CCE0F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_2890">
        <rect
          width="75"
          height="75"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
