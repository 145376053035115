import { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllData } from "../../utils/helpers/fetching";
import { LIST_DIGITAL_LAB_INSTRUMENT_REASON } from "../../gql/landingapi/queries";
import { DEFAULT_RECORDS_FETCHED, SEARCH_FILTER_QUERY } from "../../constants";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "../../components/shared/GridAutoCompleteStyles";
import { OTHER_REASON } from "../../constants";
import { OwcIcon, OwcTextarea, OwcTypography } from "@one/react";
import { find } from "lodash";
import { useFormikContext } from "formik";
import CustomSearchText from "../../components/shared/CustomSearchText";

const ReasonForm = styled.div`
  display: flex;
  margin: 16px 0px 0px 0px;
  & > .customSelect {
    width: 50%;
    margin-right: 20px;
  }
  & > .customSelect2 {
    width: 50%;
  }
  & > .customSelectBulkEdit {
    width: 100%;
    margin-bottom: 15px;
  }
  & > .customSelect2BulkEdit {
    width: 100%;
  }
`;

const ReasonDropdown = ({
  client,
  selectedReason,
  setSelectedReason,
  addComment,
  setAddComment,
  userList,
  queryChoice,
  totalCount,
  searchFilterCount,
  reviewInventoryId,
  isReviewerUpdate,
  designBulkEditStyle = false
}) => {
  const [reasonList, setReasonList] = useState([]);
  const [isError, setError] = useState(false);
  const formik = useFormikContext();
  const getReasonList = async () => {
    const list = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REASON,
      variables: {
        limit: DEFAULT_RECORDS_FETCHED
      },
      fetchPolicy: "network-only",
      dataPath: ["data", "configurationByModelAndCreatedDate"],
      drillData: false
    });
    const tempList = list.items.map((x) => {
      return { id: x.id, key: x.configText, value: x.configText };
    });
    setReasonList([...tempList]);
  };

  useEffect(() => {
    getReasonList();
    setSelectedReason("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isOtherReason =
    selectedReason && selectedReason?.trim() === OTHER_REASON;

  return (
    <ReasonForm style={{ flexWrap: designBulkEditStyle ? "wrap" : "nowrap" }}>
      <GridAutocomplete
        dataTestid="reason-select"
        className={
          designBulkEditStyle ? "customSelectBulkEdit" : "customSelect"
        }
        size="small"
        sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
        value={find(reasonList, { value: selectedReason }) || null}
        optionsList={reasonList}
        propValue="value"
        propLabel="key"
        label={isReviewerUpdate ? "Reason *" : "Select reason"}
        onAutoCompleteChange={(selectedValue) => {
          if (selectedValue !== null) {
            setSelectedReason(selectedValue?.value);
          }
        }}
        disableClearable
      />

      <OwcTextarea
        data-testid={`textarea-field-edit-comment`}
        className={
          designBulkEditStyle ? "customSelect2BulkEdit" : "customSelect2"
        }
        label={
          isOtherReason
            ? isReviewerUpdate
              ? "Comment *"
              : "Add comment *"
            : isReviewerUpdate
            ? "Comment "
            : "Add comment "
        }
        error={isOtherReason && isError}
        name="editComment"
        validation-mode="realtime"
        onInputReset={() => setAddComment("")}
        value={addComment}
        required={isOtherReason}
        onInputChange={(event) => {
          setAddComment(event.target.value);
          if (event.target.value === "") {
            setError(true);
          }
        }}
        onInputKeyDown={(event) => {
          setAddComment(event.target.value);
        }}
        reset
        cols={20}
        maxLength={80}
        minLength={0}
        multiline
        rows={1}
        minRows={2}
      />
      {isReviewerUpdate && (
        <>
          <OwcTypography
            variant="subtitle2"
            style={{ padding: "0px 20px 20px 0px" }}
          >
            Reviewer required
          </OwcTypography>
          <CustomSearchText
            id="reviewers"
            className={
              designBulkEditStyle ? "customSelect2BulkEdit" : "customSelect2"
            }
            dataTestid={`reviewers-inputs-reviewers-input`}
            optionsList={userList}
            propValue="key"
            propLabel="value"
            label="Reviewer"
            value={formik.values?.reviewers || []}
            setValue={(newValue) => {
              formik.setFieldValue("reviewers", newValue, true);
            }}
            name="reviewers"
            fullWidth={true}
            error={
              formik.touched["reviewers"] && Boolean(formik.errors["reviewers"])
            }
            helperText={
              formik.touched["reviewers"] && formik.errors["reviewers"]
            }
            multiple={true}
            onBlur={formik.handleBlur}
            required={true}
          />
          <div style={{ display: "flex" }}>
            <OwcIcon
              name="circle_info"
              type="outlined"
              style={{
                // color: "var(--one-color-interaction-default-warning-1)",
                paddingTop: 20
              }}
            />
            <OwcTypography
              variant="subtitle2"
              style={{
                fontSize: 15,
                padding: "20px 10px 15px 10px",
                fontWeight: 400
              }}
            >
              {`Reviewer selection applies to the `}
              <b>
                {queryChoice === SEARCH_FILTER_QUERY
                  ? reviewInventoryId.length + `/` + searchFilterCount
                  : reviewInventoryId.length + `/` + totalCount}
              </b>
              {` equipment that require a 4-eye review`}
            </OwcTypography>
          </div>
        </>
      )}
    </ReasonForm>
  );
};

export default compose(connect(null), withApollo)(ReasonDropdown);
