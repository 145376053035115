import React, { useContext } from "react";
import { MasterDataContext } from "./MasterDataContext";
import Notify from "../notifications/Notify";
import { DOWNLOAD_TEMPLATE } from "../../gql/landingapi/queries";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { OwcButton } from "@one/react";

const DownloadTemplateButton = ({ client }) => {
  const { selectedModule, isActionButtonDisabled } =
    useContext(MasterDataContext);

  /**
   * This method is used download the file to client
   */
  const downloadTemplate = async () => {
    try {
      const presignedUrlData = await client.query({
        query: DOWNLOAD_TEMPLATE,
        fetchPolicy: "no-cache",
        variables: {
          targetModule: selectedModule?.targetModule
        }
      });

      if (!presignedUrlData?.data && presignedUrlData?.data?.downloadTemplate) {
        throw new Error(
          "File download is not available. Please try again later"
        );
      }
      const downloadPath = presignedUrlData?.data?.downloadTemplate;
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("type", "hidden");
      downloadLink.href = downloadPath;
      downloadLink.download = selectedModule?.downloadName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Template downloaded successfully.`
      });
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: error?.message || error
      });
    }
  };

  return (
    <OwcButton
      variant="primary"
      data-testid="download-template-master-data"
      onClick={downloadTemplate}
      disabled={isActionButtonDisabled}
    >
      Download data
    </OwcButton>
  );
};

export default compose(connect(null, {}), withApollo)(DownloadTemplateButton);
