import React, { useState } from "react";
import ReasonMainPage from "../reasons/ReasonMainPage";
import GroupMainPage from "../groups/GroupMainPage";
import { OwcTab, OwcTabs } from "@one/react";
import LinkedInstanceMainPage from "../linked-instance/LinkedInstanceMainPage";
import MasterDataMainPage from "../master-data-management/MasterDataMainPage";
import ChecklistMainPage from "../checklist/ChecklistMainPage";
import { capitalize } from "lodash";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";

const THEME = themeMapping[THEME_NAME];

export const ADMIN_TABS = {
  GROUP_ACCESS: "GROUP_ACCESS",
  REASONS: "REASON",
  LINKED_INSTANCE: "LINKED_INSTANCE",
  MASTER_DATA: "MASTER_DATA",
  CHECKLIST_MANAGEMENT: "CHECKLIST"
};

/** Tabs for Admin panel */

const AdminTabs = () => {
  const [value, setValue] = useState(ADMIN_TABS.GROUP_ACCESS);
  const handleChange = (e) => {
    setValue(e.detail);
  };

  return (
    <div style={{ background: THEME["one-color-cobas-white"] }}>
      <OwcTabs
        data-testid="admin-tabs"
        value={value}
        onTabChange={handleChange}
      >
        {Object.values(ADMIN_TABS).map((value) => {
          return (
            <OwcTab
              key={value}
              data-testid={`admin-tab-${value}`}
              label={capitalize(`${value.replace("_", " ")} management`)}
              value={value}
            >
              {capitalize(`${value.replace("_", " ")} management`)}
            </OwcTab>
          );
        })}
      </OwcTabs>
      {value === ADMIN_TABS.GROUP_ACCESS && <GroupMainPage />}
      {value === ADMIN_TABS.REASONS && <ReasonMainPage />}
      {value === ADMIN_TABS.LINKED_INSTANCE && <LinkedInstanceMainPage />}
      {value === ADMIN_TABS.MASTER_DATA && <MasterDataMainPage />}
      {value === ADMIN_TABS.CHECKLIST_MANAGEMENT && <ChecklistMainPage />}
    </div>
  );
};

export default AdminTabs;
