import * as yup from "yup";

/**
 *
 * @param {identificationValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

const checkSecondaryIdMapping = (linkedInstance, field) => {
  return linkedInstance?.secondaryIdMapping?.split("; ")?.includes(field);
};
/**
 * schema for validating the equpiment identification form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const identificationValidationSchema = yup.object({
  linkedInstance: yup
    .object({
      linkedInstance: yup
        .string("Select linked instance")
        .typeError("Invalid type"),
      linkedInstanceDisplay: yup
        .string("Select linked instance")
        .typeError("Invalid type"),
      secondaryIdMapping: yup
        .string("Select linked instance")
        .typeError("Invalid type")
    })
    .required("Select linked instance")
    .typeError("Invalid type")
    .nullable(),
  secondaryID: getCustomNullable(
    yup.string("Enter secondary ID fields").typeError("Invalid type")
  ),
  serialNumber: getCustomNullable(
    yup
      .string("Enter serial number")
      .trim()
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "serialNumber"),
        then: yup.string().required("Enter serial number")
      })
  ),
  materialNumber: getCustomNullable(
    yup
      .string("Enter material number")
      .trim()
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "materialNumber"),
        then: yup.string().required("Enter material number")
      })
  ),
  equipmentId: getCustomNullable(
    yup
      .string("Enter equipment ID")
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "equipmentId"),
        then: yup.string().required("Enter equipment ID")
      })
  ),
  manufacturer: yup
    .string("Enter manufacturer")
    .typeError("Invalid type")
    .when("linkedInstance", {
      is: (linkedInstance) =>
        checkSecondaryIdMapping(linkedInstance, "manufacturer"),
      then: yup.string().required("Manufacturer is required").nullable()
    })
    .nullable(),
  equipmentModel: yup
    .string("Enter model")
    .typeError("Invalid type")
    .when("linkedInstance", {
      is: (linkedInstance) =>
        checkSecondaryIdMapping(linkedInstance, "equipmentModel"),
      then: yup.string().required("Model is required").nullable()
    })
    .nullable(),
  siteName: getCustomNullable(
    yup
      .string("Enter site")
      .required("Site is required")
      .typeError("Invalid type")
  ),
  inventoryNumber: getCustomNullable(
    yup
      .string()
      .typeError("Enter a numeric value only")
      .matches(/^(|\d)+$/, "Enter a numeric value only")
  ),
  instrumentRUDI: getCustomNullable(
    yup.string("Enter RUDI").typeError("Invalid type")
  ),
  instrumentGTIN: getCustomNullable(
    yup
      .string()
      .typeError("Enter a numeric value only")
      .matches(/^(|\d)+$/, "Enter a numeric value only")
  )
});
