import {
  SHOW_SUB_CLUSTER_KEY,
  SHOW_CLUSTER_KEY,
  entryType
} from "../../../constants";

export function lineGenerator(item) {
  try {
    let divCollecter = "";
    if (item.entryType === entryType?.cluster) {
      divCollecter += " (Cluster): ";
      SHOW_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
        divCollecter += `${clusterItem?.label}:${
          item[clusterItem?.key] !== null &&
          item[clusterItem?.key] !== "undefined" &&
          item[clusterItem?.key] !== "null"
            ? item[clusterItem?.key]
            : " -"
        }${indexItem < SHOW_CLUSTER_KEY.length - 1 ? ", " : ""}`;
      });
    } else {
      divCollecter += " (Equipment): ";
      SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
        divCollecter += `${clusterItem?.label}:${
          item[clusterItem?.key] !== null &&
          item[clusterItem?.key] !== "undefined" &&
          item[clusterItem?.key] !== "null"
            ? item[clusterItem?.key]
            : " -"
        }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
      });
    }
    return divCollecter;
  } catch (error) {
    return error;
  }
}
