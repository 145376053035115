import * as yup from "yup";
import { OTHER_REASON } from "../../constants";

const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

const checkSecondaryIdMapping = (linkedInstance, field) => {
  return linkedInstance?.secondaryIdMapping?.split("; ")?.includes(field);
};

export const linkedInstanceValidationSchema = yup.object().shape({
  linkedInstance: yup
    .object({
      linkedInstance: yup
        .string("Select linked instance")
        .typeError("Invalid type"),
      linkedInstanceDisplay: yup
        .string("Select linked instance")
        .typeError("Invalid type"),
      secondaryIdMapping: yup
        .string("Select linked instance")
        .typeError("Invalid type")
    })
    .required("Select linked instance")
    .typeError("Invalid type")
    .nullable(),
  secondaryID: getCustomNullable(
    yup.string("Enter secondary ID fields").typeError("Invalid type")
  ),
  serialNumber: getCustomNullable(
    yup
      .string("Enter serial number")
      .trim()
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "serialNumber"),
        then: yup.string().required("Enter serial number")
      })
  ),
  materialNumber: getCustomNullable(
    yup
      .string("Enter material number")
      .trim()
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "materialNumber"),
        then: yup.string().required("Enter material number")
      })
  ),
  equipmentId: getCustomNullable(
    yup
      .string("Enter equipment ID")
      .typeError("Invalid type")
      .when("linkedInstance", {
        is: (linkedInstance) =>
          checkSecondaryIdMapping(linkedInstance, "equipmentId"),
        then: yup.string().required("Enter equipment ID")
      })
  ),
  manufacturer: yup
    .string("Enter manufacturer")
    .typeError("Invalid type")
    .when("linkedInstance", {
      is: (linkedInstance) =>
        checkSecondaryIdMapping(linkedInstance, "manufacturer"),
      then: yup.string().required("Manufacturer is required").nullable()
    })
    .nullable(),
  equipmentModel: yup
    .string("Enter model")
    .typeError("Invalid type")
    .when("linkedInstance", {
      is: (linkedInstance) =>
        checkSecondaryIdMapping(linkedInstance, "equipmentModel"),
      then: yup.string().required("Model is required").nullable()
    })
    .nullable(),
  editReason: yup
    .string("Select reason")
    .typeError("Invalid type")
    .required("Select linked instance"),
  editComment: yup
    .string("Add comment")
    .typeError("Invalid type")
    .when("editReason", {
      is: (editReason) => editReason === OTHER_REASON,
      then: yup.string().required("Comment is required")
    })
});
