import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import { SelectItemsContext } from "../../../../components/context/contexts";
import { connect, useSelector } from "react-redux";
import {
  CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT,
  entryType,
  allTableColumn,
  DEFAULT_FILTER,
  listOfFieldsSortable,
  listOfFieldsIgnoredForSearch,
  DEFAULT_COLUMNS,
  listOfNumberFilter,
  agGridAutoGroupfieldName,
  dimentionUnits,
  literalFields,
  equipmentStatus
} from "../../../../constants";
import { useEffect } from "react";
import Notify from "../../../notifications/Notify";
import { filter, find, sortBy, uniq } from "lodash";
import { getColumnObj } from "./addEquipmentService";
import { loadFilterData } from "../../InstrumentsTable";
import { TOOL_PANELS, filterKeyMapping } from "../dLabGridConstant";
import {
  convertStrToObjColumns,
  getMegaClusterIds,
  onCreateSelectColumn,
  onCreatefilter,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize
} from "../clusterListService";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { withApollo } from "react-apollo";
import { compose } from "redux";
import {
  CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  CREATE_IR_DISPLAY_COLUMN,
  DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  DELETE_IR_DISPLAY_COLUMN,
  UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_IR_DISPLAY_COLUMN
} from "../../../../gql/landingapi/mutations";
import {
  updateLastDisplayColumn as updateLastDisplayColumnAction,
  updateUserDisplayColumns as updateUserDisplayColumnsAction,
  updateLastFavFilter as updateLastFavFilterAction,
  updateUserFilters as updateUserFiltersAction,
  updateLastPageSize as updateLastPageSizeAction
} from "../../../user/redux/actions";
import { onlyUnique } from "../../../../utils/helpers/text";
import { DLabEquipmentList } from "@digitallab/grid-common-components";
import { CloudSearchConfig } from "../../../../components/shared/CloudSearchConfig";
import { OwcBadge, OwcButton, OwcTypography } from "@one/react";
import { convertToCamelCase, convertToSnakeCase } from "../../../../utils/helpers/converters";

const AddEquipment = ({
  clusterEquipments,
  setClusterEquipments,
  isSubCluster,
  setIsNextDisabled,
  setDisableSave,
  isEditMode,
  updateUserFilters,
  updateLastFavFilter,
  updateUserDisplayColumns,
  updateLastDisplayColumn,
  updateLastPageSize,
  hasPermission,
  updateClusteSubEquipmentsObjs,
  clusteSubEquipments,
  selectedCluster,
  actionStatus,
  client
}) => {
  const [dataModel, setDataModel] = useState({});
  const [masterDataFilters, setMasterDataFilters] = useState({});
  const [attributes, setAttributes] = useState({});
  const [defaultShowColumns, setDefaultShowColumns] = useState([]);
  const {
    userFilters,
    myLabFilter,
    lastPageSize,
    lastFavFilter,
    lastDisplayColumns,
    userDisplays,
    systemDisplays,
    email,
    groups,
    id: userID
  } = useSelector((state) => state?.user);
  const userDisplayList = useRef([]);
  const megaclusterIds = useRef([]);
  const masterDataAutoGroup = useRef({});
  const autoGroupColField = "equipment_model";
  useEffect(() => {
    userDisplayList.current = [...userDisplays];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let siteList = useSelector((state) => state?.user?.sites);
  const linkedInstanceList = useSelector(
    (state) => state.instruments?.linkedInstanceList
  );
  const { selected, updateSelectedItems } = useContext(SelectItemsContext);

  const addClusterEquip = () => {
    if (clusterEquipments.length > CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT) {
      Notify({
        type: "alert",
        icon: "caution",
        appName: "",
        text: "Max components limit reached."
      });
    } else {
      updateSelectedItems([...clusterEquipments]);
      const tempIds = [...clusterEquipments];
      let noOrderList = [];
      let orderedList = [];
      tempIds.forEach((value) => {
        const obj = find(clusteSubEquipments || [], { id: value });
        if (obj && obj?.positionInCluster) {
          orderedList.push(obj);
        } else {
          noOrderList.push({
            id: value,
            positionInCluster: null
          });
        }
      });
      const mappedOrdered = noOrderList?.map((item, index) => {
        return {
          ...item,
          positionInCluster: orderedList?.length + index + 1
        };
      });
      const temp = [...orderedList, ...mappedOrdered];
      updateClusteSubEquipmentsObjs(temp);
      setIsNextDisabled(temp.length <= 1);
      const textMsg = isEditMode
        ? "Components updated successfully"
        : "Components added successfully";
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: textMsg
      });
    }
  };

  useEffect(() => {
    setClusterEquipments([...selected]);
    setIsNextDisabled(selected.length <= 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected.length > CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT) {
      Notify({
        type: "alert",
        icon: "caution",
        appName: "",
        text: "Max components limit reached."
      });
    }
  }, [selected]);

  useEffect(() => {
    const loadMasterData = async () => {
      const { masterDataFilter } = await loadFilterData({
        client,
        siteList,
        linkedInstanceList
      });
      masterDataAutoGroup.current = { ...masterDataFilter };
      setMasterDataFilters({ ...masterDataFilter });
    };

    loadMasterData();

    const loadMegaClusterIds = async () => {
      const ids = await getMegaClusterIds();
      megaclusterIds.current = uniq(ids);
    };

    loadMegaClusterIds();

    let attr = {
      rowModelType: "serverSide",
      rowQuickSearch: false,
      treeData: true,
      suppressPaginationPanel: true,
      pagination: true,
      animateRows: true,
      rowSelection: "multiple",
      rowMultiSelectWithClick: true,
      rowExport: false,
      masterDetail: true,
      isGroupOpenByDefault: false,
      defaultToolPanel: "filters",
      hiddenByDefault: true,
      serverSideInfiniteScroll: false,
      serverSideSortOnServer: true,
      suppressRowClickSelection: true
    };

    setAttributes(() => attr);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unorderedHidenColumn = [];
    const orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition
        ?.showColumns,
      ["order"],
      ["asc"]
    );
    allTableColumn()?.forEach((value, index) => {
      const obj = find(orderedShowColumns, {
        key: value?.key
      });
      if (!obj) {
        unorderedHidenColumn.push(value);
      }
    });

    const redefinedColumns = [
      ...orderedShowColumns,
      ...unorderedHidenColumn
    ]?.map((value) => {
      return getColumnObj(
        value,
        masterDataFilters,
        convertToCamelCase(autoGroupColField),
        dimentionUnits?.metricUnit
      );
    });

    const defaultShowCols = filter(allTableColumn(), { show: true });
    setDefaultShowColumns(() => {
      return defaultShowCols?.map((value) => {
        return getColumnObj(
          value,
          masterDataFilters,
          convertToCamelCase(autoGroupColField),
          dimentionUnits?.metricUnit
        );
      });
    });

    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [masterDataFilters]);

  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in DEFAULT_FILTER.filterDefinition) {
          if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)] ===
            undefined
          ) {
            obj[key] = DEFAULT_FILTER.filterDefinition[key];
          } else if (
            currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
              ?.filterType === "set"
          ) {
            let values =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)]
                ?.values;
            if (
              filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
              key === DATA_MODEL_TABLE?.equipmentModel?.key
            ) {
              if (
                Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)
              ) {
                values = uniq([
                  ...values,
                  ...currentState[agGridAutoGroupfieldName]?.values
                ]);
              }
            }
            obj[key] = values;
          } else {
            obj[key] =
              currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
          }
        }
      } else {
        obj = {
          ...currentState
        };
      }

      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName
      };
      if (type === "create") {
        lastSavedFilter.userID = userID;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: userID,
          email,
          userFilters,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserFilters,
          updateLastFavFilter
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateLastFavFilter
        });
      }
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = convertToCamelCase(column?.colId);
        const obj = {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[dataModelKey]?.value?.length
            ? !column?.hide
            : "ignore",
          sortStatus: listOfFieldsSortable.includes(
            DATA_MODEL_TABLE[dataModelKey]?.key
          )
        };
        return obj;
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns
        }
      };
      if (type === "create") {
        savecolumnObj.userID = userID;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: userID,
          email,
          userDisplayList,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_IR_DISPLAY_COLUMN,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: userID,
          email,
          client,
          userProfileQuery:
            UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
          query: UPDATE_IR_DISPLAY_COLUMN,
          userDisplayList,
          updateUserDisplayColumns,
          updateLastDisplayColumn
        });
      }
      return { savedObj: savecolumnObj, result };
    }
  };

  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (
      dataItem?.entry_type &&
      dataItem?.entry_type?.includes(entryType?.cluster)
    ) {
      return dataItem?.id;
    }

    return "";
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: autoGroupColField,
      sortable: true,
      pinned: "left",
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values =
            masterDataAutoGroup?.current[
              DATA_MODEL_TABLE?.equipmentModel?.key
            ] || [];
          params.success(values);
        }
      },
      showDisabledCheckboxes: true,
      cellStyle: (params) => {
        if (params?.data?.id && params?.data?.id[0] === "dummyRow") {
          return { display: "none" };
        }
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              border: "none",
              backgroundColor: "#F5F5F2",
              backgroundImage:
                "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
            }
          : {
              marginLeft: "none !important"
            };
      },
      cellRendererParams: {
        suppressCount: false,
        checkbox: (params) => {
          if (
            params?.data?.status &&
            params?.data?.status[0] === equipmentStatus?.deleted?.key
          ) {
            return false;
          }
          if (params?.data?.id && params?.data?.id[0] !== "dummyRow") {
            const item = {
              entryType:
                (params.data?.entry_type && params.data?.entry_type[0]) ||
                entryType?.standaloneEquipment,
              id: params.data.id[0],
              clusterId: params.data?.cluster_id
                ? params.data?.cluster_id[0]
                : null,
              status: params.data?.status[0],
              belongingToGroup:
                (params.data?.belonging_to_group &&
                  params.data?.belonging_to_group[0]) ||
                null
            };

            let totalSubEquipments = [...clusterEquipments] || [];
            let equipmentStartsWithUserGrp = false;
            if (
              params &&
              params !== undefined &&
              clusterEquipments.includes(params?.data?.id[0])
            ) {
              params.node.setSelected(true);
            }
            if (actionStatus.hasRole === "User") {
              if (item?.belongingToGroup) {
                groups.forEach((group) => {
                  if (
                    item.belongingToGroup
                      ?.trim()
                      ?.toLowerCase()
                      .startsWith(group?.groupName?.trim()?.toLowerCase())
                  ) {
                    equipmentStartsWithUserGrp = true;
                  }
                });
              }
            }
            if (item?.belongingToGroup && actionStatus.hasRole === "User") {
              if (!equipmentStartsWithUserGrp) {
                return false;
              }
            }

            if (
              isEditMode &&
              item?.entryType === entryType?.cluster &&
              selectedCluster === item.id
            ) {
              return false;
            }

            if (isEditMode && item?.clusterId === selectedCluster) {
              return true;
            }

            if (isSubCluster && item?.entryType === entryType?.cluster) {
              return false;
            } else if (item?.entryType === entryType?.cluster) {
              return !(
                megaclusterIds?.current?.includes(item?.id) || item?.clusterId
              );
            }
            if (
              item?.entryType === entryType?.clusterSubequipment &&
              totalSubEquipments.includes(item?.id)
            ) {
              return true;
            } else if (
              item?.entryType === entryType?.clusterSubequipment &&
              !totalSubEquipments.includes(item?.id)
            ) {
              return false;
            }

            return (
              item?.clusterId === null ||
              item?.status === equipmentStatus?.active?.key ||
              hasPermission
            );
          } else {
            params.node.selectable = false;
            return false;
          }
        },
        innerRenderer: (params) => {
          return params?.data?.equipment_model[0];
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChipDelete = async (
    chipToDelete,
    lastSavedChip,
    panel,
    userFilters
  ) => {
    return await onDeleteSaveConfigurations({
      userID,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email,
      deleteFilterQuery: DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      deleteColumnQuery: DELETE_IR_DISPLAY_COLUMN,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      userFilters,
      userDisplayList,
      updateUserFilters,
      updateUserDisplayColumns,
      updateLastFavFilter,
      updateLastDisplayColumn
    });
  };

  const onResetAll = async () => {
    await onUpdateFilter({
      lastFavFilter: DEFAULT_FILTER,
      updateRedux: true,
      id: userID,
      email,
      client,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      updateLastFavFilter
    });
    await onUpdateSelectColumn({
      data: JSON.stringify(DEFAULT_COLUMNS),
      updateRedux: true,
      id: userID,
      email,
      client,
      userProfileQuery:
        UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      query: UPDATE_IR_DISPLAY_COLUMN,
      userDisplayList,
      updateUserDisplayColumns,
      updateLastDisplayColumn
    });
  };

  const onRowsPerChange = async (rowPerPage) => {
    await saveLastPageSize({
      limit: rowPerPage,
      limitKey: "ir_grid",
      lastPageSize,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS,
      updateLastPageSize,
      id: userID,
      email,
      client
    });
  };

  const onCheckBoxSelected = ({ id = "", updatedIds = null }) => {
    if (id) {
      setClusterEquipments((prevState) => {
        return onlyUnique([...prevState, id]);
      });
    }
    if (updatedIds) {
      setClusterEquipments(() => {
        return updatedIds;
      });
    }
  };

  const requestServer = (cloudSearchConfigOption) => {
    return CloudSearchConfig(cloudSearchConfigOption);
  };

  return (
    <>
      {Object?.keys(attributes)?.length > 0 &&
        Object?.keys(masterDataFilters)?.length > 0 && (
          <DLabEquipmentList
            autoGroupColumnDefObj={autoGroupColumnDef}
            columnData={dataModel}
            masterDataFilters={masterDataFilters}
            userFilters={userFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={lastFavFilter}
            lastDisplayColumns={lastDisplayColumns ?? DEFAULT_COLUMNS}
            userDisplays={userDisplays}
            systemDisplays={systemDisplays}
            onSave={onSave}
            onChipDelete={onChipDelete}
            defaultFilterObj={DEFAULT_FILTER}
            defaultShowColumns={defaultShowColumns}
            listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
            listOfNumberFilter={listOfNumberFilter}
            isServerSideGroupObj={isServerSideGroup}
            getServerSideGroupKeyObj={getServerSideGroupKey}
            selectedEquipment={clusterEquipments}
            onResetAll={onResetAll}
            onCheckBoxChange={onCheckBoxSelected}
            showSaveConfiguration={false}
            limit={
              typeof lastPageSize === "string"
                ? JSON.parse(lastPageSize)?.ir_grid
                : lastPageSize?.ir_grid
            }
            onRowsPerChange={onRowsPerChange}
            requestServer={requestServer}
            metricValue={dimentionUnits?.metricUnit}
            literalFields={literalFields}
            isActiveEquipmentOnly={true}
            isStandaloneEquipmentOnly={true}
            {...attributes}
          >
            <span
              name="pagination"
              style={{ display: "flex", alignItems: "center" }}
            >
              <>
                <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
                  Components added
                </OwcTypography>
                <OwcBadge
                  type="active"
                  style={{
                    marginRight: "15px",
                    width: "24px",
                    height: "24px"
                  }}
                >
                  {selected?.length}
                </OwcBadge>
              </>

              <OwcButton
                variant="secondary"
                disabled={clusterEquipments?.length < 1}
                onClick={() => {
                  addClusterEquip();
                }}
              >
                Add to cluster
              </OwcButton>
            </span>
          </DLabEquipmentList>
        )}
    </>
  );
};

export default compose(
  connect(null, {
    updateLastDisplayColumn: updateLastDisplayColumnAction,
    updateUserDisplayColumns: updateUserDisplayColumnsAction,
    updateLastFavFilter: updateLastFavFilterAction,
    updateUserFilters: updateUserFiltersAction,
    updateLastPageSize: updateLastPageSizeAction
  }),
  withApollo
)(AddEquipment);
