import React, { useState, useEffect, useContext } from "react";
import CustomTable from "../../components/shared/ListOwcTable";
import TopBar from "../instruments/TopBar";

import GroupDialog from "./GroupDialog";
import { getAllData } from "../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import {
  lIST_IR_GROUP_ADMIN_MAPPINGS,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
  UNICITY_CHECK_lIST_IR_GROUP_ADMIN_MAPPINGS
} from "../../gql/landingapi/queries";
import {
  CREATE_IR_GROUP_ADMIN_MAPPING,
  UPDATE_IR_GROUP_ADMIN_MAPPING
} from "../../gql/landingapi/mutations";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { GroupContext } from "./context";
import { GroupTableMeta } from "./GroupMeta";
import TopBarButton from "./TopBarButton";
import { Formik } from "formik";
import { groupValidationSchema } from "./validationSchema";
import Notify from "../notifications/Notify";
import { checkRoleInstrumentRepo } from "../../utils/helpers/checkRoleInstrumentRepo";
import { useHistory } from "react-router";
import { CommonContextWrapper } from "../../common-context/context";
import { sortBy } from "lodash";
import { getCurrentDatas } from "../instruments/instrument-repository-pagination/PaginationActions";
import { DEFAULT_RECORDS_FETCHED } from "../../constants";

const GroupContent = ({ client }) => {
  const {
    dispatchAction,
    fetching,
    limit,
    orderBy,
    sortDirection,
    refreshData,
    nextToken
  } = useContext(PaginationContext);
  const { dialogOpen, setDialogOpen, isEditMode, userList, setUserList } =
    useContext(GroupContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalGroup, setTotalGroup] = useState([]);
  const { saveLastPageSize } = useContext(CommonContextWrapper);
  const [groupList, setGroupList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const checkUnicityForReason = async (groupName) => {
    let variables = { filter: groupName };
    const { items } = await getAllData({
      client,
      query: UNICITY_CHECK_lIST_IR_GROUP_ADMIN_MAPPINGS,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listIRGroupAdminMappings"],
      drillData: false
    });
    return items.length > 0 ? false : true;
  };

  const groupGql = async (isTokenNull = false) => {
    let variables = {
      limit: DEFAULT_RECORDS_FETCHED,
      nextToken: isTokenNull ? null : nextToken
    };
    if (searchGroup !== "") {
      variables = {
        ...variables,
        groupName: { eq: searchGroup }
      };
    }
    return getAllData({
      client,
      query: lIST_IR_GROUP_ADMIN_MAPPINGS,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listIRGroupAdminMappings"],
      drillData: false
    });
  };

  const getGroupList = async () => {
    dispatchAction({
      type: "fetching",
      payload: true
    });
    setPageIndex(0);
    const { items, nextToken: token } = await groupGql(true);

    dispatchAction({
      type: "nextToken",
      payload: { token: token, data: items }
    });
    items.map((item) => (item.userIdsString = item.userIds.join()));
    const currentItems = getCurrentDatas(limit, 0, items);
    setTotalGroup(items);
    setGroupList(currentItems);
  };

  const getUserList = async () => {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
      fetchPolicy: "network-only",
      dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
      drillData: true,
      variables: {
        limit: 1000
      }
    });

    let list = items.map((item) => ({
      ...item,
      key: item?.email,
      value:
        item?.givenName && item?.familyName && item?.name
          ? `${item?.givenName} ${item?.familyName} - ${item?.name}`
          : item?.givenName && item?.familyName
          ? `${item?.givenName} ${item?.familyName}`
          : item.email
    }));
    list = sortBy(list, "value");
    setUserList(list);
  };
  const submit = async (data) => {
    setLoading(true);
    let checkUnicityStatus = await checkUnicityForReason(
      data?.groupName?.trim()
    );
    if (checkUnicityStatus || isEditMode) {
      const list = [];

      data.userIds.forEach((item) => {
        const { email, givenName, familyName, name, userId } = item;
        list.push({ email, givenName, familyName, name, userId });
      });
      const row = {
        groupName: data.groupName,
        userIds: list
      };
      try {
        await client.mutate({
          mutation: isEditMode
            ? UPDATE_IR_GROUP_ADMIN_MAPPING
            : CREATE_IR_GROUP_ADMIN_MAPPING,
          variables: row,
          fetchPolicy: "no-cache"
        });
        dispatchAction({
          type: "refresh"
        });
        setDialogOpen(false);
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: isEditMode
            ? `Group updated successfully! `
            : `Group created successfully! `
        });
      } catch (error) {
        setDialogOpen(true);
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: isEditMode ? `Group updated failed!` : `Group created failed! `
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setDialogOpen(true);
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Group already exist"
      });
    }
  };

  useEffect(() => {
    const fetchRolePermission = async () => {
      try {
        const actions = await checkRoleInstrumentRepo();
        if (actions?.showGroup) {
          getUserList();
        } else {
          history?.goBack();
        }
      } catch (error) {
        console.log("check group role error", { error });
      }
    };
    fetchRolePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGroupList();
    saveLastPageSize(limit, "ir_group");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroup, orderBy, sortDirection, limit, refreshData]);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Formik
      validationSchema={groupValidationSchema}
      initialValues={{ groupName: "", userIds: null }}
      isInitialValid={false}
      validateOnMount={false}
    >
      <>
        <GroupDialog
          open={dialogOpen}
          onCancel={closeDialog}
          isEditMode={isEditMode}
          submit={submit}
          userList={userList}
          loading={loading}
        />
        <div style={{ marginBottom: 10 }}>
          <TopBar
            setQuery={setSearchGroup}
            placeholder="Search for group"
            compact
          >
            <TopBarButton setDialogOpen={setDialogOpen} />
          </TopBar>
        </div>

        <CustomTable
          filteredDatas={groupList}
          setFilteredDatas={setGroupList}
          handlerQueryDatas={groupGql}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalGroup}
          setTotalDatas={setTotalGroup}
          meta={GroupTableMeta}
          data={groupList}
          fieldArray={[]}
          onRequestSort={false}
          isReverseOrder={true}
          orderBy={"name"}
          fetching={fetching}
        />
      </>
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  user: state.user
});

export default compose(connect(mapStateToProps), withApollo)(GroupContent);
