import { OwcChip, OwcListItem, OwcMenu, OwcTooltip } from "@one/react";
import React, { useState } from "react";
import ReactDOM from "react-dom";

const Tags = ({ chips, rowId }) => {
  const [openChips, setOpenChips] = useState(null);
  const tags = chips || [];
  let tagsDropdown = [];
  if (tags?.length > 1) {
    tags?.forEach((element, index) => {
      if (index > 0) {
        tagsDropdown.push(element);
      }
    });
  }

  if (tags?.length === 0) {
    return <>-</>;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {tags?.length > 0 &&
            tags?.map((tag, index) => {
              if (index < 1) {
                const tagWithEllipsis =
                  tag.length > 20 ? tag.substring(20) + "..." : tag;
                return (
                  <div style={{ marginRight: 5 }}>
                    <OwcChip variant="outlined" id={tagWithEllipsis}>
                      <OwcTooltip anchor={tagWithEllipsis}>
                        {tagWithEllipsis}
                      </OwcTooltip>
                      {tagWithEllipsis}
                    </OwcChip>
                  </div>
                );
              }
              return "";
            })}
        </div>
        {tagsDropdown?.length > 0 && (
          <>
            <div
              id={rowId}
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                setOpenChips(() => event?.currentTarget);
              }}
            >
              +{tagsDropdown?.length}
            </div>
            {ReactDOM.createPortal(
              <OwcMenu
                data-testid="simple-menu"
                visible={openChips}
                onClickOutside={() => {
                  setOpenChips(() => null);
                }}
                anchor={rowId}
                style={{ height: 300 }}
              >
                {tagsDropdown?.map((tag) => (
                  <OwcListItem>{tag}</OwcListItem>
                ))}
              </OwcMenu>,
              document.body
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Tags;
