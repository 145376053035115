import styled from "styled-components";
import Tags from "../../../../utils/helpers/Tags";

import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { changeDateFormat } from "../../../../utils/helpers/text";
import {
  listOfBooleanRequired,
  listOfCheckBoxFilters,
  listOfDateRequired,
  listOfFieldsNoFilter,
  listOfFieldsNotSortable,
  listOfFilterBlankRequired,
  listOfNumberFilter,
  conversionFields,
  allTableColumn
} from "../../../../constants";
import { find } from "lodash";

import convert from "convert-units";
import EquipmentStatusRender from "../../EquipmentStatusRender";
import { convertToSnakeCase } from "../../../../utils/helpers/converters";
import { EN_DASH } from "@digitallab/grid-common-components";

const BooleanTag = styled.div`
  padding-right: 25px;
  padding-left: 25px;
`;

const DateTag = styled.div`
  padding-right: 25px;
  padding-left: 25px;
`;

export const renderChip = (params) => {
  return (
    <Tags chips={params?.data?.tags || []} rowId={params?.data?.id[0]}></Tags>
  );
};

export const booleanRender = (params) => {
  for (const field of listOfBooleanRequired) {
    const fieldName = field
      .replace(/([A-Z])/g, "_$1")
      .toLowerCase();
    if (params?.column?.colId === fieldName) {
      return <BooleanTag>{params?.data?.[fieldName]?.[0] ?? EN_DASH}</BooleanTag>;
    }
  }

  return <BooleanTag>{"booleanRender function not configured!"}</BooleanTag>;
};

export const linkedInstanceRender = (params) => {
  return <>{params?.data?.linked_instance[0]}</>;
};

export const CoversionFieldRender = ({ params, metric, field }) => {
  let obj = find(allTableColumn(), { key: field });
  let fieldValue = convertToSnakeCase(obj?.key);
  if (metric !== "metric") {
    fieldValue = convertToSnakeCase(obj?.imperialField);
  }

  return (
    <>
      {params?.data[fieldValue] !== undefined
        ? params?.data[fieldValue][0]
        : "-"}
    </>
  );
};

export const changeValueToDimention = (params) => {
  const convertValueToInch = conversionFields
    .filter((item) => convertToSnakeCase(item) === params?.column?.colId)
    .map((item) => convertToSnakeCase(item));

  if (
    params?.column?.colId ===
    convertToSnakeCase(DATA_MODEL_TABLE.weightInkg.key)
  ) {
    if (
      params?.data[convertValueToInch] !== undefined &&
      params?.data[convertValueToInch]
    ) {
      const convertedValue = convert(params?.data[convertValueToInch])
        .from("kg")
        .to("lb");
      const dimensionLbsVal = parseFloat(convertedValue.toFixed(2));
      return <>{dimensionLbsVal}</>;
    }
  } else {
    if (
      params?.data[convertValueToInch] !== undefined &&
      params?.data[convertValueToInch]
    ) {
      const convertedValueInch = convert(params?.data[convertValueToInch])
        .from("mm")
        .to("in");
      const dimensionInchVal = parseFloat(convertedValueInch.toFixed(2));
      return <>{dimensionInchVal}</>;
    }
  }
  return <>{"-"}</>;
};

/**
 * function that is passed to AGGrid for  column definition to parameter cellRenderer
 * IMPORTANT: can be only used for Dates types of columns
 * @param {*} params -provided by AGGrid
 * @returns DateTag with formatted date as string
 */

export const dateRender = (params) => {
  if(params.value){
    return (
      <DateTag>
        {changeDateFormat(
          params.value[0],
          "DD-MMM-YYYY"
        )}
      </DateTag>
    );
  }
  return <DateTag>-</DateTag>;
};

export const getColumnObj = (
  value,
  masterDataFilters,
  autoGroupField = null,
  metric
) => {
  let colObj = value;

  colObj = {
    ...colObj,
    sortable: !listOfFieldsNotSortable?.includes(value.key)
  };

  if (value.key === DATA_MODEL_TABLE?.linkedInstance?.key) {
    colObj = {
      ...colObj,
      cellRenderer: linkedInstanceRender
    };
  }

  if (conversionFields?.includes(value.key)) {
    let obj = find(allTableColumn(), { key: value.key });
    let fieldValue = metric !== "metric" ? obj?.imperialField : obj?.key;
    colObj = {
      ...colObj,
      val: DATA_MODEL_TABLE[fieldValue]?.value,
      cellRenderer: (params) => {
        return (
          <>
            <CoversionFieldRender
              params={params}
              metric={metric}
              field={value?.key}
            ></CoversionFieldRender>
          </>
        );
      }
    };
  }

  if (value.key === DATA_MODEL_TABLE?.tags?.key) {
    colObj = {
      ...colObj,
      cellRenderer: renderChip
    };
  }

  if (value.key === DATA_MODEL_TABLE?.status?.key) {
    colObj = {
      ...colObj,
      cellRenderer: EquipmentStatusRender
    };
  }

  if (listOfDateRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: dateRender,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
        suppressAndOrCondition: true,
        defaultJoinOperator: "OR",
        buttons: ["reset", "apply"]
      }
    };
  } else if (listOfBooleanRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: booleanRender,
      filter: "agSetColumnFilter",
      filterParams: {
        defaultToNothingSelected: true,
        showTooltips: true,
        buttons: ["reset"],
        values: (params) => {
          const values = ["true", "false"];
          params.success(values);
        }
      }
    };
  } else if (listOfCheckBoxFilters?.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values = masterDataFilters[value.key] || [];
          params.success(values);
        }
      }
    };
  } else if (listOfNumberFilter?.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "equals",
          "lessThan",
          "greaterThan",
          "inRange",
          "blank"
        ],
        defaultJoinOperator: "OR",
        buttons: ["reset", "apply"]
      }
    };
  } else if (!listOfFieldsNoFilter.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: listOfFilterBlankRequired?.includes(value.key)
          ? ["contains", "blank"]
          : ["contains"],
        buttons: ["reset", "apply"],
        defaultToNothingSelected: true,
        defaultJoinOperator: "OR",
        values: (params) => {
          const values = masterDataFilters[value.key] || [];
          params.success(values);
        }
      }
    };
  }

  if (autoGroupField && value.key === autoGroupField) {
    colObj = {
      ...colObj,
      hide: true,
      lockVisible: true
    };
  }

  return colObj;
};

export const getColumnObjClient = (value) => {
  let colObj = value;

  colObj = {
    ...colObj,
    sortable: !listOfFieldsNotSortable?.includes(value.key)
  };
  if (listOfBooleanRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: booleanRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.linkedInstance?.key) {
    colObj = {
      ...colObj,
      cellRenderer: linkedInstanceRender
    };
  }
  if (value.key === DATA_MODEL_TABLE?.status?.key) {
    colObj = {
      ...colObj,
      cellRenderer: EquipmentStatusRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.tags?.key) {
    colObj = {
      ...colObj,
      cellRenderer: renderChip
    };
  }

  if (listOfDateRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: dateRender
    };
  }

  return colObj;
};
