import * as yup from "yup";

/**
 *
 * @param {analyzerConfigurationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

/**
 * schema for validating the equpiment gxp maintainance form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const analyzerConfigurationSchema = yup.object({
  softwareVersion: getCustomNullable(
    yup.string("Enter software version").typeError("Invalid type")
  ),
  configurationBaseline: getCustomNullable(
    yup.string("Enter configuration baseline").typeError("Invalid type")
  ),
  installedTests: yup
    .array(
      yup
        .object({
          name: yup.string("Enter name").typeError("Invalid type").nullable(),
          version: yup
            .string("Enter version")
            .typeError("Invalid type")
            .nullable()
        })
        .nullable()
    )
    .nullable(),
  isBookable: yup.bool(),
  isVisualized: yup.bool()
});
