import React, { useContext, useEffect } from "react";
import "./App.scss";
import AppBar from "./views/AppBar";
import { StyledToastContainer } from "./features/notifications/Notify";
import "react-toastify/dist/ReactToastify.css";
import LoadInstrumentsInfo from "./features/instruments/LoadInstrumentsInfo";
import InstrumentsMainPage from "./features/instruments/InstrumentsMainPage";
import { Route, Switch } from "react-router-dom";
import ExportCsvMainPage from "./features/export-csv/ExportCsvMainPage";
import TableauEquipmentPage from "./features/tableau/TableauEquipmentPage";
import { useState } from "react";
import ExportCsvWapper from "./features/export-csv/ExportCsvWapper";
import styled from "styled-components";
import { getEnv } from "./utils/helpers/text";
import { BackendInfoContext } from "./features/backend-info-context/context";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { LIST_BACKEND_HEALTH_CHECKS } from "../src/gql/landingapi/queries";
import { API } from "aws-amplify";
import AppDrawer from "./AppDrawer";
import MainPageMaintenance from "./views/MainPageMaintenance";
import TogglerContextWrapper from "../src/components/shared/TogglerContextWrapper";
import DrawerMenu from "./DrawerMenu";
import ImportMainView from "./features/importFile/ImportMainView";
import AdminMainView from "./features/admin/AdminMainView";
import { CssBaseline } from "@mui/material";
import { DialogServiceContextWrapper } from "@digitallab/grid-common-components";

const currentEnv = getEnv();

const FlexBox = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
`;
const AdaptarWrapperStyle = styled.div`
  width: 100%;
  overflow: auto;
`;
const AppVal = styled.div`
  .MuiAppBar-positionAbsolute {
    top: 42px;
  }
`;
const getContainerHeight = (currentEnv) => {
  return currentEnv ? window.innerHeight - 126 : window.innerHeight - 76;
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("recordingError: " + error);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Clear Error
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  const { hideFooter } = useContext(BackendInfoContext);
  const [enableVal, setEnableVal] = useState(false);
  const [containerHeight, setContainerHeight] = useState(
    getContainerHeight(currentEnv)
  );
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv));
  });
  const { currentUser, maintenance, setMaintenance } =
    useContext(BackendInfoContext);
  const getBackendAuthChecks = async () => {
    try {
      const backendBuildInfo = await API.graphql({
        query: LIST_BACKEND_HEALTH_CHECKS
      });
      const items = backendBuildInfo?.data?.listBackendHealthChecks?.items;
      const trackDisable = items.map((item) => item?.enableAnalyticsTracking);
      const isAppUnderMaintenance = items.map(
        (item) => item?.isAppUnderMaintenance
      );
      setMaintenance(isAppUnderMaintenance[0]);
      setEnableVal(trackDisable[0]);
    } catch (error) {
      console.log("Matomo api error..", error);
    }
  };
  useEffect(() => {
    getBackendAuthChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Matomo code added for analytics
  // urlBase: optional, default value: `${urlBase}matomo.php`
  // siteId : optional, default value: `${urlBase}matomo.js`
  // disabled :optional, false by default. Makes all tracking calls no-ops if set to true.
  // linkTracking :optional, default value: true
  // active: optional, default value: true
  // seconds: optional, default value: `15

  const instance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_BASE_URL,
    siteId: process.env.REACT_APP_SITE_ID,
    trackerUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.php",
    srcUrl: process.env.REACT_APP_MATOMO_BASE_URL + "matomo.js",
    disabled: enableVal,
    linkTracking: true,
    trackPageView: true,
    heartBeat: {
      active: true,
      seconds: 10
    }
  });
  const [isInboxActive, setIsInboxActive] = useState(false);
  const [inboxCount, setInboxCount] = useState(0);
  if (maintenance) return <MainPageMaintenance />;
  
  return (
    <ErrorBoundary>
      <MatomoProvider value={instance}>
        <LoadInstrumentsInfo
          setIsInboxActive={setIsInboxActive}
          setInboxCount={setInboxCount}
        >
          <CssBaseline />

          {currentEnv ? (
            <AppVal>
              <AppBar isInboxActive={isInboxActive} inboxCount={inboxCount} />
            </AppVal>
          ) : (
            <AppBar isInboxActive={isInboxActive} inboxCount={inboxCount} />
          )}
         <DialogServiceContextWrapper>
          <TogglerContextWrapper>
            <AdaptarWrapperStyle
              style={
                currentUser && currentEnv
                  ? {
                      top: 92,
                      height: `${
                        hideFooter ? containerHeight : containerHeight + 23
                      }px`
                    }
                  : {
                      top: 50,
                      height: `${
                        hideFooter ? containerHeight : containerHeight + 23
                      }px`
                    }
              }
            >
              <FlexBox>
                <AppDrawer>
                  <DrawerMenu />
                </AppDrawer>

                <Switch>
                  <Route exact path="/">
                    <InstrumentsMainPage />
                  </Route>
                  <Route exact path="/equipmentReview/:ReviewId">
                    <InstrumentsMainPage />
                  </Route>
                  <Route exact path="/equipmentList/:InventoryId">
                    <InstrumentsMainPage />
                  </Route>
                  <Route exact path="/import">
                    <ImportMainView />
                  </Route>
                  <Route exact path="/admin">
                    <AdminMainView />
                  </Route>
                  <Route exact path="/tableau-equipments">
                    <TableauEquipmentPage />
                  </Route>
                  <Route exact path="/export-csv">
                    <ExportCsvWapper>
                      <ExportCsvMainPage
                        setIsInboxActive={setIsInboxActive}
                        isInboxActive={isInboxActive}
                        setInboxCount={setInboxCount}
                      />
                    </ExportCsvWapper>
                  </Route>
                </Switch>
              </FlexBox>
            </AdaptarWrapperStyle>
          </TogglerContextWrapper>
          </DialogServiceContextWrapper>
          <StyledToastContainer />
        </LoadInstrumentsInfo>
      </MatomoProvider>
    </ErrorBoundary>
  );
}

export default App;
