import { useState, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "./DlabGrid.css";
import DLabGridPagination from "./DLabGridPagination";

const DLabGrid = ({
  height = "100%",
  width = "100%",
  onRowsPerPageChanged,
  rowPerPage,
  totalRows,
  options,
  gridRef,
  optionsDisabled = false,
  hidePagination = false,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPages, setTotalPages] = useState("0");
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({
      height,
      width
    }),
    [height, width]
  );
  const onCustomPaginationChange = ({ type, value }) => {
    switch (type) {
      case "PreviousPage":
        gridRef.current.api.paginationGoToPreviousPage();
        break;
      case "NextPage":
        gridRef.current.api.paginationGoToNextPage();
        break;
      case "LastPage":
        gridRef.current.api.paginationGoToLastPage();
        break;
      case "Limit":
        onRowsPerPageChanged(value);
        break;
      default:
        gridRef.current.api.paginationGoToFirstPage();
        break;
    }
  };

  const onGridPaginationChanged = useCallback((params) => {
    // Workaround for bug in events order
    if (gridRef && gridRef?.current?.api) {
      // setPageSize(gridRef.current.api.paginationGetPageSize());
      // we +1 to current page, as pages are zero based
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      // setLastPageFound(gridRef.current.api.paginationIsLastPageFound());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={containerStyle}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ overflow: "hidden", flexGrow: "1" }}>
            <div style={gridStyle} className="ag-theme-material ag-theme-DLab">
              <AgGridReact
                onPaginationChanged={onGridPaginationChanged}
                ref={gridRef}
                {...props}
              ></AgGridReact>
            </div>
          </div>
          {!hidePagination && (
            <div>
              <DLabGridPagination
                onPaginationChange={onCustomPaginationChange}
                limit={rowPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                totalRows={totalRows}
                options={options}
                optionsDisabled={optionsDisabled}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DLabGrid;
