import React from "react";
import { LinkedInstanceContext } from "./Context";

const LinkedInstanceWrapper = ({ children }) => {
  return (
    <LinkedInstanceContext.Provider value={{}}>
      {children}
    </LinkedInstanceContext.Provider>
  );
};

export default LinkedInstanceWrapper;
