import React from "react";

const FilterOutlinedActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4L2 4L2 6L20 6V4ZM17 9L5 9L5 11L17 11V9ZM11 14L9 14L9 16L11 16V14Z"
      fill="#0B41CD"
    />
    <path
      d="M17.9039 19.75C16.6539 19.75 15.6539 18.75 15.6539 17.5C15.6539 16.25 16.6539 15.25 17.9039 15.25C19.1539 15.25 20.1539 16.25 20.1539 17.5C20.1539 18.75 19.1539 19.75 17.9039 19.75ZM17.9039 16.25C17.2039 16.25 16.6539 16.8 16.6539 17.5C16.6539 18.2 17.2039 18.75 17.9039 18.75C18.6039 18.75 19.1539 18.2 19.1539 17.5C19.1539 16.8 18.6039 16.25 17.9039 16.25Z"
      fill="#0B41CD"
    />
    <path
      d="M18.9039 13V14.05L19.4539 14.35C19.6039 14.4 19.7039 14.5 19.8539 14.55L20.3539 14.9L20.9039 14.6L21.3039 14.35L22.3039 16.1L21.9039 16.35L21.3539 16.65L21.4039 17.25V17.65L21.3539 18.25L21.9039 18.55L22.3039 18.8L21.3039 20.55L20.9039 20.4L20.3539 20.1L19.8539 20.45C19.7539 20.5 19.6039 20.6 19.4539 20.65L18.9039 20.9L18.9039 22H16.9039L16.9039 20.95L16.3539 20.65C16.2039 20.6 16.1039 20.5 15.9539 20.45L15.4539 20.1L14.9039 20.4L14.5039 20.65L13.5039 18.9L13.9039 18.65L14.4539 18.35L14.4039 17.7V17.3L14.4539 16.7L13.9039 16.35L13.5039 16.1L14.5039 14.35L14.9039 14.6L15.4539 14.9L15.9539 14.55C16.0539 14.5 16.2039 14.45 16.3539 14.35L16.9039 14.1V13H18.9039ZM18.9039 12H16.9039C16.3539 12 15.9039 12.45 15.9039 13V13.45C15.7539 13.55 15.5539 13.65 15.4039 13.75L15.0039 13.5C14.8539 13.4 14.6539 13.35 14.5039 13.35C14.1539 13.35 13.8039 13.55 13.6539 13.85L12.6539 15.6C12.3539 16.1 12.5539 16.7 13.0039 16.95L13.4039 17.2V17.8L13.0039 18C12.5039 18.3 12.3539 18.9 12.6539 19.35L13.6539 21.1C13.8539 21.4 14.1539 21.6 14.5039 21.6C14.6539 21.6 14.8539 21.55 15.0039 21.45L15.4039 21.25C15.5539 21.35 15.7039 21.45 15.9039 21.55L15.9039 22C15.9039 22.55 16.3539 23 16.9039 23H18.9039C19.4539 23 19.9039 22.55 19.9039 22V21.55C20.0539 21.45 20.2539 21.35 20.4039 21.25L20.8039 21.5C20.9539 21.6 21.1539 21.65 21.3039 21.65C21.6539 21.65 22.0039 21.45 22.1539 21.15L23.1539 19.4C23.4539 18.9 23.2539 18.3 22.8039 18.05L22.4039 17.8V17.2L22.8039 16.95C23.3039 16.65 23.4539 16.05 23.1539 15.6L22.1539 13.85C21.9539 13.55 21.6539 13.35 21.3039 13.35C21.1539 13.35 20.9539 13.4 20.8039 13.5L20.4039 13.75C20.2539 13.65 20.1039 13.55 19.9039 13.45V13C19.9039 12.45 19.4539 12 18.9039 12Z"
      fill="#0B41CD"
    />
  </svg>
);

export default FilterOutlinedActive;
