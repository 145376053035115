import * as Yup from "yup";

export const checklistDialogValidationSchema = Yup.object({
  description: Yup.string()
    .max(40, "Maximum 40 characters are allowed")
    .trim()
    .required("Description is required"),
  url: Yup.string()
    .url("Invalid link, should be a valid URL, e.g. https://roche.com ")
    .required("Url is required"),
  models: Yup.array()
    .min(1, "Please select at least one model")
    .required("Please select at least one model"),
  sites: Yup.array()
    .min(1, "Please select at least one site")
    .required("Please select at least one site"),
  groups: Yup.array()
    .min(1, "Please select at least one group")
    .required("Please select at least one group")
});
