import React from "react";
import { MasterDataContext } from "./Context";

const MasterDataWrapper = ({ children }) => {
  return (
    <MasterDataContext.Provider value={{}}>
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataWrapper;
