import React from "react";
import styled from "styled-components";
import { SOP_MANDATORY_CLP } from "../../constants";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { OwcIcon } from "@one/react";

export const IconStyle = {
  position: "absolute",
  marginTop: "-43px",
  marginLeft: "176px",
  color: "blue"
};
export const Input = styled.div`
  margin-bottom: 20px;
`;
export const ScreenCentered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SOPHelperText = styled.div`
  display: flex;
  font-size: 11px;
`;

export const CustomSelect = ({
  options,
  value,
  propLabel,
  propValue,
  onChange,
  onOpen,
  testid,
  name,
  selectLabel,
  emptyOption = false,
  multiple = false,
  helperText,
  SelectProps,
  error,
  renderValue = undefined,
  showBadge = false,
  isUpdateBadge = false,
  sopHelperText,
  fetching = false,
  ...rest
}) => {
  return (
    <>
      <FormControl variant="filled" error={error} {...rest}>
        <InputLabel id={testid}>{selectLabel}</InputLabel>
        {showBadge && (
          <>
            <OwcIcon
              style={{
                position: "absolute",
                color:
                  isUpdateBadge === true
                    ? "var(--one-color-legacy-green-400)"
                    : "var(--one-color-gray-600)",
                fontSize: 11,
                right: 11,
                top: 5,
                "z-index": "2"
              }}
              name="certificate"
              type="legacy"
            />
          </>
        )}
        <Select
          labelId={testid}
          data-testid={testid}
          id={testid}
          multiple={multiple}
          value={value}
          onChange={onChange}
          onOpen={onOpen}
          name={name}
          style={{
            backgroundColor: "#f5f5f5",
            height: "49px"
          }}
          renderValue={renderValue}
          {...SelectProps}
        >
          {fetching && (
            <>
              <MenuItem value="" className="DropDown">
                <ScreenCentered>
                  <CircularProgress data-testid="loading" size={20} />
                </ScreenCentered>
              </MenuItem>
            </>
          )}
          {emptyOption && <MenuItem value="">Select all</MenuItem>}
          {options?.map((item) => {
            const option = propValue
              ? multiple
                ? item
                : item?.[propValue]
              : item;
            return (
              <MenuItem value={option} key={option}>
                {propLabel ? item?.[propLabel] : item}
              </MenuItem>
            );
          })}
        </Select>
        {error ? (
          <FormHelperText error={error}>{`*${helperText}`}</FormHelperText>
        ) : null}
        {sopHelperText ? (
          <FormHelperText error={error}>
            <SOPHelperText>{SOP_MANDATORY_CLP}</SOPHelperText>
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
};

export default CustomSelect;
