import { OwcTypography } from "@one/react";
import React from "react";
import styles from "./WelcomeScreen.module.scss";

const WelcomeScreen = ({ text = "You must login to view equipment" }) => {
  return (
    <div
      className={styles.welcomeScreenWrapper}
      data-testid="main-page-not-authenticated-with-no-error-by-backend"
    >
      <OwcTypography style={{ color: "inherit" }} variant="title2">
        Equipment repository
      </OwcTypography>
      <OwcTypography style={{ color: "inherit" }} variant="title2">
        Welcome
      </OwcTypography>
      <div className={styles.wrapTitle5}>
        <OwcTypography style={{ color: "inherit" }} variant="title5">
          {text}
        </OwcTypography>
      </div>
    </div>
    // <WelcomeScreenStyled data-testid="main-page-not-authenticated-with-no-error-by-backend">
    //   <h2>
    //     Equipment repository
    //     <br /> <span>Welcome</span>
    //   </h2>
    //   <h5>{text}</h5>
    // </WelcomeScreenStyled>
  );
};

export default WelcomeScreen;
